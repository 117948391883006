import { Component, ElementRef, OnInit } from '@angular/core';
import { Control } from 'leaflet';
import { SidePanelControl } from '../../controls/sidepanel-control';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-map-sidepanel',
  template: `<ng-content></ng-content>`
})
export class SidePanelComponent implements OnInit {
  private control: Control;
  private readonly element: HTMLElement;

  constructor({ nativeElement }: ElementRef, private readonly mapService: MapService) {
    this.element = nativeElement;
  }

  ngOnInit(): void {
    this.mountSidePanelOnMap();
  }

  mountSidePanelOnMap(): void {
    this.control = new SidePanelControl(this.element);
    this.control.addTo(this.mapService.getMap());
  }
}
