/* eslint-disable complexity */
import 'leaflet-rotatedmarker';

import { DivIcon, FeatureGroup, GeoJSON, Icon, LatLngBounds, Marker, Util } from 'leaflet';

import { ROUTE_DASH_CONFIG } from '../map.constants';
import { getSegmentCenter, pointsToSegments } from '../utilities/route-utility';

const STATUS_DEFAULT = 'default';
const STATUS_HIGHLIGHT = 'highlight';
const ASSETS_PATH = '/mapleafletassets/images/';
const SPEED_UNIT = 'kph';

export const RoutesGeoJSON = GeoJSON.extend({
  _map: null,
  _defaultLayer: null,
  _highlightLayer: null,
  _dashLayer: null,
  _currentStatus: STATUS_DEFAULT,
  _segmentCoordinates: null,
  _segmentCenterCoordinates: null,
  initialize(map, geojson, options) {
    GeoJSON.prototype.initialize.call(this);
    this._map = map;
    Util.setOptions(this, options);
    this._layers = {};
    if (geojson) {
      this.addData(geojson);
      this._addDashedRoute(geojson);
      const segments = this._getSegmentCoordinates(geojson);
      this._segmentCoordinates = segments.segmentCoordinates;
      this._segmentCenterCoordinates = segments.segmentsCenter;
      this._addRouteSymbols();
    }
  },
  _modifyRouteSymbols(status) {
    if (this._currentStatus !== status) {
      this._currentStatus = status;
      this._addRouteSymbols();
    }
  },
  _getSegmentCoordinates(geojson) {
    const pathAsPoints = [];
    const segmentsIndexRef = [];
    const segmentsCenter = [];
    // iterate each segment in geojson
    for (const feature of geojson.features) {
      //convert lat lng to x y points
      const points = feature.geometry.coordinates.map(lnglat => this._map.project([lnglat[1], lnglat[0]]));
      //capture segment index to identify which is first and last segment
      const segmentIndex =
        segmentsIndexRef.length === 0 ? points.length - 1 : segmentsIndexRef[segmentsIndexRef.length - 1] + (points.length - 1);
      segmentsIndexRef.push(segmentIndex);
      pathAsPoints.push(...points);

      // Calculate distance between points and its heading direction
      const featureSegments = pointsToSegments(points);

      // Identify segment center and its heading direction
      const segmentCenter = getSegmentCenter(featureSegments);

      const speed = feature.properties.max_speed ? feature.properties.max_speed : feature.properties.speed;

      segmentsCenter.push({ center: this._map.unproject(segmentCenter[0].pt), maxSpeed: speed });
    }

    const segments = pointsToSegments(pathAsPoints);
    /**
     * convert points to lat lng to plot it in map
     */
    const segmentCoordinates = segmentsIndexRef.map(segmentLength => ({
      latlng: this._map.unproject(segments[segmentLength - 1].b),
      heading: segments[segmentLength - 1].heading
    }));

    segmentCoordinates.unshift({ latlng: this._map.unproject(segments[0].a), heading: segments[0].heading });

    return { segmentCoordinates, segmentsCenter };
  },

  _addRouteSymbols() {
    if (this._currentStatus === STATUS_DEFAULT) {
      this._highlightLayer && this.removeLayer(this._highlightLayer);
      if (this._defaultLayer) {
        this.addLayer(this._defaultLayer);
      } else {
        const symbolsLayer = this._generateSymbols();
        this._defaultLayer = symbolsLayer;
        this.addLayer(symbolsLayer);
      }
    } else {
      this.removeLayer(this._defaultLayer);
      if (this._highlightLayer) {
        this.addLayer(this._highlightLayer);
      } else {
        const symbolsLayer = this._generateSymbols();
        this._highlightLayer = symbolsLayer;
        this.addLayer(symbolsLayer);
      }
    }
  },

  _addDashedRoute(geoJsonData) {
    if (this._dashLayer) {
      this.removeLayer(this._dashLayer);
    }

    this._dashLayer = new GeoJSON(geoJsonData, {
      style: () => ROUTE_DASH_CONFIG
    });

    this.addLayer(this._dashLayer);
  },

  _generateSymbols() {
    const symbolsGroup = new FeatureGroup([]);

    for (let i = 0; i < this._segmentCoordinates.length; i++) {
      let iconUrl, iconAnchor, iconSize;
      if (i === 0) {
        iconUrl = ASSETS_PATH + 'route_source.png';
        iconAnchor = [8, 8];
        iconSize = [16, 16];
      } else if (i === this._segmentCoordinates.length - 1) {
        iconUrl = ASSETS_PATH + 'route_destination.png';
        iconAnchor = [10, 27];
        iconSize = [20, 32];
      } else {
        iconUrl = `${ASSETS_PATH + this.options.routeColor}_segment.png`;
        iconAnchor = [8, 6];
        iconSize = [16, 16];
      }

      const markerIcon = new Icon({
        iconUrl,
        iconAnchor,
        iconSize
      });

      const options: any = {
        icon: markerIcon
      };

      if (i !== this._segmentCoordinates.length - 1) {
        options.rotationAngle = this._segmentCoordinates[i].heading;
      }

      const marker = new Marker(this._segmentCoordinates[i].latlng, options);
      symbolsGroup.addLayer(marker);
    }

    if (this._currentStatus === STATUS_HIGHLIGHT) {
      for (const coordinate of this._segmentCenterCoordinates) {
        if (coordinate.maxSpeed !== null && coordinate.maxSpeed !== undefined) {
          const speedOverlayIcon = new DivIcon({
            html: `<div>${coordinate.maxSpeed} ${SPEED_UNIT}</div>`,
            className: 'speed-overlay speed-overlay-' + this.options.routeColor,
            iconAnchor: [-10, -10],
            iconSize: [50, 23]
          });
          const overlayMarker = new Marker(coordinate.center, {
            icon: speedOverlayIcon,
            zIndexOffset: 100
          });
          symbolsGroup.addLayer(overlayMarker);
        }
      }
    }
    return symbolsGroup;
  },
  removeHighlight() {
    if (this._currentStatus !== STATUS_DEFAULT) {
      this._modifyRouteSymbols(STATUS_DEFAULT);
    }
  },
  highlight() {
    if (this._currentStatus !== STATUS_HIGHLIGHT) {
      this._modifyRouteSymbols(STATUS_HIGHLIGHT);
    }
  },
  updateSymbolsColor(color) {
    this.removeLayer(this._defaultLayer);
    this.removeLayer(this._highlightLayer);

    this._defaultLayer = null;
    this._highlightLayer = null;

    this.options.routeColor = color;
    this._addRouteSymbols();
  },
  getBounds() {
    const bounds = new LatLngBounds();
    for (const id in this._layers) {
      const layer = this._layers[id];
      if (layer.getBounds) {
        bounds.extend(layer.getBounds());
      }
    }
    return bounds;
  }
});
