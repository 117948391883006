import { Position } from '@Terra/shared/widgets/config';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Control } from 'leaflet';
import 'leaflet.heat/dist/leaflet-heat';
import * as L from 'leaflet/dist/leaflet';
import { HEAT_MAP_DEFAULT_OPTIONS } from '../../map.constants';
import { HeatMapControlOptions, MapContentOptions } from '../../map.model';
import { MapService } from '../../map.service';

declare let L: any;

@Component({
  selector: 'app-heatmap',
  template: ''
})
export class HeatmapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() options: HeatMapControlOptions;
  @Input() data: number[][];
  contentOptions: MapContentOptions = {
    position: Position.BOTTOMCENTER
  };
  controlOptions: any;

  private control: Control;

  constructor(private readonly mapService: MapService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data?.currentValue.length) {
      //To be called only data array is not empty
      this.createControl();
    }
  }

  ngOnInit() {
    this.controlOptions = {
      ...HEAT_MAP_DEFAULT_OPTIONS,
      ...this.options
    };
  }

  private createControl(): void {
    if (this.control) {
      this.mapService.getMap().removeLayer(this.control);
    }
    this.control = L.heatLayer(this.data, HEAT_MAP_DEFAULT_OPTIONS);
    this.control.addTo(this.mapService.getMap());
  }

  ngOnDestroy(): void {
    if (this.control) {
      this.control.remove();
    }
  }
}
