import { Control, DomEvent, DomUtil, Evented, Util } from 'leaflet';

/**
 * Leaflet Map View Toggle Switch Control
 * Takes 2 map types and default map type as input
 * Toggle between the two types on click
 *
 */

const MapViewLayerToggleSwitchControl = Control.extend({
  includes: Evented.prototype,
  _mapTypes: null,
  _defaultMap: null,
  _mapKeys: null,
  _mapDetails: null,
  onAdd(map) {
    if (this._mapKeys.length < 2) {
      return null;
    }

    if (this._mapKeys.length > 2) {
      this._mapKeys = this._mapKeys.slice(0, 2);
    }

    if (this._mapKeys.indexOf(this._defaultMap) === -1) {
      this._defaultMap = this._mapKeys[0];
    }

    this._container = DomUtil.create('div', 'switch-control-toggle');
    this._mapTypes[this._defaultMap].addTo(map);
    const switchLayer = this._toggleKey(this._defaultMap);
    this._toggleButton = DomUtil.create('button', switchLayer.toLowerCase() + '-custom-layer layer-toggle-container', this._container);
    this._toggleButton.mapType = this._defaultMap;
    this._toggleButton.selectedMapView = switchLayer;
    this._layerTextDiv = DomUtil.create('div', 'layer-text-container', this._container);
    DomEvent.on(this._toggleButton, 'click', this._click, this);
    DomEvent.on(this._layerTextDiv, 'click', this._click, this);
    DomEvent.on(this._container, 'touchstart dblclick mousewheel mouseout', this._stopPropagation, this);
    if (this._mapDetails) {
      this._overlayEle = DomUtil.create('div', 'map-details-overlay', this._container);
      this._layerTextDiv.innerHTML = '<div class="layer-icon"></div> <div class="layer-text">Layers</div>';
    } else {
      this._layerTextDiv.innerHTML = `<div class="layer-text text-align-item">${this._toggleButton.selectedMapView}</div>`;
    }
    DomEvent.on(this._container, 'mouseenter', this._mouseover, this);
    DomEvent.on(this._container, 'mouseleave', this._mouseout, this);
    return this._container;
  },
  initialize(mapTypes, defaultMap, options, mapDetailsElem) {
    Util.setOptions(this, options);
    this._mapTypes = mapTypes;
    this._defaultMap = defaultMap;
    this._mapKeys = Object.keys(mapTypes);
    this._mapDetails = mapDetailsElem;
  },
  getCurrentLayer() {
    return { layer: this._mapTypes[this._toggleButton.mapType], key: this._toggleButton.mapType };
  },
  _click() {
    const mapTypeToLoad = this._toggleKey(this._toggleButton.mapType);
    this._toggleButton.selectedMapView = this._toggleButton.mapType;
    this._map.removeLayer(this._mapTypes[this._toggleButton.mapType]);
    DomUtil.removeClass(this._toggleButton, mapTypeToLoad.toLowerCase() + '-custom-layer');
    DomUtil.addClass(this._toggleButton, this._toggleButton.mapType.toLowerCase() + '-custom-layer');
    this._toggleButton.mapType = mapTypeToLoad;
    this._mapTypes[mapTypeToLoad].addTo(this._map);
    this._layerTextDiv.innerHTML = `<div class="layer-text text-align-item">${this._toggleButton.selectedMapView}</div>`;
    this.fire('maptypechange', { mapType: mapTypeToLoad });
  },
  _stopPropagation(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.originalEvent) {
      // In case of Leaflet event.
      e.originalEvent._stopped = true;
    } else {
      e.cancelBubble = true;
    }
  },
  _toggleKey(currentKey) {
    return this._mapKeys.indexOf(currentKey) === 1 ? this._mapKeys[0] : this._mapKeys[1];
  },

  _mouseover() {
    if (this._mapDetails) {
      if (this._overlayEle.hasChildNodes()) {
        this._overlayEle.removeChild(this._mapDetails);
      }
      this._overlayEle.appendChild(this._mapDetails);
      this._layerTextDiv.innerHTML = `<div class="layer-text text-align-item">${this._toggleButton.selectedMapView}</div>`;
    }
  },

  _mouseout($event) {
    if ($event.relatedTarget?.offsetParent?.classList?.value?.indexOf('switch-control-toggle') === -1) {
      this._close();
    }
  },

  onRemove() {
    const container = this.getContainer();
    DomEvent.off(container, 'touchstart dblclick mousewheel', this._stopPropagation, this);
    DomEvent.off(this._toggleButton, 'click', this._click, this);
    DomEvent.off(this._layerTextDiv, 'click', this._click, this);
    DomEvent.off(this._container, 'mouseenter', this._mouseover, this);
    DomEvent.off(this._container, 'mouseleave', this._mouseout, this);
  },

  _setToggleType(mapType) {
    this._toggleButton.mapType = mapType;
  },

  _close() {
    if (this._mapDetails) {
      //BELOW CODE (110-121) IS A WORKAROUND FOR TOOLTIP ISSUE, TO BE REMOVED POST DISCUSSION WITH RC TEAM
      document?.querySelectorAll('.cc-traffic-layer-tooltip').forEach(ele => {
        ele.parentElement.parentElement.remove();
      });
      document?.querySelectorAll('.cc-speed-layer-tooltip').forEach(ele => {
        ele.parentElement.parentElement.remove();
      });
      this._overlayEle.innerHTML = '';
      this._layerTextDiv.innerHTML = '<div class="layer-icon"></div> <div class="layer-text">Layers</div>';
    }
  },

  _addHoverEvent(add) {
    if (add) {
      DomEvent.on(this._container, 'mouseenter', this._mouseover, this);
      DomEvent.on(this._container, 'mouseleave', this._mouseout, this);
    } else {
      DomEvent.off(this._container, 'mouseenter', this._mouseover, this);
      DomEvent.off(this._container, 'mouseleave', this._mouseout, this);
    }
  }
});

export { MapViewLayerToggleSwitchControl };

