import { Circle, Polygon, Polyline, Rectangle } from 'leaflet';

export const DataPolygon = Polygon.include({
  _popupData: {},

  getPopupData() {
    return this._popupData;
  },

  setPopupData(popupData: any) {
    this._popupData = popupData;
  }
});

export const DataPolyline = Polyline.include({
  _popupData: {},

  getPopupData() {
    return this._popupData;
  },

  setPopupData(popupData: any) {
    this._popupData = popupData;
  }
});

export const DataRectangle = Rectangle.include({
  _popupData: {},

  getPopupData() {
    return this._popupData;
  },

  setPopupData(popupData: any) {
    this._popupData = popupData;
  }
});

export const DataCircle = Circle.include({
  _popupData: {},

  getPopupData() {
    return this._popupData;
  },

  setPopupData(popupData: any) {
    this._popupData = popupData;
  }
});
