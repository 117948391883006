@if (showMeasureCard) {
  <div class="measure-container">
    <div class="title-card">
      {{ controlOptions.cardTitle }}
    </div>
    <div class="sub-title">
      {{ controlOptions.cardSubTitle }}
    </div>
    @if (totalDistance) {
      <div class="values-container">
        @if (area) {
          <div>
            {{ controlOptions.cardAreaText + ': ' }} <span class="bolder-value">{{ area + ' ' + distanceUnit }}<sup>2</sup></span>
          </div>
        }
        <div>
          {{ controlOptions.cardTotalDistanceText + ': ' }}<span class="bolder-value">{{ totalDistance + ' ' + distanceUnit }}</span>
        </div>
      </div>
    }
    <div class="close-btn">
      <button (click)="isInsights ? exitMeasureEvent() : exitMeasure()" cc-tooltip="{{ 'app.close' | transloco }}" ></button>
    </div>
  </div>
}

@if (
  (showElevationProfileCard && !isElevationProfileChartActive) ||
  (showElevationProfileCard && (showIntersectError || this.latLngs.length < 2))
  ) {
  <div class="elevation-profile-card-container">
    <div class="elevation-profile-card-header">
      <div class="elevation-profile-card-title">{{ controlOptions.cardTitle | transloco }}</div>
      <div class="elevation-profile-card-info-box">
        <img
          class="elevation-profile-info-icon"
          [src]="controlOptions.infoIcon"
          alt="info-icon"
          cc-tooltip
          [template]="elevationProfileInfoToolTipTemplate"
          tooltipPosition="top-right"
          />
          <img
            cc-tooltip="{{ 'app.close' | transloco }}"
            class="elevation-profile-close-icon"
            (click)="exitElevationProfileDrawing()"
            [src]="controlOptions.closeIcon"
            alt="close-icon"
            />
          </div>
        </div>
        <div class="elevation-profile-card-content">
          <ul class="elevation-profile-card-info-list">
            @for (infoText of controlOptions.cardInfo; track trackByForLoop(i); let i = $index) {
              <li
                class="elevation-profile-card-info"
          [ngClass]="{
            'intersect-error': showIntersectError && isElevationProfileFinish && i === 1,
            'min-two-point-error': latLngs.length === 1 && i === 0
          }"
                >
                {{ infoText | transloco }}
              </li>
            }
          </ul>
        </div>
      </div>
    }

    <ng-template #elevationProfileInfoToolTipTemplate>
      <div class="elevation-profile-tooltip">
        <span>{{ controlOptions.infoTooltip | transloco | transloco }}</span>
      </div>
    </ng-template>
