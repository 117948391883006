import { CircleMarker, Marker } from 'leaflet';
export const DataMarker = Marker.include({
  _popupData: {},

  getPopupData() {
    return this._popupData;
  },

  setPopupData(popupData: any) {
    this._popupData = popupData;
  }
});

export const DataCircleMarker = CircleMarker.include({
  _popupData: {},

  getPopupData() {
    return this._popupData;
  },

  setPopupData(popupData: any) {
    this._popupData = popupData;
  }
});
