import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { Control, LeafletEvent, gridLayer } from 'leaflet';
import 'leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant';
import { MapViewLayerToggleSwitchControl } from '../../controls/map-view-layer-toggle-control';
import { HIDE_STREET_INFO } from '../../map-demo.config';
import {
  DEFAULT_SELECTED_TOOL,
  DEFAULT_VIEW_LAYER_TOGGLE_OPTIONS,
  ELEVATION_PROFILE_LAYER,
  GEOFENCE_SUPPORT_LAYERS,
  GEOFENCE_UNSUPPORT_LAYERS,
  MAP_VIEW_LABELS,
  MORE_MAP_LAYER,
  SELECTED_LAYER
} from '../../map.constants';
import { MapViewType, ViewLayerToggleOptions } from '../../map.model';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-view-layer-toggle',
  templateUrl: './view-layer-toggle-control.component.html',
  styleUrls: ['./view-layer-toggle-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewLayerToggleComponent implements OnChanges, OnInit, OnDestroy {
  @Input() options: ViewLayerToggleOptions;
  @Input() enableTrafficLayer = false;
  @Input() enableSpeedDefaultLayer = false;
  @Input() isTrafficON = false;
  @Input() isSpeedDefaultON = false;
  @Input() isElevationON = false;
  @Input() disableZoneLayer = false;
  @Input() isDrawEditZoneInProgress = false;
  @Input() zoneLayerInfo: any = null;
  @Input() elevationProfileExitLayerInfo = {};
  @Input() isPollingActive: boolean = false;
  @Output() showZonePollingDiscardPopup = new EventEmitter<any>();
  @Output() mapTypeChange = new EventEmitter<string>();
  @Output() showZoneDiscardModal = new EventEmitter<any>();
  @Output() showElevationProfileDiscardModal = new EventEmitter<string>();
  selectedView: string;
  selectedLayer = { ...SELECTED_LAYER };
  selectedTool = { ...DEFAULT_SELECTED_TOOL };
  mapViewTypes = MapViewType;
  showMoreLayers = false;
  elevationProfileLayer = ELEVATION_PROFILE_LAYER;
  isMeasureDistanceON = false;
  private control: Control;
  private readonly element: HTMLElement;

  constructor({ nativeElement }: ElementRef, private readonly mapService: MapService) {
    this.element = nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.enableTrafficLayer && !changes.enableTrafficLayer.currentValue && this.isTrafficON) {
      this.isTrafficON = false;
    }
    if (changes?.enableSpeedDefaultLayer && !changes.enableSpeedDefaultLayer.currentValue && this.isSpeedDefaultON) {
      this.isSpeedDefaultON = false;
    }
    this.resetLayer(changes);
    if (changes?.options && this.options.unselectAllLayer) {
      this.selectedLayer = { ...SELECTED_LAYER };
      this.selectedTool[MapViewType.ZONE] = false;
    }
    if (changes?.zoneLayerInfo && this.zoneLayerInfo?.resetZoneLayer) {
      this.selectedTool[MapViewType.ZONE] = false;
    }
    if (changes?.zoneLayerInfo && this.zoneLayerInfo?.layerName) {
      if (this.zoneLayerInfo.layerName === MapViewType.MEASURE_DISTANCE) {
        this.toggleMapTools(MapViewType.MEASURE_DISTANCE);
      } else if (this.zoneLayerInfo.layerName !== MapViewType.ZONE) {
        this.onMapDetail(this.zoneLayerInfo.layerName);
      }
    }
    this.showOrHideLayers();
    this.checkForElevationProfileExit(changes);
  }

  checkForElevationProfileExit(changes: SimpleChanges): void {
    if (
      changes?.elevationProfileExitLayerInfo &&
      this.elevationProfileExitLayerInfo['exit'] &&
      (this.elevationProfileExitLayerInfo['layerName'] === MapViewType.MEASURE_DISTANCE ||
        this.elevationProfileExitLayerInfo['layerName'] === MapViewType.ELEVATION_PROFILE)
    ) {
      this.toggleMapTools(this.elevationProfileExitLayerInfo['layerName']);
    } else if (changes?.elevationProfileExitLayerInfo && this.elevationProfileExitLayerInfo['exit']) {
      this.selectedTool[MapViewType.ELEVATION_PROFILE] = !this.selectedTool[MapViewType.ELEVATION_PROFILE];
      this.onMapDetail(this.elevationProfileExitLayerInfo['layerName']);
    }
  }

  ngOnInit(): void {
    this.createControl();
    this.selectedLayer[this.options.defaultLayer] = true;
    if (this.options.isTablet) {
      this.showMoreLayers = true;
      this.populateMoreLayers();
    }
  }

  ngOnDestroy(): void {
    if (this.control) {
      this.control.remove();
    }
  }

  getMutantLayer(view: MapViewType): any {
    const viewType = view === MapViewType.TRAFFIC || view === MapViewType.TRANSIT ? MapViewType.ROAD : view;
    const options = isDemoModeON() ? { type: viewType, styles: HIDE_STREET_INFO } : { type: viewType };
    return gridLayer.googleMutant(options);
  }

  private createControl(): void {
    const options = {
      ...DEFAULT_VIEW_LAYER_TOGGLE_OPTIONS,
      ...this.options
    };

    let defaultView = '';
    if (this.control) {
      const currentView = this.control.getCurrentLayer();
      if (currentView && this.mapService.getMap().hasLayer(currentView.layer)) {
        defaultView = currentView.key;
        this.mapService.getMap().removeLayer(currentView.layer);
      }
    }
    const views = {};
    for (const view of options.mapViews) {
      const mutantLayer = this.getMutantLayer(view);
      if (view === MapViewType.TRAFFIC || view === MapViewType.TRANSIT) {
        mutantLayer.addGoogleLayer(view);
      }
      views[MAP_VIEW_LABELS[view]] = mutantLayer;
    }
    if (defaultView === '') {
      defaultView = options.mapViews.includes(options.defaultMapView)
        ? MAP_VIEW_LABELS[options.defaultMapView]
        : MAP_VIEW_LABELS[options.mapViews[0]];
    }
    if (this.control) {
      this.mapService.getMap().removeControl(this.control);
    }
    this.selectedView = defaultView.toLowerCase();
    const trafficLayer = options.showTrafficLayer ? this.element : null;
    this.control = new MapViewLayerToggleSwitchControl(views, defaultView, { position: options.position }, trafficLayer);
    this.control.addTo(this.mapService.getMap());
    this.control.on('maptypechange', ({ mapType }: LeafletEvent) => {
      this.selectedView = mapType.toLowerCase();
      this.mapTypeChange.emit(mapType);
    });
  }

  resetLayer(changes: SimpleChanges): void {
    if (changes?.options && this.options.resetLayer && this.options.mapLayers?.length) {
      this.control?._close();
      this.control?._addHoverEvent(true);
      this.selectedLayer = { ...SELECTED_LAYER };
      this.selectedLayer[this.options.defaultLayer] = true;
      this.showMoreLayers = this.options.isTablet;
    }
  }

  onMapDetail(mapView: MapViewType) {
    if (mapView === MapViewType.TRAFFIC) {
      this.isTrafficON = !this.isTrafficON;
      this.isSpeedDefaultON = false;
      if (this.isTrafficON) {
        this.control._close();
        this.hideMoreLayers();
      }
    } else if (mapView === MapViewType.SPEED_DEFAULT) {
      this.isSpeedDefaultON = !this.isSpeedDefaultON;
      this.isTrafficON = false;
      if (this.isSpeedDefaultON) {
        this.control._close();
        this.hideMoreLayers();
      }
    } else if (mapView !== MapViewType.MORE) {
      this.isTrafficON = false;
      this.isSpeedDefaultON = false;
    }
    if (mapView === MapViewType.MORE) {
      this.showMoreLayers = true;
      this.control._addHoverEvent(false);
      this.populateMoreLayers();
    } else {
      this.toggleAndCloseLayers(mapView);
    }
    this.mapTypeChange.emit(mapView);
  }

  handleMeasureDistanceToggle(mapView: string): void {
    this.isMeasureDistanceON = !this.isMeasureDistanceON;
    this.mapTypeChange.emit(mapView);
  }

  toggleAndCloseLayers(mapView: MapViewType): void {
    for (const layer in this.selectedLayer) {
      if (layer === mapView) {
        this.selectedLayer[mapView] = !this.selectedLayer[mapView];
      } else {
        this.selectedLayer[layer] = false;
      }
      if (this.selectedLayer[layer]) {
        this.control._close();
        this.hideMoreLayers();
      }
    }
  }

  toggleMapTools(toolName: MapViewType): void {
    this.selectedTool[toolName] = !this.selectedTool[toolName];
    if (toolName === MapViewType.ELEVATION_PROFILE) {
      this.selectedTool[MapViewType.MEASURE_DISTANCE] = false;
    } else if (toolName === MapViewType.MEASURE_DISTANCE) {
      this.selectedTool[MapViewType.ELEVATION_PROFILE] = false;
    }
    if (this.selectedTool[toolName]) {
      this.control?._close();
      this.hideMoreLayers();
    }
    this.mapTypeChange.emit(toolName);
  }

  showOrHideLayers(): void {
    if (this.showMoreLayers) {
      this.populateMoreLayers();
    } else {
      this.hideMoreLayers();
    }
  }

  populateMoreLayers() {
    if (this.options.mapLayers?.length) {
      this.options.mapLayers = this.options.mapLayers.filter(element => element.layerName !== MapViewType.MORE);
      const alreadyPopulated = this.options.mapLayers.find(
        element => element.layerName === this.options.additionalMapLayers?.[0]?.layerName
      );
      if (!alreadyPopulated && this.options.additionalMapLayers?.length) {
        this.options.mapLayers = [...this.options.mapLayers, ...this.options.additionalMapLayers];
      }
    }
  }

  hideMoreLayers() {
    if (!this.options.isTablet) {
      this.showMoreLayers = false;
      this.control?._addHoverEvent(true);
      if (this.options.mapLayers?.length) {
        this.options.mapLayers = this.options.mapLayers.filter(element => element.layerName !== MapViewType.MORE);
        if (this.options.additionalMapLayers?.length) {
          const additionalMapLayers = this.options.additionalMapLayers.map(element => element.layerName);
          this.options.mapLayers = this.options.mapLayers.filter(element => !additionalMapLayers.includes(element.layerName));
        }
        this.options.mapLayers.push(MORE_MAP_LAYER);
      }
    }
  }

  trackToolsBy(id: number): number {
    return id;
  }

  onMapTypeChanged(mapType: string) {
    this.control._setToggleType(mapType);
    this.control._click();
  }

  onViewLayerClose() {
    this.control._close();
    this.hideMoreLayers();
  }

  discardPopupForMapTool(isFromClose: boolean, layerName: MapViewType): void {
    const isShowModal = (): boolean =>
      (layerName === MapViewType.ZONE && this.selectedTool[MapViewType.ZONE]) ||
      (layerName === MapViewType.MEASURE_DISTANCE && this.isDrawEditZoneInProgress);
    if (this.isPollingActive) {
      this.showZonePollingDiscardPopup.emit({ isFromClose, layerName });
    } else if (isShowModal()) {
      this.showZoneDiscardModal.emit({ isFromClose, layerName });
    } else if (this.selectedTool[MapViewType.ELEVATION_PROFILE]) {
      this.showElevationProfileDiscardModal.emit(layerName);
    } else {
      this.toggleMapTools(layerName);
    }
  }

  showZoneDiscardPopup(isFromClose: boolean, layerName: MapViewType): void {
    const isShowModal = (): boolean =>
      layerName !== MapViewType.MORE &&
      this.selectedTool[MapViewType.ZONE] &&
      (GEOFENCE_UNSUPPORT_LAYERS.includes(layerName) || this.selectedLayer[layerName] || this.isDrawEditZoneInProgress);
    if (layerName !== MapViewType.MORE && this.isPollingActive) {
      this.showZonePollingDiscardPopup.emit({
        isFromClose,
        layerName,
        isGeoFenceSupportLayer: GEOFENCE_SUPPORT_LAYERS.includes(layerName)
      });
    } else if (isShowModal()) {
      this.showZoneDiscardModal.emit({ isFromClose, layerName });
    } else if (layerName !== MapViewType.MORE && this.selectedTool[MapViewType.ELEVATION_PROFILE]) {
      this.showElevationProfileDiscardModal.emit(layerName);
    } else {
      this.onMapDetail(layerName);
    }
  }

  disableMapTool(layerName: string): boolean {
    if (layerName === MapViewType.ZONE) {
      return (
        Object.values(this.selectedLayer).every(element => !element) || this.isTrafficON || this.isElevationON || this.disableZoneLayer
      );
    } else if (layerName === MapViewType.ELEVATION_PROFILE) {
      return !this.isElevationON;
    } else {
      return false;
    }
  }
}
