/* eslint-disable complexity */
import { Component, ContentChild, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FeatureGroup, Icon, Canvas as LCanvas, Marker as LMarker, LatLng, LeafletEvent, circleMarker } from 'leaflet';

import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { customCanvas } from '../../layers/custom-canvas.layer';
import { DataCircleMarker, DataMarker } from '../../layers/data-marker.layer';
import { MAP_LAYERS } from '../../map-demo.config';
import { DEFAULT_MARKER_OPTIONS } from '../../map.constants';
import { LatLngObject, Marker, MarkerIcon, MarkerOptions, MarkerTrackPosition } from '../../map.model';
import { MapService } from '../../map.service';
import { CoreUtility } from '../../utilities/core-utility.service';
import { Popup } from '../popup/popup.component';

@Component({
  selector: 'app-markers',
  template: ''
})
export class Markers implements OnInit, OnChanges, OnDestroy {
  @Input()
  data: Marker[];

  @Input()
  showAsCircleMarker = false;

  @Input()
  highlightMarkerOnClick = true;

  @Input()
  defaultOptions: MarkerOptions;

  protected markerTrack: boolean;
  protected canvasMarkerRenderer: LCanvas;

  @Input()
  set appMarkerTrack(_track: boolean) {
    this.markerTrack = _track;
  }

  @Output() markerClick = new EventEmitter<LMarker | circleMarker>();
  @Output() markerMouseout = new EventEmitter<LMarker | circleMarker>();
  @Output() markerMouseover = new EventEmitter<LMarker | circleMarker>();
  @Output() markersLoad = new EventEmitter<LMarker[] | circleMarker[]>();
  @Output() markerPath = new EventEmitter<any>();

  @ContentChild(Popup) markerPopup: Popup;

  private activeMarker: LMarker | circleMarker;

  private markersRef: LMarker[] | circleMarker[] = [];

  private lastTrackedMarker: LMarker | circleMarker;

  protected markersGroup: FeatureGroup;

  constructor(protected mapService: MapService) {}

  ngOnInit() {
    this.attachMapEventListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      isDemoModeON() && this.mapService.fitBoundsMap({ data: this.data, view: MAP_LAYERS.markers });
      this.addMarkersLayer(this.data, this.defaultOptions);
    }
  }

  ngOnDestroy() {
    /**
     * Detaching all listeners and clear layers
     */
    this.detachMarkersEventListeners();
  }

  /**
   * Method used to attach marker listeners if obsevers are available
   */
  protected attachMapEventListeners() {
    this.mapService.getMap().on('click', this.mapClickListener, this);
    this.mapService.getMap().on('zoomstart', this.removeHighlight, this);
  }

  /**
   * Generate Marker Icon with the given Icon Spec
   * @param iconSpec of type MarkerIcon
   */

  private generateIcon(iconSpec: MarkerIcon): Icon {
    if (iconSpec.html) {
      return iconSpec.html;
    }
    return new Icon({
      iconUrl: iconSpec.url,
      iconSize: [iconSpec.size?.x, iconSpec.size?.y],
      iconAnchor: [iconSpec.iconAnchor?.x, iconSpec.iconAnchor?.y]
    });
  }

  /**
   * Remove highlighted icon when another icon or map is clicked
   *
   */

  public removeHighlight(): void {
    if (this.activeMarker && this.highlightMarkerOnClick) {
      const curIcon = this.activeMarker.getIcon();
      this.activeMarker.setIcon(this.activeMarker.options.highlightIcon);
      this.activeMarker.options.highlightIcon = curIcon;
      this.activeMarker = null;
    }
  }

  /**
   * Highlight marker with provided highlight icon when icon is clicked
   * @param marker of type LMarker | circleMarker
   */

  public highlightMarker(marker: LMarker | circleMarker): void {
    this.removeHighlight();
    if (marker.options.highlightIcon) {
      const { highlightIcon } = marker.options;
      marker.options.highlightIcon = marker.getIcon();
      marker.setIcon(highlightIcon);
      this.activeMarker = marker;
    }
  }

  private clickListener(event: LeafletEvent): void {
    this.handleEvent(this.markerClick, event.propagatedFrom);

    if (this.highlightMarkerOnClick) {
      this.highlightMarker(event.propagatedFrom);
    }

    if (this.markerPopup && event.propagatedFrom.options.popup === true) {
      this.markerPopup.show(event.propagatedFrom.getLatLng(), event.propagatedFrom.getPopupData());
    }
    this.trackMarker(event.propagatedFrom);
  }

  private mouseoutListener(event: LeafletEvent): void {
    this.markerMouseout.emit(event.propagatedFrom);
  }

  private mouseoverListener(event: LeafletEvent): void {
    this.markerMouseover.emit(event.propagatedFrom);
  }

  private loadsListener(event: LeafletEvent) {
    this.markersLoad.emit(event.target.getLayers());
  }

  /**
   * Method used to attach marker listeners if obsevers are available
   */
  protected attachMarkerEventListeners(): Promise<any> {
    this.markersGroup.on('click', this.clickListener, this);
    this.mapService.getMap().on('popupclose', this.removeHighlight, this);
    if (this.markersLoad.observers.length > 0) {
      this.markersGroup.on('add', this.loadsListener, this);
    }

    if (this.markerMouseout.observers.length > 0) {
      this.markersGroup.on('mouseout', this.mouseoutListener, this);
    }

    if (this.markerMouseover.observers.length > 0) {
      this.markersGroup.on('mouseover', this.mouseoverListener, this);
    }

    return Promise.resolve();
  }

  protected detachMarkersEventListeners(): void {
    this.mapService.getMap().off('click', this.mapClickListener, this);
    this.mapService.getMap().off('zoomstart', this.removeHighlight, this);
    this.mapService.getMap().off('popupclose', this.removeHighlight, this);

    if (this.markersGroup) {
      this.markersGroup.off('add', this.loadsListener, this);
      this.markersGroup.off('click', this.clickListener, this);
      this.markersGroup.off('mouseout', this.mouseoutListener, this);
      this.markersGroup.off('mouseover', this.mouseoverListener, this);
      CoreUtility.removeLayerGroup(this.mapService.getMap(), this.markersGroup);
    }

    this.canvasMarkerRenderer.removeAllListeners();
  }

  /**
   * Return the from and to path of marker when marker track is on and marker is clicked
   * @param marker of type LMarker | circleMarker
   */

  protected trackMarker(marker: LMarker | circleMarker): void {
    if (!this.markerTrack) {
      return;
    }

    if (this.markersRef.length <= 1) {
      return;
    }

    const path: any = {};

    path[MarkerTrackPosition.CURRENT] = this.getMarkerLatLng(marker);

    const currentIndex = this.markersRef.indexOf(marker);

    if (this.markersRef[currentIndex - 1]) {
      path[MarkerTrackPosition.PREVIOUS] = this.getMarkerLatLng(this.markersRef[currentIndex - 1]);
    }

    if (this.markersRef[currentIndex + 1]) {
      path[MarkerTrackPosition.NEXT] = this.getMarkerLatLng(this.markersRef[currentIndex + 1]);
    }

    this.lastTrackedMarker = marker;

    this.markerPath.emit(path);
  }

  protected handleEvent<T>(eventEmitter: EventEmitter<T>, event: T) {
    if (eventEmitter.observers.length > 0) {
      eventEmitter.emit(event);
    }
  }

  protected mapClickListener(event: LeafletEvent): void {
    this.removeHighlight();
  }

  /**
   * Generate Leaflet markers with default & specific options and add it to fearture group
   * @param data of type Marker[]
   * @param options of type MarkerOptions
   */

  protected addMarkersLayer(data: Marker[], options: MarkerOptions): void {
    if (this.markersGroup && this.mapService.getMap().hasLayer(this.markersGroup)) {
      this.mapService.getMap().removeLayer(this.markersGroup);
    }

    this.generateMarkers(data, options).then((markers: LMarker[] | circleMarker[]) => {
      this.markersRef = markers;

      if (markers.length > 0) {
        this.createFeatureGroup(markers);
      }
    });
  }

  /**
   * Create new feature group with leaflet markers and attach it to map
   * @param markers of type LMarker[] | circleMarker[]
   */

  protected createFeatureGroup(markers: LMarker[] | circleMarker[]): void {
    this.markersGroup = new FeatureGroup(markers);
    this.attachMarkerEventListeners().then(() => {
      this.markersGroup.addTo(this.mapService.getMap());
    });
  }

  /**
   * Generate Leaflet markers with default & specific options
   * @param data of type Marker[]
   * @param defaultOptions of type MarkerOptions
   */

  protected generateMarkers(markersData: Marker[], defaultOptions: MarkerOptions): Promise<LMarker[] | circleMarker[]> {
    return new Promise((resolve, reject) => {
      const markers: LMarker[] | circleMarker[] = [];
      this.canvasMarkerRenderer = new customCanvas({ padding: 0.1, pane: 'overlayPane' });

      if (this.showAsCircleMarker) {
        markersData.forEach(markerData => {
          const marker = new DataCircleMarker(markerData.point, {
            ...DEFAULT_MARKER_OPTIONS,
            radius: 8,
            color: 'white',
            fillOpacity: 1,
            fillColor: markerData.options.color,
            weight: 2,
            zIndexOffset: -1,
            bubblingMouseEvents: false,
            renderer: this.canvasMarkerRenderer
          });
          marker.setPopupData(markerData.popupData);
          markers.push(marker);
        });
      } else {
        const markerOptions: any = {
          ...DEFAULT_MARKER_OPTIONS,
          ...defaultOptions
        };

        markerOptions.defaultIcon = this.generateIcon(markerOptions.icon);
        markerOptions.icon = this.generateIcon(markerOptions.icon);

        if (markerOptions.highlightIcon) {
          markerOptions.highlightIcon = this.generateIcon(markerOptions.highlightIcon);
        }

        let specificMarkerOptions: any;

        for (const markersDataValue of markersData) {
          specificMarkerOptions = markersDataValue.options;
          specificMarkerOptions = this.updateSpecificMarkerOptions(specificMarkerOptions);

          const marker = new DataMarker(markersDataValue.point, {
            ...markerOptions,
            ...specificMarkerOptions
          });

          if (markersDataValue.popupData) {
            marker.setPopupData(markersDataValue.popupData);
          }
          markers.push(marker);
        }
      }
      resolve(markers);
    });
  }

  private updateSpecificMarkerOptions(specificMarkerOptions: any): any {
    if (specificMarkerOptions && specificMarkerOptions.icon) {
      specificMarkerOptions.icon = this.generateIcon(specificMarkerOptions.icon);
    }

    if (specificMarkerOptions && specificMarkerOptions.highlightIcon) {
      specificMarkerOptions.highlightIcon = this.generateIcon(specificMarkerOptions.highlightIcon);
    }

    return specificMarkerOptions;
  }

  protected getMarkerLatLng(marker: LMarker | circleMarker): LatLng {
    return marker.getLatLng();
  }

  /**
   * Method to initialize markers with new set of markers . exposed to use by host
   * @param data of type Marker[]
   * @param options of type MarkerOptions
   */

  public initMarkers(data: Marker[], options?: MarkerOptions) {
    this.addMarkersLayer(data, options);
  }

  /**
   * Method to include single marker to feature group . exposed to use by host
   * @param marker of type Marker
   */

  public addMarkers(markers: Marker[]) {
    if ((this.markersGroup && !this.mapService.getMap().hasLayer(this.markersGroup)) || markers.length <= 0) {
      return;
    }

    this.generateMarkers(markers, this.defaultOptions).then((generatedMarkers: LMarker[] | circleMarker[]) => {
      if (generatedMarkers && generatedMarkers.length > 0) {
        for (const marker of generatedMarkers) {
          this.markersGroup?.addLayer(marker);
          this.markersRef.push(marker);
        }

        this.handleEvent(this.markersLoad, generatedMarkers);
      }
    });
  }

  /**
   * Methods to remove markers
   * @param markers
   */
  public removeMarkers(markers: LMarker[] | circleMarker[]) {
    if (this.markersGroup && !this.mapService.getMap().hasLayer(this.markersGroup)) {
      return;
    }

    let markerIndex: number, lastTrackedMarkerIndex: number, trackRequired: boolean;
    for (const marker of markers) {
      if (!this.markersGroup?.hasLayer(marker)) {
        continue;
      }

      this.markersGroup.removeLayer(marker);

      markerIndex = this.markersRef.indexOf(marker);

      //To reset or redraw marker tracklines if required
      if (this.markerTrack === true && this.lastTrackedMarker) {
        lastTrackedMarkerIndex = this.markersRef.indexOf(this.lastTrackedMarker);
        if (lastTrackedMarkerIndex === markerIndex) {
          this.markerPath.emit({});
        } else if (markerIndex + 1 === lastTrackedMarkerIndex || markerIndex - 1 === lastTrackedMarkerIndex) {
          trackRequired = true;
        }
      }

      this.markersRef.splice(markerIndex, 1);
      if (trackRequired) {
        this.trackMarker(this.lastTrackedMarker);
      }
    }
  }

  public getBounds(): any {
    if (this.markersGroup) {
      return this.markersGroup.getBounds();
    }
    return undefined;
  }

  public setIcon(marker: LMarker | circleMarker, icon: MarkerIcon): void {
    const leafletIcon: Icon = this.generateIcon(icon);
    marker.setIcon(leafletIcon);
  }

  public resetIcon(marker: LMarker | circleMarker): void {
    marker.setIcon(marker.options.defaultIcon);
  }

  public setLatLng(marker: LMarker | circleMarker, latLng: LatLngObject): void {
    if (this.markersGroup?.hasLayer(marker)) {
      marker.setLatLng(latLng);
    }
  }

  public addLeafletMarker(marker: LMarker | circleMarker): void {
    marker.addTo(this.mapService.getMap());
  }
}
