import { DomUtil, Popup } from 'leaflet';

import { Point, toPoint } from 'leaflet/src/geometry/Point';

import { PopupPosition } from '../map.model';

export const CustomPopup = Popup.extend({
  _updatePosition() {
    if (!this._map) {
      return;
    }
    const pos = this._map.latLngToLayerPoint(this._latlng);
    let offset = toPoint(this.options.offset);
    const anchor = this._getAnchor();

    if (this._zoomAnimated) {
      DomUtil.setPosition(this._container, pos.add(anchor));
    } else {
      offset = offset.add(pos).add(anchor);
    }

    switch (this.options.position) {
      case PopupPosition.RIGHT: {
        const left = (this._containerLeft = offset.x),
          bottom = (this._containerBottom = -Math.round(this._container.offsetHeight / 2) - offset.y);
        this._container.style.left = left + 'px';
        this._container.style.bottom = bottom + 'px';
        this._container.style.margin = '0 0 0 20px';
        this._container.children[1].style.top = '50%';
        this._container.children[1].style.left = '0px';
        this._container.children[1].style.margin = '-10px 0 0 -30px';
        this._container.children[1].style.transform = 'rotate(90deg)';
        break;
      }
      case PopupPosition.LEFT: {
        const right = (this._containerLeft = offset.x),
          bottom = (this._containerBottom = -Math.round(this._container.offsetHeight / 2) - offset.y);
        this._container.style.right = right + 'px';
        this._container.style.bottom = bottom + 'px';
        this._container.style.margin = '0 20px 0 0';
        this._container.children[1].style.bottom = '50%';
        this._container.children[1].style.left = '100%';
        this._container.children[1].style.margin = '0 0 -10px -10px';
        this._container.children[1].style.transform = 'rotate(270deg)';
        break;
      }
      case PopupPosition.BOTTOM: {
        const top = (this._containerTop = -offset.y),
          left = (this._containerLeft = -Math.round(this._containerWidth / 2) + offset.x);
        this._container.style.top = top + 'px';
        this._container.style.left = left + 'px';
        this._container.style.margin = '20px 0 0 0';
        this._container.children[1].style.top = '-20px';
        this._container.children[1].style.transform = 'rotate(180deg)';
        break;
      }
      case PopupPosition.TOP: {
        const bottom = (this._containerBottom = -offset.y),
          left = (this._containerLeft = -Math.round(this._containerWidth / 2) + offset.x);
        this._container.style.bottom = bottom + 'px';
        this._container.style.left = left + 'px';
        break;
      }
      default:
        break;
    }
  },
  _adjustPan() {
    if (!this.options.autoPan) {
      return;
    }
    if (this._map._panAnim) {
      this._map._panAnim.stop();
    }
    const map = this._map;
    let containerHeight, containerWidth, layerPos;
    switch (this.options.position) {
      case PopupPosition.RIGHT: {
        const marginLeft = parseInt(DomUtil.getStyle(this._container, 'marginLeft'), 10) || 0;
        containerHeight = this._container.offsetHeight;
        containerWidth = this._container.offsetWidth + marginLeft;
        layerPos = new Point(this._containerLeft, -containerHeight - this._containerBottom);
        break;
      }
      case PopupPosition.LEFT: {
        const marginRight = parseInt(DomUtil.getStyle(this._container, 'marginRight'), 10) || 0;
        containerHeight = this._container.offsetHeight;
        containerWidth = this._container.offsetWidth + marginRight;
        layerPos = new Point(-this._containerLeft - this._container.offsetWidth - marginRight, -containerHeight - this._containerBottom);
        break;
      }
      case PopupPosition.BOTTOM: {
        const marginTop = parseInt(DomUtil.getStyle(this._container, 'marginTop'), 10) || 0;
        containerHeight = this._container.offsetHeight + marginTop;
        containerWidth = this._containerWidth;
        layerPos = new Point(this._containerLeft, this._containerTop);
        break;
      }
      case PopupPosition.TOP: {
        const marginBottom = parseInt(DomUtil.getStyle(this._container, 'marginBottom'), 10) || 0;
        containerHeight = this._container.offsetHeight + marginBottom;
        containerWidth = this._containerWidth;
        layerPos = new Point(this._containerLeft, -containerHeight - this._containerBottom);
        break;
      }
      default:
        break;
    }
    layerPos._add(DomUtil.getPosition(this._container));
    const containerPos = map.layerPointToContainerPoint(layerPos),
      padding = toPoint(this.options.autoPanPadding),
      paddingTL = toPoint(this.options.autoPanPaddingTopLeft || padding),
      paddingBR = toPoint(this.options.autoPanPaddingBottomRight || padding),
      size = map.getSize();
    let dx = 0,
      dy = 0;

    if (containerPos.x + containerWidth + paddingBR.x > size.x) {
      // right
      dx = containerPos.x + containerWidth - size.x + paddingBR.x;
    }
    if (containerPos.x - dx - paddingTL.x < 0) {
      // left
      dx = containerPos.x - paddingTL.x;
    }
    if (containerPos.y + containerHeight + paddingBR.y > size.y) {
      // bottom
      dy = containerPos.y + containerHeight - size.y + paddingBR.y;
    }
    if (containerPos.y - dy - paddingTL.y < 0) {
      // top
      dy = containerPos.y - paddingTL.y;
    }

    // @namespace Map
    // @section Popup events
    // @event autopanstart: Event
    // Fired when the map starts autopanning when opening a popup.
    if (dx || dy) {
      map.fire('autopanstart').panBy([dx, dy]);
    }
  }
});
