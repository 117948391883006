import { Directive, HostListener, Input } from '@angular/core';
import { LayerGroup, Polyline, layerGroup, polyline } from 'leaflet';
import 'leaflet-arrowheads';

import { DEFAULT_MARKER_TRACK_OPTIONS, DEFAULT_POLY_ARROW_OPTIONS } from '../../map.constants';
import {
  ArrowHeadsOptions,
  MarkerTrackData,
  MarkerTrackOptions,
  MarkerTrackPosition,
  PolyLineOptions,
  TrackOptions
} from '../../map.model';
import { MapService } from '../../map.service';

@Directive({
  selector: '[appMarkerTrack]'
})
export class MarkerTrack {
  private customPoly: LayerGroup | Polyline; //customized poly line

  //for accessing the private variables
  get customPolyValue(): LayerGroup | Polyline {
    return this.customPoly;
  }
  @Input()
  markerTrackOptions: MarkerTrackOptions = DEFAULT_MARKER_TRACK_OPTIONS; //get the input values from the users for line and arrow options

  @HostListener('markerPath', ['$event']) markerPath($event) {
    this.createPolyLine($event);
  }
  constructor(private readonly mapService: MapService) {}

  /**
   *
   * @param MarkersData = Array of markers data last,current and next markers data we will get
   *
   */
  private createPolyLine(MarkersData: MarkerTrackData) {
    this.resetPolyLines();

    if (!MarkersData[MarkerTrackPosition.CURRENT]) {
      return;
    }

    if (MarkersData[MarkerTrackPosition.PREVIOUS] && MarkersData[MarkerTrackPosition.NEXT]) {
      const { next, ...previousmarkerData } = MarkersData;
      const path1 = this.formLineArrow(previousmarkerData);
      const { previous, ...nextmarkerData } = MarkersData;
      const path2 = this.formLineArrow(nextmarkerData);
      this.customPoly = layerGroup([path1, path2]);
    } else {
      this.customPoly = this.formLineArrow(MarkersData);
    }
    this.customPoly.addTo(this.mapService.getMap());
    // zoom the map to the polyline
    //this.mapService.getMap().fitBounds(this.customPoly.getBounds()); //if needed will add
  }

  /**
   *
   * @param MarkersData  form the lineArrow with the markers data
   */
  private formLineArrow(markersData: MarkerTrackData) {
    const lineOption = DEFAULT_POLY_ARROW_OPTIONS.polyLineOption;
    const arrowOption = DEFAULT_POLY_ARROW_OPTIONS.arrowHeadOption;
    if (markersData[MarkerTrackPosition.PREVIOUS]) {
      this.formArrowOptions(lineOption, arrowOption, this.markerTrackOptions.prevTrackOptions);
    } else if (markersData[MarkerTrackPosition.NEXT]) {
      this.formArrowOptions(lineOption, arrowOption, this.markerTrackOptions.nextTrackOptions);
    }
    return polyline(this.formPolylineData(markersData), lineOption).arrowheads(arrowOption);
  }

  /**
   *
   * @param lineOption --polulineOptions
   * @param arrowOption  -- ArrowHeadsOptions
   * @param customOptions --TrackOptions
   */
  private formArrowOptions(lineOption: PolyLineOptions, arrowOption: ArrowHeadsOptions, customOptions: TrackOptions) {
    for (const key of Object.keys(customOptions)) {
      if (key) {
        const item = key.split('_');
        if (item[0] === 'line') {
          lineOption[item[1]] = customOptions[key];
        } else {
          arrowOption[item[1]] = customOptions[key];
        }
      }
    }
  }

  /**
   *
   * @param polyLineMarkerData = Array of markers data last,current and next markers data we will pass
   * convert to our format
   *[[45.51, -122.68],[37.77, -122.43],[34.04, -118.2]]
   */

  private formPolylineData(polyLineMarkerData: MarkerTrackData) {
    const lineData = [];
    for (const key of Object.keys(MarkerTrackPosition)) {
      if (polyLineMarkerData[MarkerTrackPosition[key]]) {
        lineData.push(Object.values(polyLineMarkerData[MarkerTrackPosition[key]]));
      }
    }
    return lineData;
  }

  /**
   * remove the existing poly lines
   */
  private resetPolyLines() {
    if (this.customPoly) {
      this.customPoly.remove();
    }
  }
}
