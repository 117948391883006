import { Control, DomEvent, DomUtil, Evented, Util } from 'leaflet';

/**
 * Leaflet recenter control
 *
 */

export const RecenterControl = Control.extend({
  includes: Evented.prototype,

  options: { position: 'bottomleft' },

  onAdd() {
    const container = DomUtil.create('div', 'map-ctrl-recenter leaflet-bar leaflet-control');
    this.link = DomUtil.create('button', 'leaflet-bar-part', container);
    DomEvent.on(this.link, 'click', this._click, this);
    DomEvent.on(container, 'touchstart dblclick mousewheel', this._stopPropagation, this);
    return container;
  },

  initialize(options) {
    Util.setOptions(this, options);
  },
  _stopPropagation(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.originalEvent) {
      // In case of Leaflet event.
      e.originalEvent._stopped = true;
    } else {
      e.cancelBubble = true;
    }
  },
  _click() {
    this.fire('recenter');
  },
  onRemove() {
    const container = this.getContainer();
    DomEvent.off(container, 'touchstart dblclick mousewheel', this._stopPropagation, this);
    DomEvent.off(this.link, 'click', this._click, this);
  }
});
