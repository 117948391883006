import { Control, DomEvent, DomUtil, Evented, Util } from 'leaflet';

/**
 * Leaflet OverlayDropdownControl control.
 * Will be always on top right of map
 * Will get content as input
 */
export const ThreeTilesOverlayControl = Control.extend({
  includes: Evented.prototype,
  _extended: false,
  onAdd(map) {
    this.container = DomUtil.create('div', 'three-tiles');
    this.icon = DomUtil.create('a', 'three-tiles-icon', this.container);
    this.icon.href = '#';

    DomEvent.disableClickPropagation(this.icon);
    DomEvent.on(this.icon, 'click', DomEvent.stop);
    DomEvent.on(this.icon, 'click', this._click, this);
    DomEvent.on(document, 'click', this.collapse, this);
    DomEvent.on(this.container, 'click mousedown touchstart dblclick mousewheel', this._stopPropagation, this);

    const section = DomUtil.create('div', 'three-tiles-section', this.container);
    section.appendChild(this._content);
    return this.container;
  },

  initialize(content, options) {
    this._content = content;
    Util.setOptions(this, options);
  },

  _click() {
    this._map.closePopup();
    this.fire('threeTilesClick');
    if (this._extended) {
      this.collapse();
    } else {
      this.expand();
    }
  },

  _stopPropagation(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.originalEvent) {
      // In case of Leaflet event.
      e.originalEvent._stopped = true;
    } else {
      e.cancelBubble = true;
    }
  },

  onRemove() {
    DomEvent.off(this.icon, 'click', this._click, this);
    DomEvent.off(document, 'click', this.collapse, this);
    DomEvent.off(this.container, 'click mousedown touchstart dblclick mousewheel', this._stopPropagation, this);
  },

  expand() {
    const container = this.getContainer();
    DomUtil.addClass(container, 'three-tiles-enable');
    this.fire('threeTilesExpand');
    this._extended = true;
  },

  collapse() {
    if (this._extended) {
      const container = this.getContainer();
      DomUtil.removeClass(container, 'three-tiles-enable');
      this._extended = false;
    }
  }
});
