import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { LatLng, svg } from 'leaflet';
import { DEMO_MODE_DEFAULT_OPTIONS, DEMO_MODE_LAT_LNG_FUNC, DEMO_MODE_MAP_CONFIGURATIONS, MAP_LAYERS } from './map-demo.config';
import { DEFAULT_OPTIONS, MAP_RENDER_PADDING } from './map.constants';
import {
  FitBoundsObject,
  LatLngObject,
  LayerDataConfigObject,
  LayersDataConfig,
  MapAdditionalOptions,
  MapOptions,
  Marker,
  PriorityViewObject,
  SiteData,
  VectorData
} from './map.model';

// Map Initializer Config based on Demo Mode
export function getInitializeMapConfig(options: MapOptions): MapAdditionalOptions {
  return isDemoModeON()
    ? { ...DEMO_MODE_DEFAULT_OPTIONS, renderer: svg({ padding: MAP_RENDER_PADDING }) }
    : {
        minZoom: options?.minZoom || DEFAULT_OPTIONS.minZoom,
        maxZoom: options?.maxZoom || DEFAULT_OPTIONS.maxZoom,
        attributionControl: DEFAULT_OPTIONS.attributionControl,
        scrollWheelZoom: options?.scrollWheelZoom || DEFAULT_OPTIONS.scrollWheelZoom,
        zoomControl: false,
        renderer: svg({ padding: MAP_RENDER_PADDING }),
        gestureHandling: options?.gestureHandling || DEFAULT_OPTIONS.gestureHandling,
        wheelPxPerZoomLevel: options?.wheelPxPerZoomLevel || DEFAULT_OPTIONS.wheelPxPerZoomLevel
      };
}

/**
 * Based on the Options, this function will return lat & lng to set MAP Bounds.
 * If we are not passing any options then it takes the first element from Layers Data.
 */
function getFitBoundsLatLng(data: LayersDataConfig, options: SiteData = {}): LatLngObject {
  if (data?.length) {
    for (const [key, value] of Object.entries(options)) {
      if (DEMO_MODE_FUNCTIONS?.[DEMO_MODE_LAT_LNG_FUNC?.[key]]) {
        return DEMO_MODE_FUNCTIONS[DEMO_MODE_LAT_LNG_FUNC[key]](value, data, key);
      }
    }
    return data[0]?.['point'] || data?.['points']?.[0];
  }
  return DEMO_MODE_DEFAULT_OPTIONS.center;
}

const DEMO_MODE_FUNCTIONS = {
  getLatLng(value: LatLngObject): LatLngObject {
    return value;
  },

  /**
   *
   * This will return lat & lng Bounds based on INDEX
   */
  getIndexBasedBounds(value: number, data: Marker[]): LatLngObject {
    if (value >= 0 && value < data.length) {
      return data[value]?.point;
    }
    return data[0]?.point;
  },

  /**
   *
   * This will return lat & lng Bounds based on SITEID/SubState
   */
  getSiteZoneIdSegmentsSubStateBounds(value: string, data: Marker[], siteIdOrSubState: string): LatLngObject {
    const siteOrSubStateData = data?.find(d => d.popupData?.[siteIdOrSubState] === value);
    if (siteOrSubStateData) {
      return siteOrSubStateData.point;
    }
    return data[0]?.point;
  },

  getVectorIndexBasedBounds(value: number, data: VectorData): LatLngObject {
    if (value >= 0 && value < data[0]?.['points']?.length) {
      return data[0]?.['points'][value];
    }
    return data[0]?.['points'][0];
  }
};

export function getLatLngBounds(data: LayersDataConfig, options: SiteData = {}): LatLngObject {
  const latLngBound = getFitBoundsLatLng(data, options);
  return latLngBound ?? (data[0]?.['point'] || data?.['points']?.[0]);
}

export function getLatLngObject(mapModule: string, _boundsMap: FitBoundsObject): LatLngObject[] {
  if (_boundsMap.view === MAP_LAYERS.tileLayer) {
    return _boundsMap.data as LatLngObject[];
  }
  const bounds = getLatLngBounds(
    _boundsMap.data,
    getFirstValueKeyValuePair(DEMO_MODE_MAP_CONFIGURATIONS?.[mapModule]?.group_by_views?.[_boundsMap.view])
  );
  return convertLatLng(bounds);
}

export function getConvertedLatLngObject(mapBoundsData: LayersDataConfig, mapLayer: SiteData): LatLngObject[] {
  const bounds = getLatLngBounds(mapBoundsData, mapLayer);
  return convertLatLng(bounds);
}

/**
 *
 * This will ignore all null/undefined values.
 * Will get very first value and ignore remaining as we can't set bounds for every properties
 */
export function getFirstValueKeyValuePair(demoModeSiteObj: SiteData): { [key: string]: number | LatLngObject } {
  for (const key of Object.keys(demoModeSiteObj)) {
    if (demoModeSiteObj[key] !== null && demoModeSiteObj[key] !== undefined) {
      return { [key]: demoModeSiteObj[key] };
    }
  }
  return { index: 0 };
}

/**
 *
 * Converting lat & lng object to actual MAP Bounds Object
 */
export function convertLatLng(_bounds: LatLngObject): LatLngObject[] {
  return [new LatLng(_bounds?.lat, _bounds?.lng)];
}

function priorityViewDefaultCondition(mapModule: string, boundsView: string, layersData: LayerDataConfigObject): boolean {
  return !Object.values(layersData)?.[0]?.length || boundsView === MAP_LAYERS.tileLayer;
}

export function isPriorityView(mapModule: string, boundsView: string, layersData: LayerDataConfigObject): boolean {
  if (!boundsView || !mapModule) {
    return false;
  }
  if (priorityViewDefaultCondition(mapModule, boundsView, layersData)) {
    return true;
  }
  const firstLayerDataKey = Object.keys(layersData)?.[0];
  const layerDataPriority = getPriorityView(firstLayerDataKey, mapModule);
  const boundsViewPriority = getPriorityView(boundsView, mapModule);
  return boundsViewPriority <= layerDataPriority;
}

function getPriorityView(boundView: string, mapModule: string): number {
  return DEMO_MODE_MAP_CONFIGURATIONS?.[mapModule]?.priorityView.find((view: PriorityViewObject) => view.layerName === boundView)?.priority;
}
