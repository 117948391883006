import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Control } from 'leaflet';
import 'leaflet-google-places-autocomplete/src/js/leaflet-gplaces-autocomplete';

import { DEFAULT_LOCATION_SERVICE_OPTIONS } from '../../map.constants';
import { LocationSearchOptions } from '../../map.model';
import { MapService } from '../../map.service';

@Directive({
  selector: '[appLocationSearch]'
})
export class LocationSearch implements OnInit {
  @Input() locationSearchOptions: LocationSearchOptions;

  @Output() searchCallback = new EventEmitter(); //Event triggered for callback if they need
  constructor(private readonly mapService: MapService) {}

  private searchControl: Control.GPlaceAutocomplete;
  private autoPan: boolean;

  ngOnInit(): void {
    const observ = of({})
      .pipe(delay(1500))
      .subscribe(() => {
        this.initSearchConsole();
        observ.unsubscribe();
      });
  }

  private initSearchConsole(): void {
    const searchOptions = { ...DEFAULT_LOCATION_SERVICE_OPTIONS, ...this.locationSearchOptions }; //merge the default options & user defined options
    this.autoPan = searchOptions.autoPan; //check user enabled Pan
    const { autoPan, ...consoleOptions } = searchOptions; //remove the pan check options

    this.searchControl = new Control.GPlaceAutocomplete({
      ...consoleOptions,
      callback: (location: any) => {
        this.searchHandler(location);
      }
    }).addTo(this.mapService.getMap());
  }

  private searchHandler(location: any): void {
    if (this.autoPan) {
      const loc = location.geometry.location;
      this.mapService.getMap().panTo([loc.lat(), loc.lng()]);
    }
    if (this.searchCallback.observers.length > 0) {
      this.searchCallback.emit(location);
    }
  }
}
