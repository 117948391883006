// https://terra-cat.visualstudio.com/TerraPlatform/_git/CI-UI-Workspace?version=GBpoc%2Fleaflet-sample-app
import * as L from 'leaflet';

L.Map.include({
  _initControlPos() {
    const corners = (this._controlCorners = {}),
      l = 'leaflet-',
      container = (this._controlContainer = L.DomUtil.create('div', l + 'control-container', this._container));

    function createCorner(vSide, hSide) {
      const className = `${l + vSide} ${l + hSide}`;

      corners[vSide + hSide] = L.DomUtil.create('div', className, container);
    }

    createCorner('top', 'left');
    createCorner('top', 'right');
    createCorner('bottom', 'left');
    createCorner('bottom', 'right');

    createCorner('top', 'center');
    createCorner('middle', 'center');
    createCorner('middle', 'left');
    createCorner('middle', 'right');
    createCorner('bottom', 'center');
  }
});

const zoom = L.Control.extend({
  // @section
  // @aka Control.Zoom options
  options: {
    position: 'topleft',

    // @option zoomInTitle: String = 'Zoom in'
    // The title set on the 'zoom in' button.
    zoomInTitle: 'Zoom in',

    // @option zoomOutTitle: String = 'Zoom out'
    // The title set on the 'zoom out' button.
    zoomOutTitle: 'Zoom out'
  },

  onAdd(map) {
    const zoomName = 'leaflet-control-zoom',
      container = L.DomUtil.create('div', zoomName + ' leaflet-bar'),
      options = this.options;

    this._zoomInButton = this._createButton(options.zoomInTitle, zoomName + '-in', container, this._zoomIn);
    this._zoomOutButton = this._createButton(options.zoomOutTitle, zoomName + '-out', container, this._zoomOut);

    this._updateDisabled();
    map.on('zoomend zoomlevelschange', this._updateDisabled, this);

    return container;
  },

  onRemove(map) {
    map.off('zoomend zoomlevelschange', this._updateDisabled, this);
  },

  disable() {
    this._disabled = true;
    this._updateDisabled();
    return this;
  },

  enable() {
    this._disabled = false;
    this._updateDisabled();
    return this;
  },

  _zoomIn(e) {
    if (!this._disabled && this._map._zoom < this._map.getMaxZoom()) {
      this._map.zoomIn(this._map.options.zoomDelta * (e.shiftKey ? 3 : 1));
    }
  },

  _zoomOut(e) {
    if (!this._disabled && this._map._zoom > this._map.getMinZoom()) {
      this._map.zoomOut(this._map.options.zoomDelta * (e.shiftKey ? 3 : 1));
    }
  },

  _createButton(title, className, container, fn) {
    const link = L.DomUtil.create('a', className, container);
    link.title = title;

    /*
     * Will force screen readers like VoiceOver to read this as "Zoom in - button"
     */
    link.setAttribute('role', 'button');
    link.setAttribute('aria-label', title);
    L.DomEvent.on(link, 'mousedown touchstart dblclick', this._stopPropagation, this);
    L.DomEvent.on(link, 'click', fn, this);
    L.DomEvent.on(link, 'click', this._refocusOnMap, this);

    return link;
  },

  _stopPropagation(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.originalEvent) {
      // In case of Leaflet event.
      e.originalEvent._stopped = true;
    } else {
      e.cancelBubble = true;
    }
  },

  _updateDisabled() {
    const map = this._map,
      className = 'leaflet-disabled';

    L.DomUtil.removeClass(this._zoomInButton, className);
    L.DomUtil.removeClass(this._zoomOutButton, className);

    if (this._disabled || map._zoom === map.getMinZoom()) {
      L.DomUtil.addClass(this._zoomOutButton, className);
    }
    if (this._disabled || map._zoom === map.getMaxZoom()) {
      L.DomUtil.addClass(this._zoomInButton, className);
    }
  }
});

L.control.zoom = function (options) {
  return new zoom(options);
};

export const zoomControl = function (options) {
  return new zoom(options);
}