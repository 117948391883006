import { Injectable } from '@angular/core';
import { Map } from 'leaflet';
import { BehaviorSubject, Subject } from 'rxjs';
import { FitBoundsObject } from './map.model';

@Injectable()
export class MapService {
  private map: Map;
  overlayClickSubject = new Subject<void>();
  overlayExpandSubject = new Subject<string>();

  fitBoundsSubject = new BehaviorSubject<FitBoundsObject>({ data: [], view: '' });

  constructor() {}

  getMap(): Map {
    return this.map;
  }

  initializeMap(map: Map): Promise<Map> {
    return new Promise((resolve, reject) => {
      this.map = map;
      resolve(this.map);
    });
  }

  fitBoundsMap(_bounds: FitBoundsObject): void {
    _bounds?.data?.length && this.fitBoundsSubject.next(_bounds);
  }
}
