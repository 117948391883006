import { Control, DomEvent, DomUtil, Evented, Util } from 'leaflet';
import { MapViewSwitchControlType } from '../map.model';

const MapViewSwitchControl = Control.extend({
  includes: Evented.prototype,
  _currentLayer: null,
  _map: null,
  _controlType: null,
  _mapTypes: null,
  _defaultMap: null,
  onAdd(map) {
    this._map = map;
    const container = DomUtil.create(
      'div',
      this._controlType === MapViewSwitchControlType.VERTICAL_IMAGE ? 'switch-control-image' : 'switch-control-text'
    );
    this._mapTypes[this._defaultMap].addTo(map);
    this._currentLayer = this._mapTypes[this._defaultMap];

    for (const prop of Object.keys(this._mapTypes)) {
      const className = prop.toLowerCase();
      const button = DomUtil.create('button', prop === this._defaultMap ? className + ' active' : className, container);
      button.textContent = this._controlType === MapViewSwitchControlType.HORIZONTAL_BAR ? prop : '';
      button.title = this._controlType === MapViewSwitchControlType.VERTICAL_IMAGE ? prop : '';
      button.mapType = prop;
      DomEvent.on(button, 'click', this._click, this);
    }
    DomEvent.on(container, 'touchstart dblclick mousewheel', this._stopPropagation, this);
    return container;
  },
  initialize(type, mapTypes, defaultMap, options) {
    Util.setOptions(this, options);
    this._mapTypes = mapTypes;
    this._defaultMap = defaultMap;
    this._controlType = type;
  },
  getCurrentLayer() {
    const container = this.getContainer();
    const buttons = container?.querySelectorAll('button.active');
    return { layer: this._currentLayer, key: buttons[0].mapType };
  },
  _click(event) {
    const container = this.getContainer();
    const currentButton = container?.querySelectorAll('button.active')[0];
    if (currentButton === event.currentTarget) {
      return;
    }
    this._map.removeLayer(this._currentLayer);
    currentButton.classList.remove('active');
    event.srcElement.classList.add('active');
    this._currentLayer = this._mapTypes[event.currentTarget.mapType];
    this._currentLayer.addTo(this._map);
    this.fire('maptypechange', { mapType: event.currentTarget.mapType });
  },
  _stopPropagation(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.originalEvent) {
      // In case of Leaflet event.
      e.originalEvent._stopped = true;
    } else {
      e.cancelBubble = true;
    }
  },
  onRemove() {
    const container = this.getContainer();
    DomEvent.off(container, 'touchstart dblclick mousewheel', this._stopPropagation, this);
    const buttons = container?.querySelectorAll('button');
    buttons.forEach(button => {
      DomEvent.off(button, 'click', this._click, this);
    });
  }
});

export { MapViewSwitchControl };
