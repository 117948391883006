import { Position } from '@Terra/shared/widgets/config';
import { DemoModeConfig, MapViewSwitchControlType, MapViewType } from './map.model';

/**
 * Config to restrict Map controls for DEMO MODE
 * zoom: 15 = 300m.
 * If we want to zoom-out then decrease the number (< 15).
 * If we want to zoom-in more then increase the number (> 15)
 */
export const DEMO_MODE_DEFAULT_OPTIONS = {
  center: { lat: 13.0827, lng: 80.2707 },
  zoom: 15, // For 300m Zoom range
  minZoom: 15,
  maxZoom: 15,
  scrollWheelZoom: false,
  gestureHandling: false,
  wheelPxPerZoomLevel: 180,
  attributionControl: false,
  zoomControl: false,
  zoomControlPosition: Position.BOTTOMRIGHT,
  scaleControl: false,
  scaleControlPosition: Position.BOTTOMRIGHT,
  baseMap: 'Google',
  mapView: MapViewType.SATELLITE,
  preventCentreUpdateOnResize: false,
  doubleClickZoom: false,
  touchZoom: false,
  boxZoom: false,
  mapViewSwitchControl: {
    type: MapViewSwitchControlType.VERTICAL_IMAGE,
    mapViews: [MapViewType.TERRAIN, MapViewType.SATELLITE],
    defaultMapView: MapViewType.SATELLITE,
    position: Position.BOTTOMRIGHT
  },
  invalidateSizeOnLoad: true,
  keyboard: false
};

export const MAP_VIEWS = {
  insights: 'insights',
  summaryDashboard: 'summaryDashboard',
  jobsiteMapView: 'jobsiteMapView',
  playBack: 'playBack'
};

export const MAP_LAYERS = {
  markers: 'markers',
  markersCluster: 'markersCluster',
  vectorLayer: 'vectorLayer',
  tileLayer: 'tileLayer'
};

/**
 * Set the Fit Bounds based on Layers and propeties.
 * We can show particular SiteID or Latitude & Longitudes Coordinates on MAP.
 * If we are not passing any data then it takes the first element from Layers Data.
 */
export const DEMO_MODE_MAP_CONFIGURATIONS: DemoModeConfig = {
  [MAP_VIEWS.jobsiteMapView]: {
    group_by_views: {
      [MAP_LAYERS.markers]: {
        siteId: null,
        latLng: null,
        index: 0
      }
    },
    priorityView: [{ layerName: MAP_LAYERS.markers, priority: 1 }],
    layerData: {}
  },
  [MAP_VIEWS.summaryDashboard]: {
    group_by_views: {
      [MAP_LAYERS.markers]: {
        siteId: null,
        latLng: null,
        index: 0
      },
      [MAP_LAYERS.markersCluster]: {
        segmentDesc: null,
        subState: null,
        latLng: null,
        index: 0
      },
      [MAP_LAYERS.vectorLayer]: {
        zoneId: null,
        latLng: null,
        vectorIndex: 0
      }
    },
    priorityView: [
      { layerName: MAP_LAYERS.markersCluster, priority: 1 },
      { layerName: MAP_LAYERS.vectorLayer, priority: 2 },
      { layerName: MAP_LAYERS.tileLayer, priority: 3 },
      { layerName: MAP_LAYERS.markers, priority: 4 }
    ],
    layerData: {}
  },
  [MAP_VIEWS.insights]: {
    group_by_views: {
      [MAP_LAYERS.markers]: {
        siteId: null,
        latLng: null,
        index: 0
      },
      [MAP_LAYERS.markersCluster]: {
        segmentDesc: null,
        subState: null,
        latLng: null,
        index: 0
      },
      [MAP_LAYERS.vectorLayer]: {
        zoneId: null,
        latLng: null,
        vectorIndex: 0
      }
    },
    priorityView: [
      { layerName: MAP_LAYERS.markersCluster, priority: 1 },
      { layerName: MAP_LAYERS.vectorLayer, priority: 2 },
      { layerName: MAP_LAYERS.tileLayer, priority: 3 },
      { layerName: MAP_LAYERS.markers, priority: 4 }
    ],
    layerData: {}
  },
  [MAP_VIEWS.playBack]: {
    group_by_views: {
      [MAP_LAYERS.markers]: {
        siteId: null,
        latLng: null,
        index: 0
      },
      [MAP_LAYERS.vectorLayer]: {
        zoneId: null,
        latLng: null,
        vectorIndex: 0
      }
    },
    priorityView: [
      { layerName: MAP_LAYERS.markers, priority: 1 },
      { layerName: MAP_LAYERS.vectorLayer, priority: 2 }
    ],
    layerData: {}
  }
};

// To hide streets on Map
export const HIDE_STREET_INFO = [
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  }
];

export const FUNCTION_TO_CALL = {
  INDEX_BASED_BOUNDS: 'getIndexBasedBounds',
  SITE_ZONE_ID_SUBSTATE_BOUNDS: 'getSiteZoneIdSegmentsSubStateBounds',
  LAT_LNG: 'getLatLng',
  VECTOR_INDEX_BASED_BOUNDS: 'getVectorIndexBasedBounds'
};

export const DEMO_MODE_LAT_LNG_FUNC = {
  index: FUNCTION_TO_CALL.INDEX_BASED_BOUNDS,
  siteId: FUNCTION_TO_CALL.SITE_ZONE_ID_SUBSTATE_BOUNDS,
  subState: FUNCTION_TO_CALL.SITE_ZONE_ID_SUBSTATE_BOUNDS,
  segmentDesc: FUNCTION_TO_CALL.SITE_ZONE_ID_SUBSTATE_BOUNDS,
  latLng: FUNCTION_TO_CALL.LAT_LNG,
  vectorIndex: FUNCTION_TO_CALL.VECTOR_INDEX_BASED_BOUNDS,
  zoneId: FUNCTION_TO_CALL.SITE_ZONE_ID_SUBSTATE_BOUNDS
};
