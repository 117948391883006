import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { TileLayer as LTileLayer, Layer, LeafletEvent } from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { TileLayerWithFilter } from '../../layers/tile-filter.layer';
import { MAP_LAYERS } from '../../map-demo.config';
import { TileLayerOptions } from '../../map.model';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-tile-layer',
  template: ''
})
export class TileLayer implements OnInit, OnDestroy {
  private readonly tileUrl$ = new BehaviorSubject<string>(undefined);
  private readonly tileOptions$ = new BehaviorSubject<TileLayerOptions>({});

  @Input()
  options: TileLayerOptions;

  @Input()
  set url(_url: string) {
    this.tileUrl$.next(_url);
  }

  @Output() tilesLoad = new EventEmitter<Layer>();

  private tileLayer: LTileLayer;

  constructor(private readonly mapService: MapService, private http: HttpClient) {}

  ngOnInit() {
    this.options.httpInstance = this.http;
    this.tileLayer = new TileLayerWithFilter('', this.options);
    this.attachEventListeners().then(() => {
      const map = this.mapService.getMap();
      this.tileLayer.addTo(map);
      this.subscribeUrlListener();
    });
  }

  ngOnDestroy() {
    this.tileUrl$.unsubscribe();
    this.tileOptions$.unsubscribe();
    this.detachEventListeners();
  }

  private subscribeUrlListener(): void {
    this.tileUrl$.subscribe((_url: string) => {
      if (_url !== undefined) {
        if (this.options) {
          for (const key of Object.keys(this.options)) {
            this.tileLayer.options[key] = this.options[key];
          }
        }
        if (isDemoModeON() && this.tileLayer?.options?.bounds?.length && !_url?.endsWith('.png')) {
          this.mapService.fitBoundsMap({ data: [this.tileLayer.options.bounds[0]], view: MAP_LAYERS.tileLayer });
        }
        this.tileLayer.setUrl(_url, false);
      }
    });
  }

  /**
   * Method used to attach vector listeners if obsevers are available
   */
  private attachEventListeners(): Promise<any> {
    if (this.tilesLoad.observers.length > 0) {
      this.tileLayer.on('load', this.loadListener, this);
    }
    return Promise.resolve();
  }

  private detachEventListeners(): void {
    if (this.tileLayer) {
      this.tileLayer.off('load', this.loadListener, this);
    }
  }

  private loadListener(event: LeafletEvent) {
    this.tilesLoad.emit(event.target);
  }

  public setUrl(url: string) {
    this.tileUrl$.next(url);
  }
}
