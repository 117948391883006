import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Popup as LPopup, LatLng, Point } from 'leaflet/dist/leaflet-src';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CustomPopup } from '../../layers/custom-popup.layer';

import { DEFAULT_POPUP_OPTIONS, DEFAULT_POPUP_TYPE } from '../../map.constants';
import { LatLngObject, PopupOptions, PopupType } from '../../map.model';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html'
})
export class Popup implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly options$ = new BehaviorSubject<PopupOptions>(DEFAULT_POPUP_OPTIONS);

  @Input()
  set options(_options: PopupOptions) {
    this.options$.next(_options);
  }

  @Input()
  templateId: TemplateRef<any>;

  @Input()
  type: PopupType = DEFAULT_POPUP_TYPE;

  private board: LPopup;

  get templateRef() {
    return this.type === PopupType.TEMPLATE ? this.templateId : null;
  }

  get popupTypes() {
    return PopupType;
  }

  get templateData(): any {
    return { $implicit: this.boardTemplateData };
  }

  private boardTemplateData: any;

  get boardInitialized(): boolean {
    return this.initialized;
  }

  get getboardTemplateData(): any {
    return this.boardTemplateData;
  }

  private initialized = false;

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly mapService: MapService,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    this.subscribeListeners();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Observer to create popup
   */

  private subscribeListeners(): void {
    this.options$
      .pipe(
        takeUntil(this.destroy$),
        map(_options => {
          const acOptions: any = {
            ...DEFAULT_POPUP_OPTIONS,
            ..._options
          };
          acOptions.offset = new Point(acOptions.offset.x, acOptions.offset.y);
          return acOptions;
        })
      )
      .subscribe((_options: PopupOptions) => {
        this.board = new CustomPopup(_options);
      });
  }

  /**
   * To show popup with popupdata recieved. Exposed to host to use
   * @param latLong of type LatLngObject
   * @param templateData of type Object optional
   */

  public show(latLong: LatLngObject, templateData?: any) {
    this.initialized = true;

    setTimeout(() => {
      if (templateData) {
        this.boardTemplateData = templateData;
      }

      this.ref.detectChanges();

      this.board.setLatLng(new LatLng(latLong.lat, latLong.lng)).setContent(this.elementRef.nativeElement).openOn(this.mapService.getMap());
    });
  }

  /**
   * To set default popup options. Exposed to host to use
   * @param _options of type PopupOptions
   */

  public setOptions(_options: PopupOptions) {
    this.options$.next(_options);
  }

  public close() {
    if (this.board) {
      this.mapService.getMap().removeLayer(this.board);
    }
  }

  public isOpen(): boolean {
    return this.board?.isOpen();
  }

  public setLatLng(latLong: LatLngObject): void {
    if (this.board) {
      this.board.setLatLng(new LatLng(latLong.lat, latLong.lng));
    }
  }
}
