<div class="map-details-type-container" [ngClass]="{ 'show-more-layers': showMoreLayers }">
  <div>
    <div class="common-display-align map-details-title-container">
      <strong class="layer-title">Map Details</strong>
      <img
        cc-tooltip="{{ 'app.close' | transloco }}"
        class="map-view-close-icon map-leaflet-close-icon map-leaflet-background-image"
        (click)="onViewLayerClose()"
        src="assets/transparent_image.png"
        alt="close-icon"
      />
    </div>
    <div class="map-detail-layers">
      <div
        class="map-tile-layer"
        cc-tooltip
        [template]="TrafficInfoTemplate"
        [appClass]="'-traffic-layer-tooltip'"
        [tooltipPosition]="'top-right'"
        [disabled]="enableTrafficLayer"
        [ngStyle]="{ opacity: enableTrafficLayer ? 1 : '0.4' }"
        (click)="enableTrafficLayer && showZoneDiscardPopup(false, mapViewTypes.TRAFFIC)"
      >
        <div class="map-tile-layer-icon" [ngClass]="{ 'layer-selected': isTrafficON && enableTrafficLayer }">
          <div class="layer-option traffic-custom-layer"></div>
          <div class="layer-label">Traffic</div>
        </div>
      </div>

      @if (options?.showSpeedDefaultLayer) {
      <div
        class="map-tile-layer"
        cc-tooltip
        [template]="SpeedDefaultInfoTemplate"
        [appClass]="'-speed-layer-tooltip'"
        [tooltipPosition]="'top-right'"
        [disabled]="enableSpeedDefaultLayer"
        [ngStyle]="{ opacity: enableSpeedDefaultLayer ? 1 : '0.4' }"
        (click)="enableSpeedDefaultLayer && onMapDetail(mapViewTypes.SPEED_DEFAULT)"
      >
        <div class="map-tile-layer-icon" [ngClass]="{ 'layer-selected': isSpeedDefaultON && enableSpeedDefaultLayer }">
          <div class="layer-option speed-custom-layer"></div>
          <div class="layer-label">Speed</div>
        </div>
      </div>
      } @if (options?.showDistanceDefaultLayer && !showMoreLayers) {
      <div class="layer-distance-border"></div>
      } @if (options?.showDistanceDefaultLayer) {
      <div class="map-tile-layer" (click)="handleMeasureDistanceToggle(mapViewTypes.MEASURE_DISTANCE)">
        <div
          class="common-alignment-center common-display-column map-tile-layer-icon"
          [ngClass]="{ 'layer-selected': isMeasureDistanceON }"
        >
          <div class="layer-option measure-distance-custom-layer"></div>
          <div class="layer-label">{{ options.measureDistanceTitle }}</div>
        </div>
      </div>
      }
      <!-- speed view tile layer end -->
      @for (tileLayer of options.mapLayers; track trackToolsBy($index)) { @if (!tileLayer?.isDisabled) {
      <div class="map-tile-layer" (click)="showZoneDiscardPopup(false, tileLayer.layerName)">
        <div class="map-tile-layer-icon" [ngClass]="{ 'layer-selected': selectedLayer[tileLayer.layerName] }">
          <div [ngClass]="['layer-option', tileLayer.class]"></div>
        </div>
        <div class="layer-label">{{ tileLayer.displayText }}</div>
      </div>
      } @else {
      <ng-container *ngTemplateOutlet="disabledLayer; context: { layer: tileLayer }"></ng-container>
      } }
    </div>
  </div>
  <hr />
  @if (showMoreLayers && options?.mapTools.length > 0) {
  <div class="map-tools">
    <strong class="layer-title">Map Tools</strong>
    <div class="map-detail-layers">
      @for (mapTool of options.mapTools; track trackToolsBy($index)) {
      <div
        class="map-tile-layer"
        cc-tooltip
        [template]="MapToolInfoTemplate"
        [appClass]="'-map-tool-tooltip'"
        [tooltipPosition]="'right'"
        [disabled]="!disableMapTool(mapTool.layerName)"
        [ngClass]="{ 'map-tile-layer-disabled': disableMapTool(mapTool.layerName) }"
        (click)="!disableMapTool(mapTool.layerName) && discardPopupForMapTool(false, mapTool.layerName)"
      >
        <div class="map-tile-layer-icon" [ngClass]="{ 'layer-selected': selectedTool[mapTool.layerName] }">
          <div [ngClass]="['layer-option', mapTool.class]"></div>
        </div>
        <div class="layer-label">{{ mapTool.displayText }}</div>
      </div>
      }
    </div>
  </div>
  <hr />
  }
  <div class="main-layers-wrapper">
    <strong class="layer-title">Map Type</strong>
    <div class="main-layers">
      <div class="map-tile-layer" (click)="onMapTypeChanged('Terrain')">
        <div [ngClass]="{ 'view-layer-selected': selectedView === mapViewTypes.SATELLITE }">
          <div class="layer-option satellite-custom-layer"></div>
        </div>
        <span>Satellite</span>
      </div>
      <div class="map-tile-layer" (click)="onMapTypeChanged('Satellite')">
        <div [ngClass]="{ 'view-layer-selected': selectedView === mapViewTypes.TERRAIN }">
          <div class="layer-option terrain-custom-layer"></div>
        </div>
        <span>Terrain</span>
      </div>
    </div>
  </div>
</div>

<ng-template #disabledLayer let-layer="layer">
  <div
    class="map-tile-layer disabled-layer"
    cc-tooltip
    [template]="disabledLayerTooltip"
    appClass="-disable-layer-tooltip"
    [tooltipPosition]="'top-right'"
  >
    <div [ngClass]="['layer-option', layer?.class]"></div>
    <div class="layer-label">{{ layer?.displayText }}</div>
  </div>

  <ng-template #disabledLayerTooltip>
    <ng-container *ngTemplateOutlet="mapInfoTooltipTemplate; context: { message: layer.tooltipText }"></ng-container>
  </ng-template>
</ng-template>

<ng-template #TrafficInfoTemplate>
  <span class="traffic-layer-tooltip-wrap" [ngClass]="{ 'more-width': options.moreTooltipWidth }">{{ options.tooltipText }}</span>
</ng-template>
<!-- Tootip Text for speed view -->
<ng-template #SpeedDefaultInfoTemplate>
  <span class="speed-layer-tooltip-wrap" [ngClass]="{ 'more-width': options.moreTooltipWidth }">{{ options.tooltipTextForSpeed }}</span>
</ng-template>
<ng-template #MapToolInfoTemplate>
  <ng-container *ngTemplateOutlet="mapInfoTooltipTemplate; context: { message: options.tooltipTextForZone }"></ng-container>
</ng-template>

<ng-template #mapInfoTooltipTemplate let-message="message">
  <span class="map-tool-tooltip-wrap">{{ message }}</span>
</ng-template>
