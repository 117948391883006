import { Position } from '@Terra/shared/widgets/config';
import { InjectionToken } from '@angular/core';
import {
  ArrowHeadsOptions,
  ClusterOptions,
  DecimalSeparatorType,
  DrawManagerToolbarOptions,
  FullScreenControlOptions,
  LocationSearchOptions,
  MapContentOptions,
  MapViewType,
  MarkerIcon,
  MarkerOptions,
  MarkerTrackOptions,
  MobilizationRouteColor,
  PolyArrowOptions,
  PolyLineOptions,
  PopupOptions,
  PopupPosition,
  PopupType,
  RecenterControlOptions,
  TrackOptions,
  VectorLayerOptions
} from './map.model';

export const MapConfigService = new InjectionToken<any>('MAP_CONFIG');

export const MAP_RENDER_PADDING = 100;

export const MAP_FIT_BOUNDS_PADDING = [50, 50];

export const MAP_INVALIDATE_SIZE_DELAY = 500;

export const DEFAULT_OPTIONS = {
  center: { lat: 13.0827, lng: 80.2707 },
  zoom: 6,
  minZoom: 0,
  maxZoom: 30,
  scrollWheelZoom: false,
  gestureHandling: true,
  wheelPxPerZoomLevel: 180,
  attributionControl: false,
  zoomControl: true,
  zoomControlPosition: Position.BOTTOMRIGHT,
  scaleControl: false,
  scaleControlPosition: Position.BOTTOMRIGHT,
  baseMap: 'Google',
  mapView: MapViewType.ROAD,
  preventCentreUpdateOnResize: false
};

export const DEFAULT_ICON_OPTIONS: MarkerIcon = {
  url: '/mapleafletassets/images/markerIcon.png',
  size: { x: 32, y: 32 },
  iconAnchor: { x: 16, y: 32 }
};

export const DEFAULT_MARKER_OPTIONS: MarkerOptions = {
  icon: DEFAULT_ICON_OPTIONS,
  highlightIcon: null,
  keyboard: true,
  title: '',
  alt: '',
  zIndexOffset: 0,
  opacity: 1,
  riseOnHover: false,
  riseOffset: 250,
  interactive: true,
  popup: true,
  rotationAngle: 0
};

const polyLineOptions: PolyLineOptions = {
  color: 'red',
  smoothFactor: 5
};

const arrowOptions: ArrowHeadsOptions = {
  frequency: 'allvertices',
  size: '5%',
  yawn: 60,
  proportionalToTotal: false,
  fill: false,
  color: 'black',
  fillColor: 'green'
};

export const DEFAULT_POLY_ARROW_OPTIONS: PolyArrowOptions = {
  polyLineOption: polyLineOptions,
  arrowHeadOption: arrowOptions
};

const periviousTrackOptions: TrackOptions = {
  line_color: 'green',
  line_smoothFactor: 5,
  arrow_size: '5%',
  arrow_frequency: 10,
  arrow_fill: false,
  arrow_color: 'green',
  arrow_fillColor: 'green'
};
const nextCurrentTrackOptions: TrackOptions = {
  line_color: 'blue',
  line_smoothFactor: 5,
  arrow_size: '5%',
  arrow_frequency: 5,
  arrow_fill: false,
  arrow_color: 'blue',
  arrow_fillColor: 'blue'
};

export const DEFAULT_MARKER_TRACK_OPTIONS: MarkerTrackOptions = {
  nextTrackOptions: nextCurrentTrackOptions,
  prevTrackOptions: periviousTrackOptions
};

export const DEFAULT_POPUP_OPTIONS: PopupOptions = {
  maxWidth: 300,
  minWidth: 50,
  maxHeight: 300,
  keepInView: false,
  closeButton: true,
  autoClose: true,
  closeOnEscapeKey: true,
  closeOnClick: true,
  className: '',
  position: PopupPosition.TOP,
  offset: { x: 0, y: 0 },
  autoPan: true,
  autoPanPadding: { x: 20, y: 20 }
};

export const DEFAULT_POPUP_TYPE = PopupType.CONTENT;

export const DEFAULT_FULLSCREEN_CONTROL_OPTIONS: FullScreenControlOptions = {
  position: Position.TOPRIGHT, // change the position of the button can be topleft, topright, bottomright or bottomleft, default TOPRIGHT
  title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
  titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
  content: null, // change the content of the button, can be HTML, default null
  forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
  forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
  fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container,
  fullscreenElementTargetClass: null, // Select the dom element using queryselector
};

export enum FULL_SCREEN_EVENTS {
  ENTER_FULL_SCREEN = 'enterFullscreen',
  EXIT_FULL_SCREEN = 'exitFullscreen'
}

export const DEFAULT_CLUSTER_OPTIONS: ClusterOptions = {
  showCoverageOnHover: false,
  zoomToBoundsOnClick: true,
  spiderfyOnMaxZoom: true,
  animate: true,
  maxClusterRadius: 80,
  clusterIconSize: { x: 32, y: 32 }
};

export const DEFAULT_LOCATION_SERVICE_OPTIONS: LocationSearchOptions = {
  position: Position.TOPLEFT,
  collapsed_mode: true,
  prepend: true,
  placeholder: 'Search CAT Places',
  autocomplete_options: {},
  autoPan: false
};

export const GAPI_CONFIG = {
  MAP_URL: 'https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=',
  LANG: '&language=',
  CALLBACK: '&callback=__onGoogleLoaded',
  MAP_SRC: 'https://maps.googleapis.com/maps/api/js?',
  REMOVE_MAP_SRC: 'https://maps.googleapis.com/maps',
  REGION: '&region='
};

export const DEFAULT_VECTOR_LAYER_OPTIONS: VectorLayerOptions = {
  stroke: true, //for borders of the polygons or circle.
  color: '#0074D9', // border colour
  weight: 3, //border width
  opacity: 0.8, //stroke opacity border
  fill: true, //fill the box with user given colours
  fillColor: '#0074D9',
  fillOpacity: 0.35, //fill opacity
  popup: true
};

export const DEFAULT_DM_TOOLBAR_OPTIONS: DrawManagerToolbarOptions = {
  position: Position.TOPRIGHT,
  draw: {
    polygon: { allowIntersection: false },
    polyline: true,
    rectangle: true,
    circle: true
  },
  edit: true,
  delete: true,
  allowIntersectionWhileEdit: false,
  editDrawError: { color: 'red' }
};

export const DEFAULT_SELECTED_PATH_OPTION: VectorLayerOptions = {
  dashArray: '10, 10',
  fill: true,
  fillOpacity: 0.3,
  maintainColor: true
};

export const DEFAULT_UNDO_ICON: MarkerIcon = {
  url: '/mapleafletassets/images/undo.png',
  size: { x: 32, y: 32 },
  iconAnchor: { x: 0, y: 32 }
};

export const DEFAULT_MAP_CONTENT_OPTIONS: MapContentOptions = {
  position: Position.BOTTOMLEFT,
  disableDblClickPropagation: false
};

export const MAP_VIEW_LABELS = {
  [MapViewType.ROAD]: 'Map',
  [MapViewType.SATELLITE]: 'Satellite',
  [MapViewType.HYBRID]: 'Hybrid',
  [MapViewType.TERRAIN]: 'Terrain',
  [MapViewType.TRAFFIC]: 'Traffic',
  [MapViewType.TRANSIT]: 'Transit'
};

export const ROUTE_COLOR_CONFIG = {
  [MobilizationRouteColor.BLUE]: '#1991EB',
  [MobilizationRouteColor.GREEN]: '#008A45',
  [MobilizationRouteColor.RED]: '#ED1C24',
  [MobilizationRouteColor.BLACK]: '#000000'
};

export const ROUTE_DASH_CONFIG = {
  weight: 4,
  dashArray: '10, 9',
  color: '#FFFFFF'
};

export const ROUTE_STROKE_WIDTH = 8;

export const DEFAULT_RECENTER_CONTROL_OPTIONS: RecenterControlOptions = {
  position: Position.BOTTOMRIGHT
};

export const ZOOM_PAN_ANIMATION_CONFIG = {
  animate: true,
  pan: {
    duration: 0.5
  }
};

export const MAP_FIT_BOUNDS_ANIMATION_CONFIG = {
  ...ZOOM_PAN_ANIMATION_CONFIG,
  padding: MAP_FIT_BOUNDS_PADDING
};
export enum TOGGLER_TOOLTIP {
  Terrain = 'Switch to Terrain View',
  Hybrid = 'Switch to Map View',
  Satellite = 'Switch to Satellite View',
  Traffic = 'Switch to Traffic View',
  Transit = 'Switch to Transit View',
  Map = 'Switch to Map View'
}

export const MEASURE_LAYER = 'PolyLineMeasure';
export const ELEVATION_PROFILE_LAYER = 'ElevationProfileLayer';

export const MAP_MEASURE_CONTROL_DEFAULT_CONFIG = {
  controlId: 'polyline-measure-control',
  unicodeClass: 'polyline-measure-unicode-icon',
  options: {
    position: 'topleft',
    unit: 'metres',
    decimalSeparator: DecimalSeparatorType.PERIOD,
    clearMeasurementsOnStop: true,
    tooltipTextFinish: 'Double click to finish line',
    tooltipTextDelete: 'Click to delete point',
    tooltipTextMove: 'Click and drag to move point',
    tooltipTextAdd: 'Press CTRL-key and click to add point',
    measureControlTitleOn: 'Turn on PolyLineMeasure',
    measureControlTitleOff: 'Turn off PolyLineMeasure',
    measureControlLabel: '&#8614;',
    eventFireControlName: 'PolyLineMeasure',
    measureControlClasses: [],
    distanceShowSameUnit: false,
    unitControlLabel: {
      metres: 'm',
      kilometres: 'km',
      feet: 'ft',
      landmiles: 'mi',
      nauticalmiles: 'nm'
    },
    tempLine: {
      color: '#00f',
      weight: 2
    },
    fixedLine: {
      color: '#006',
      weight: 2
    },
    startCircle: {
      color: '#000',
      weight: 1,
      fillColor: '#0f0',
      fillOpacity: 1,
      radius: 3
    },
    intermedCircle: {
      color: '#000',
      weight: 1,
      fillColor: '#ff0',
      fillOpacity: 1,
      radius: 3
    },
    currentCircle: {
      color: '#000',
      weight: 1,
      fillColor: '#f0f',
      fillOpacity: 1,
      radius: 30
    },
    endCircle: {
      color: '#000',
      weight: 1,
      fillColor: '#f00',
      fillOpacity: 1,
      radius: 3
    },
    arrowOptions: {
      color: '#FFCC11'
    },
    allowMultipleLine: false
  }
};

const MAP_MEASURE_THEME_COLOR = '#FFCC11';
const ELEVATION_PROFILE_THEME_COLOR = '#FFF';

export const MAP_MEASURE_DEFAULT_OPTIONS = {
  layer: MEASURE_LAYER,
  cardTitle: 'Measure Distance',
  cardSubTitle: ' Click on the map to add to your path',
  cardTotalDistanceText: 'Total Distance',
  cardAreaText: 'Total Area',
  eventFireControlName: 'PolyLineMeasure',
  tooltipOpacity: 0.7,
  tempLine: {
    // Styling settings for the temporary dashed line
    color: MAP_MEASURE_THEME_COLOR, // Dashed line color
    weight: 2 // Dashed line weight
  },
  fixedLine: {
    // Styling for the solid line
    color: MAP_MEASURE_THEME_COLOR, // Solid line color
    weight: 2 // Solid line weight
  },
  startCircle: {
    // Style settings for circle marker indicating the starting point of the polyline
    color: MAP_MEASURE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: MAP_MEASURE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  intermedCircle: {
    // Style settings for all circle markers between startCircle and endCircle
    color: MAP_MEASURE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: MAP_MEASURE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  currentCircle: {
    // Style settings for circle marker indicating the latest point of the polyline during drawing a line
    color: MAP_MEASURE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: MAP_MEASURE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  endCircle: {
    // Style settings for circle marker indicating the last point of the polyline
    color: MAP_MEASURE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: MAP_MEASURE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  arrowOptions: {
    color: MAP_MEASURE_THEME_COLOR
  },
  allowMultipleLine: false
};

export const MEASURE_ELEVATION_COMMON_CONFIG = {
  position: Position.TOPRIGHT,
  showBearings: false,
  showClearControl: false,
  showUnitControl: false,
  measureControlLabel: '',
  measureControlTitleOn: 'Turn on Measure',
  measureControlTitleOff: 'Turn off Measure',
  measureControlClasses: ['map-ctrl-measure']
};

export const ELEVATION_PROFILE_DEFAULT_OPTIONS = {
  layer: ELEVATION_PROFILE_LAYER,
  cardTitle: 'insights.elevationProfile.title',
  cardInfo: ['insights.elevationProfile.infoText2', 'insights.elevationProfile.infoText1', 'insights.elevationProfile.infoText3'],
  infoTooltip: 'insights.elevationProfile.infoTooltip',
  hideDistance: true,
  eventFireControlName: 'elevaionProfileMeasure',
  infoIcon: '/mapleafletassets/images/info-icon.svg',
  closeIcon: '/mapleafletassets/images/measure_close.svg',
  errorThemeColor: '#940000',
  arrowErrorClass: 'elevprofile-error-arrow',
  tooltipTextFinish: 'Double click to end path',
  tooltipTextDelete: 'Click to delete point',
  tooltipOpacity: 1,
  tempLine: {
    // Styling settings for the temporary dashed line
    color: ELEVATION_PROFILE_THEME_COLOR, // Dashed line color
    weight: 2 // Dashed line weight
  },
  errorLine: {
    // Styling settings for the temporary dashed line
    color: '#940000', // Dashed line color
    weight: 2 // Dashed line weight
  },
  fixedLine: {
    // Styling for the solid line
    color: ELEVATION_PROFILE_THEME_COLOR, // Solid line color
    weight: 2 // Solid line weight
  },
  startCircle: {
    // Style settings for circle marker indicating the starting point of the polyline
    color: ELEVATION_PROFILE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: ELEVATION_PROFILE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  intermedCircle: {
    // Style settings for all circle markers between startCircle and endCircle
    color: ELEVATION_PROFILE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: ELEVATION_PROFILE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  currentCircle: {
    // Style settings for circle marker indicating the latest point of the polyline during drawing a line
    color: ELEVATION_PROFILE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: ELEVATION_PROFILE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  endCircle: {
    // Style settings for circle marker indicating the last point of the polyline
    color: ELEVATION_PROFILE_THEME_COLOR, // Color of the border of the circle
    weight: 1, // Weight of the circle
    fillColor: ELEVATION_PROFILE_THEME_COLOR, // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 5 // Radius of the circle
  },
  arrowOptions: {
    color: ELEVATION_PROFILE_THEME_COLOR
  },
  allowMultipleLine: false
};

export const MEASURE_CONTROL_OPTIONS = {
  [MEASURE_LAYER]: { ...MEASURE_ELEVATION_COMMON_CONFIG, ...MAP_MEASURE_DEFAULT_OPTIONS },
  [ELEVATION_PROFILE_LAYER]: { ...MEASURE_ELEVATION_COMMON_CONFIG, ...ELEVATION_PROFILE_DEFAULT_OPTIONS }
};

export const DEFAULT_VIEW_LAYER_TOGGLE_OPTIONS = {
  showTrafficLayer: false,
  mapViews: [MapViewType.TERRAIN, MapViewType.SATELLITE],
  defaultMapView: MapViewType.SATELLITE,
  position: Position.BOTTOMLEFT
};

export const HEAT_MAP_DEFAULT_OPTIONS = {
  minOpacity: 1,
  max: 1.0,
  radius: 10,
  blur: 15,
  gradient: {
    0.1: '#9FFB67',
    0.2: '#63C825',
    0.3: '#55B21C',
    0.5: '#51ADF1',
    0.6: '#9AD4FF',
    0.7: '#1991EB',
    1: '#ea290e'
  }
};

export const SELECTED_LAYER = {
  [MapViewType.CUTFILL]: false,
  [MapViewType.ELEVATION]: false,
  [MapViewType.MORE]: false,
  [MapViewType.CMV]: false,
  [MapViewType.MDP]: false,
  [MapViewType.PASS_COUNT]: false,
  [MapViewType.SPEED]: false,
  [MapViewType.TEMP]: false,
  [MapViewType.LCA]: false
};

export const DEFAULT_SELECTED_TOOL = {
  [MapViewType.ZONE]: false,
  [MapViewType.ELEVATION_PROFILE]: false,
  [MapViewType.MEASURE_DISTANCE]: false
};

export const RESET_SELECTED_TOOL = {
  [MapViewType.ZONE]: false,
  [MapViewType.ELEVATION_PROFILE]: false
};

export const MORE_MAP_LAYER = { layerName: 'MoreLayer', class: 'more-custom-layer', displayText: 'More' };

export const GEOFENCE_SUPPORT_LAYERS = [
  MapViewType.CMV,
  MapViewType.MDP,
  MapViewType.PASS_COUNT,
  MapViewType.SPEED,
  MapViewType.TEMP,
  MapViewType.LCA,
  MapViewType.CUTFILL
];
export const GEOFENCE_UNSUPPORT_LAYERS = [MapViewType.TRAFFIC, MapViewType.ELEVATION];

export const MEASURE_DISTANCE_INDEX = 2;

export const BASIC_LAYERS_LENGTH = 3;

export const MAP_CTRL_MAXIMIZE_FULLSCREEN = 'map-ctrl-maximize-fullscreen';
export const POLYLINE_MEASURE_POPUP_TOOLTIP = 'polyline-measure-popupTooltip';
export const POLYLINE_MEASURE_TOTAL_DISTANCE = 'polylinemeasure:totalDistance';
