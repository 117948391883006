import { Component, Input, OnInit } from '@angular/core';

import * as L from 'leaflet/dist/leaflet';

import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-measure-path/leaflet-measure-path';
import '../../libs/draw-manager-measure-extn';
import '../../libs/draw-manager-patch';
import { DrawManagerOptions, ShowMeasurementOptions, VectorLayerType, typeDraw } from '../../map.model';
import { MapService } from '../../map.service';
import { VectorLayersToolbar } from '../vector-layers-toolbar/vector-layers-toolbar.component';

declare var L: any;

L.Draw.Feature.prototype._cancelDrawing = event => {
  if (event.keyCode === 27) {
    return;
  }
};

export enum MANAGER_MODE {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  CLEAR = 'clear'
}

export type ManagerMode = 'create' | 'edit' | 'delete' | 'clear';
@Component({
  selector: 'app-vector-layers-with-draw-manager',
  template: ''
})
export class VectorLayersDrawManager extends VectorLayersToolbar implements OnInit {
  @Input()
  drawManagerOptions: DrawManagerOptions;

  @Input()
  measurementOptions: ShowMeasurementOptions = {
    showMeasurements: false
  };

  private editToolbar: L.EditToolbar.Edit;
  private deleteToolbar: L.EditToolbar.Delete;
  private singleEditVectors: {
    [id: number]: {
      points?: L.LatLng[];
      center?: L.LatLng;
      radius?: number;
    };
  } = {};

  private managerMode: MANAGER_MODE = MANAGER_MODE.CLEAR;
  private createHandler: typeDraw;

  constructor(private readonly mapServiceRefChld: MapService) {
    super(mapServiceRefChld);
  }

  ngOnInit() {
    this.initializeIfDataNotExists();

    this.attachDrawListeners();

    this.groupInitiate$.subscribe(() => {
      this.prepareToolbarText();
      this.editToolbar = new L.EditToolbar.Edit(this.mapService.getMap(), {
        featureGroup: this.vectorsGroup,
        poly: {
          allowIntersection: this.toolbarOptions.allowIntersectionWhileEdit,
          drawError: this.toolbarOptions.editDrawError
        },
        edit: {
          selectedPathOptions: this.selectedPathOptions
        }
      });
      this.deleteToolbar = new L.EditToolbar.Delete(this.mapService.getMap(), { featureGroup: this.vectorsGroup });
    });
  }

  /**
   * Method used to cancel the previous mode operation during mode change
   */
  private handleModeChange(): void {
    switch (this.managerMode) {
      case MANAGER_MODE.CREATE:
        this.cancelDraw();
        break;

      case MANAGER_MODE.EDIT:
        this.cancelEdit();
        break;

      case MANAGER_MODE.DELETE:
        this.cancelDelete();
        break;

      default:
        break;
    }
  }

  /**
   * Exposed to host
   * Method used to draw vector
   * @param type
   * @param options
   */
  public drawVector(type: VectorLayerType, options?: DrawManagerOptions): void {
    this.handleModeChange();

    const vectorOptions: DrawManagerOptions = {
      ...this.drawManagerOptions,
      ...options
    };

    switch (type) {
      case VectorLayerType.POLYGON:
        this.createHandler = new L.Draw.Polygon(this.mapService.getMap(), vectorOptions);
        break;

      case VectorLayerType.CIRCLE:
        this.createHandler = new L.Draw.Circle(this.mapService.getMap(), vectorOptions);
        break;

      case VectorLayerType.RECTANGLE:
        vectorOptions.showArea = false;
        this.createHandler = new L.Draw.Rectangle(this.mapService.getMap(), vectorOptions);
        break;

      case VectorLayerType.POLYLINE:
        this.createHandler = new L.Draw.Polyline(this.mapService.getMap(), vectorOptions);
        break;
      default:
        this.createHandler = null;
        break;
    }

    if (this.createHandler) {
      this.createHandler.enable();
      this.managerMode = MANAGER_MODE.CREATE;
    }
  }

  /**
   * Exposed to host
   * Method used to cancel draw
   */
  public cancelDraw(): void {
    if (this.createHandler && this.managerMode === MANAGER_MODE.CREATE) {
      this.createHandler.disable();
      this.managerMode = MANAGER_MODE.CLEAR;
    }
  }

  /**
   * Exposed to host
   * Method used edit all available vectors
   */
  public editAllVectors(): void {
    if (!this.editToolbar) {
      return;
    }

    this.handleModeChange();
    this.editToolbar.enable();
    this.managerMode = MANAGER_MODE.EDIT;
  }

  /**
   * Exposed to host
   * Method used to cancel edit and remove undo action if any
   */
  public cancelEdit(): void {
    if (!this.editToolbar || this.managerMode !== MANAGER_MODE.EDIT) {
      return;
    }

    this.removeUndoMarker();

    for (const key of Object.keys(this.singleEditVectors)) {
      const layer = this.vectorsGroup.getLayer(key);
      if (layer) {
        layer.edited = false;
        if (layer instanceof L.Polyline || layer instanceof L.Polygon || layer instanceof L.Rectangle) {
          layer.setLatLngs(this.singleEditVectors[key].points);
        } else if (layer instanceof L.Circle) {
          layer.setLatLng(this.singleEditVectors[key].center);
          layer.setRadius(this.singleEditVectors[key].radius);
        }
        layer.fire('revert-edited', { layer });
        layer.editing.disable();
      }
    }
    this.singleEditVectors = {};

    this.editToolbar.revertLayers();
    this.editToolbar.disable();
  }

  /**
   * Exposed to host
   * Method used to edit a vector
   * @param vector
   */
  public editVector(vector: L.Layer): void {
    if (!(vector instanceof L.Polyline || vector instanceof L.Polygon || vector instanceof L.Rectangle || vector instanceof L.Circle)) {
      return;
    }

    this.handleModeChange();

    const refObj =
      vector instanceof L.Circle
        ? {
            center: L.LatLngUtil.cloneLatLng(vector.getLatLng()),
            radius: vector.getRadius()
          }
        : { points: L.LatLngUtil.cloneLatLngs(vector.getLatLngs()) };

    this.singleEditVectors[L.Util.stamp(vector)] = refObj;
    vector.editing.enable();

    // enable measure
    if (this.measurementOptions.showMeasurements) {
      vector.showMeasurements({ imperial: !this.measurementOptions.imperial, showArea: false });
    }
    this.managerMode = MANAGER_MODE.EDIT;

    this.mapService.getMap().fire(L.Draw.Event.EDITSTART);
  }

  /**
   * Exposed to host
   * Method used start delete mode
   */
  public initiateDelete(): void {
    if (!this.deleteToolbar) {
      return;
    }
    this.handleModeChange();
    this.deleteToolbar.enable();
    this.managerMode = MANAGER_MODE.DELETE;
  }

  /**
   * Exposed to host
   * Method used save feature group after deleting is done
   */
  public completeDelete(): void {
    if (!this.deleteToolbar || this.managerMode !== MANAGER_MODE.DELETE) {
      return;
    }
    this.deleteToolbar.save();
    this.deleteToolbar.disable();
    this.managerMode = MANAGER_MODE.CLEAR;
  }

  /**
   * Exposed to host
   * Method used cancel delete operation and revert
   */
  public cancelDelete(): void {
    if (!this.deleteToolbar || this.managerMode !== MANAGER_MODE.DELETE) {
      return;
    }
    this.deleteToolbar.revertLayers();
    this.deleteToolbar.disable();
    this.managerMode = MANAGER_MODE.CLEAR;
  }

  /**
   * Exposed to host
   * Method used delete all vectors
   */
  public deleteAllVectors(): void {
    if (!this.deleteToolbar) {
      return;
    }
    this.handleModeChange();

    this.deleteToolbar.enable();
    this.deleteToolbar.removeAllLayers();
    this.deleteToolbar.disable();

    this.managerMode = MANAGER_MODE.CLEAR;
  }

  /**
   * Exposed to host
   * Method used to save all edited vectors
   */
  public saveAllVectors(): void {
    if (!this.editToolbar || this.managerMode !== MANAGER_MODE.EDIT) {
      return;
    }
    this.editToolbar.save();
    this.editToolbar.disable();

    for (const key of Object.keys(this.singleEditVectors)) {
      const layer = this.vectorsGroup.getLayer(key);
      if (layer) {
        layer.edited = false;
        layer.editing.disable();
        layer.hideMeasurements();
      }
    }
    this.singleEditVectors = {};
    this.managerMode = MANAGER_MODE.CLEAR;
  }

  public getManagerMode(): ManagerMode {
    return this.managerMode;
  }

  /**
   * Exposed to host
   * method used to save one single vector
   * @param vector
   */
  public saveVector(vector: L.Layer): void {
    if (!this.editToolbar || !this.vectorsGroup.hasLayer(vector) || this.managerMode !== MANAGER_MODE.EDIT) {
      return;
    }
    const layerId: number = L.Util.stamp(vector);
    const layer = this.vectorsGroup.getLayer(layerId);
    const editedLayers = new L.LayerGroup();
    if (layer.edited) {
      editedLayers.addLayer(layer);
      layer.edited = false;
      this.mapService.getMap().fire(L.Draw.Event.EDITED, { layers: editedLayers });
    }
    layer.editing.disable();
    layer.hideMeasurements();
    if (this.editToolbar._uneditedLayerProps && this.editToolbar._uneditedLayerProps[layerId]) {
      delete this.editToolbar._uneditedLayerProps[layerId];
    }

    if (this.singleEditVectors[layerId]) {
      delete this.singleEditVectors[layerId];
    }
    this.managerMode = MANAGER_MODE.CLEAR;
  }

  public hideVectorMeasurements(vector: L.Layer): void {
    vector.hideMeasurements();
  }

  public removeUndoIcon(): void {
    this.removeUndoMarker();
  }
}
