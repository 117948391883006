import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { GAPI_CONFIG } from '../map.constants';

// @dynamic
export class GApiLoaderService {
  public static gApiLoadNotifier$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private static languageLoaded: string;
  private static apiKey: string;
  public static init(apiKey: string, lang: string): void {
    GApiLoaderService.apiKey = apiKey;
    this.load(lang);
  }
  public static load(lang: string): void {
    if (lang === this.languageLoaded) {
      return;
    }
    this.languageLoaded = lang;
    const isGApiAvailable = this.isGApiAvailable(lang);
    if (!isGApiAvailable) {
      this.removeExistingGApi();
      this.loadGApi(lang).then(() => {
        this.gApiLoadNotifier$.next(true);
      });
    } else {
      this.gApiLoadNotifier$.pipe(take(1)).subscribe(loaded => {
        if (!loaded) {
          this.gApiLoadNotifier$.next(true);
        }
      });
    }
  }

  static isGApiAvailable(lang: string): boolean {
    const scriptElem: any = document.getElementsByTagName('script');
    const value: any = Object.keys(scriptElem).map(function (e) {
      return scriptElem[e];
    });
    return value.some(item => item.src && item.src.indexOf(GAPI_CONFIG.MAP_SRC) > -1 && item.src.indexOf('language=' + lang) > -1);
  }

  static removeExistingGApi(): void {
    const scriptElements: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script');
    for (let i = 0; i < scriptElements.length - 1; i++) {
      if (scriptElements[i].src.indexOf(GAPI_CONFIG.REMOVE_MAP_SRC) > -1) {
        scriptElements[i].parentNode.removeChild(scriptElements[i]);
      }
    }
    window['google'] = null;
  }

  static loadGApi(lang: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let ready;
      ready = false;
      const scriptElement: any = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = GAPI_CONFIG.MAP_URL + GApiLoaderService.apiKey + GAPI_CONFIG.LANG + lang + GAPI_CONFIG.CALLBACK;
      scriptElement.onload = scriptElement.onreadystatechange = function () {
        if (!ready && (!this.readyState || this.readyState === 'complete')) {
          ready = true;
        }
      };
      scriptElement.addEventListener('error', function () {
        reject();
      });
      window['__onGoogleLoaded'] = $event => {
        resolve(true);
      };
      const mapScriptElement = document.getElementsByTagName('script')[0];
      mapScriptElement.parentNode.insertBefore(scriptElement, mapScriptElement);
    });
  }
}
