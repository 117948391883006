import { HttpErrorResponse } from '@angular/common/http';
import { DomEvent, TileLayer, Util } from 'leaflet';
import { take } from 'rxjs/operators';

export const TileLayerWithFilter = TileLayer.extend({
  createTile(coords, done) {
    const tile = document.createElement('img');

    DomEvent.on(tile, 'load', Util.bind(this._tileOnLoad, this, done, tile));
    DomEvent.on(tile, 'error', Util.bind(this._tileOnError, this, done, tile));

    if (this.options.crossOrigin || this.options.crossOrigin === '') {
      tile.crossOrigin = this.options.crossOrigin === true ? '' : this.options.crossOrigin;
    }

    tile.alt = '';

    tile.setAttribute('role', 'presentation');

    /**
     * Overridden createTile method of TileLayer to include filter
     */
    if (this.options.filter && !this.options.filter(coords)) {
      tile.src = '';
    } else {
      let tileUrl = this.getTileUrl(coords);
      if (tileUrl.includes('isRandomIntAdded')) {
        tileUrl += '&randomInt=' + this.generateRandInt();
      }
      if (tileUrl) {
        /**  we need to make an api call from here(since request should go through our interceptor module to add the auth token in request header),
        if url includes  window location host name or it is starting with **/
        if (tileUrl.includes(window.location.hostname) || tileUrl.startsWith('/')) {
          this.options.httpInstance
            .get(tileUrl, { responseType: 'blob' })
            .pipe(take(1))
            .subscribe(
              (blob: Blob) => {
                tile.src = URL.createObjectURL(blob);
              },
              (error: HttpErrorResponse) => {
                if (error) {
                  tile.src = '';
                }
              }
            );
        } else {
          /** we can directly set the tile url src for url's having host name other than window location host name,
           No need to add auth header for other api's**/
          tile.src = tileUrl;
        }
      } else {
        tile.src = '';
      }
    }
    return tile;
  },
  generateRandInt() {
    return Math.floor(Math.random() * 200000) + 1;
  }
});
