import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaximizeControl } from '../../controls/maximize-control';
import { DEFAULT_FULLSCREEN_CONTROL_OPTIONS, FULL_SCREEN_EVENTS } from '../../map.constants';
import { FullScreenControlOptions } from '../../map.model';
import { MapService } from '../../map.service';
@Directive({
  selector: '[appFullScreenControl]',
  standalone: true
})
export class FullScreenControl implements OnInit, OnDestroy {
  @Input()
  set appFullScreenControl(_maximize: boolean) {
    this.maximize$.next(_maximize);
  }

  @Input() fullScreenControlOptions: FullScreenControlOptions = DEFAULT_FULLSCREEN_CONTROL_OPTIONS;

  @Output() mapMaximize = new EventEmitter<boolean>();

  private control: any;

  private readonly destroy$ = new Subject<void>();
  private readonly maximize$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly mapService: MapService) {}

  ngOnInit(): void {
    this.createControl();
    this.watchControlEvents();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createControl(): void {
    // create a fullscreen button and add it to the map
    this.control = new MaximizeControl(false, this.fullScreenControlOptions);

    this.control.addTo(this.mapService.getMap());

    this.control.on(FULL_SCREEN_EVENTS.ENTER_FULL_SCREEN, () => {
      this.mapMaximize.emit(true);
    });
    this.control.on(FULL_SCREEN_EVENTS.EXIT_FULL_SCREEN, () => {
      this.mapMaximize.emit(false);
    });
  }

  private watchControlEvents(): void {
    this.maximize$.pipe(takeUntil(this.destroy$)).subscribe((_maximize: boolean) => {
      this.control.maximize(_maximize);
    });
  }

  public maximize(): void {
    this.maximize$.next(true);
  }

  public minimize(): void {
    this.maximize$.next(false);
  }
}
