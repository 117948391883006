/**
 * Method to find whether two given points are equal
 * @param a
 * @param b
 * @returns
 */
const pointsEqual = (a, b) => a.x === b.x && a.y === b.y;

/**
 * Method to calculate the direction of point
 * @param a
 * @param b
 * @returns
 */
const computeSegmentHeading = (a, b) => ((Math.atan2(b.y - a.y, b.x - a.x) * 180) / Math.PI + 90 + 360) % 360;

/**
 * Method to calculate between two coordinates in pixel
 * @param ptA
 * @param ptB
 * @returns
 */
const pointDistance = (ptA, ptB) => {
  const x = ptB.x - ptA.x;
  const y = ptB.y - ptA.y;
  return Math.sqrt(x * x + y * y);
};

/**
 * Method to find out a point x,y between two points on a given ratio
 * @param ptA
 * @param ptB
 * @param ratio
 * @returns
 */
const interpolateBetweenPoints = (ptA, ptB, ratio) => {
  if (ptB.x !== ptA.x) {
    return {
      x: ptA.x + ratio * (ptB.x - ptA.x),
      y: ptA.y + ratio * (ptB.y - ptA.y)
    };
  }
  // special case where points lie on the same vertical axis
  return {
    x: ptA.x,
    y: ptA.y + (ptB.y - ptA.y) * ratio
  };
};

/**
 * Method identify distance and heading angle for the list of passed x,y points
 * @param pts
 * @returns
 */
export const pointsToSegments = pts =>
  pts.reduce((segments, b, idx, points) => {
    // this test skips same adjacent points
    if (idx > 0 && !pointsEqual(b, points[idx - 1])) {
      const a = points[idx - 1];
      const distA = segments.length > 0 ? segments[segments.length - 1].distB : 0;
      const distAB = pointDistance(a, b);
      segments.push({
        a,
        b,
        distA,
        distB: distA + distAB,
        heading: computeSegmentHeading(a, b)
      });
    }
    return segments;
  }, []);

/**
 * Method used to find segment center x,y and heading direction angle
 * @param segments
 * @returns
 */
export const getSegmentCenter = segments => {
  const totalPathLength = segments[segments.length - 1].distB - segments[0].distA;
  const offset = 0.5;
  const startOffsetPixels = totalPathLength * offset;
  const positionOffsets = [startOffsetPixels];
  let segmentIndex = 0;
  let segment = segments[0];

  return positionOffsets.map(positionOffset => {
    // find the segment matching the offset,
    // starting from the previous one as offsets are ordered
    while (positionOffset > segment.distB && segmentIndex < segments.length - 1) {
      segmentIndex++;
      segment = segments[segmentIndex];
    }

    const segmentRatio = (positionOffset - segment.distA) / (segment.distB - segment.distA);
    return {
      pt: interpolateBetweenPoints(segment.a, segment.b, segmentRatio),
      heading: segment.heading
    };
  });
};
