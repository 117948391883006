import { Control, DomEvent, Util } from 'leaflet';

/**
 * Leaflet Content control.
 *
 */
export const ContentControl = Control.extend({
  onAdd() {
    if (this.options.disableDblClickPropagation) {
      DomEvent.on(this._content, 'dblclick', this._dbClick, this);
    }
    return this._content;
  },
  initialize(content, options) {
    this._content = content;
    Util.setOptions(this, options);
  },
  _dbClick(event) {
    event.stopPropagation();
  },
  onRemove() {
    DomEvent.off(this._content, 'dblclick', this._dbClick, this);
  }
});
