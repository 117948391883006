import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { Control } from 'leaflet';

import { Position } from '@Terra/shared/widgets/config';
import { Subscription } from 'rxjs';
import { OverlayDropdownControl } from '../../controls/overlay-dropdown';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-overlay-dropdown-control',
  template: ` <ng-content></ng-content> `
})
export class OverlayDropdown implements OnInit, OnDestroy {
  private control: Control;
  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly mapService: MapService, private readonly elementRef: ElementRef) {}
  ngOnInit() {
    this.addContent();
  }
  private addContent() {
    this.control = new OverlayDropdownControl(this.elementRef.nativeElement, { position: Position.TOPRIGHT });
    this.control.addTo(this.mapService.getMap()).on('overlayDropdownClick', () => {
      this.mapService.overlayClickSubject.next();
    });

    this.control.addTo(this.mapService.getMap()).on('overlayDropdownExpand', () => {
      this.mapService.overlayExpandSubject.next('overlayDropdownExpand');
    });

    if (this.mapService.overlayExpandSubject) {
      this.subscription.add(
        this.mapService.overlayExpandSubject.subscribe(data => {
          if (data === 'threeTilesExpand') {
            this.control.collapse();
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
