import { Control, DomEvent, Map } from 'leaflet';

/**
 * Leaflet SidePanelControl control.
 *
 */
export const SidePanelControl = Control.extend({
  addTo(map: Map) {
    DomEvent.disableScrollPropagation(this._container);
    DomEvent.disableClickPropagation(this._container);
    DomEvent.on(this._container, 'contextmenu', DomEvent.stopPropagation);
    map._container.insertBefore(this._container, map._container.firstChild);
    return this;
  },
  initialize(content: HTMLElement) {
    this._container = content;
  },
  onRemove() {
    DomEvent.on(this._container, 'contextmenu', DomEvent.stopPropagation);
  }
});
