import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as L from 'leaflet/dist/leaflet';
// leaflet markerCluster import
import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import { Subscription } from 'rxjs';
import { MAP_LAYERS } from '../../map-demo.config';
import { DEFAULT_CLUSTER_OPTIONS } from '../../map.constants';
import { ClusterOptions } from '../../map.model';
import { MapService } from '../../map.service';
import { Markers } from '../markers/markers.component';

declare var L: any;

@Component({
  selector: 'app-markers-with-cluster',
  template: ''
})
export class ClusterMarkers extends Markers implements OnInit, OnChanges, OnDestroy {
  @Input()
  clusterOptions: ClusterOptions;

  @Input() clusterView = true;

  @Output() clusterClick = new EventEmitter<L.LeafletEvent>();
  @Output() clusterMouseout = new EventEmitter<L.LeafletEvent>();
  @Output() clusterMouseover = new EventEmitter<L.LeafletEvent>();

  private activeMarkerRef: any;
  private readonly markerClusterSubscription: Subscription = new Subscription();

  constructor(private readonly mapServiceRef: MapService) {
    super(mapServiceRef);
  }

  ngOnInit() {
    this.markerClick.subscribe(marker => {
      this.activeMarkerRef = marker;
    });

    this.mapService.getMap().on('click', this.mapClickListener, this);
    this.mapService.getMap().on('zoomstart', this.closeMarkerInfo, this);

    if (this.mapService.overlayClickSubject) {
      this.markerClusterSubscription.add(
        this.mapService.overlayClickSubject.subscribe(() => {
          this.removeHighlight();
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const _data = JSON.parse(JSON.stringify(this.data));
    if ('data' in changes) {
      isDemoModeON() && this.mapService.fitBoundsMap({ data: _data, view: MAP_LAYERS.markersCluster });
      this.addMarkersLayer(_data, this.defaultOptions);
    }

    if (changes.clusterView && !changes.clusterView.firstChange) {
      this.addMarkersLayer(_data, this.defaultOptions);
    }
  }

  closeMarkerInfo(): void {
    if (this.markerPopup && this.markerPopup.isOpen()) {
      this.markerPopup.close();
    }
    this.removeHighlight();
  }

  ngOnDestroy() {
    /**
     * Detaching all listeners
     */
    this.detachMarkersEventListeners();
    this.markerClusterSubscription.unsubscribe();
    this.mapService.getMap().off('zoomstart', this.closeMarkerInfo, this);
    if (this.markersGroup) {
      this.markersGroup.off('clusterclick', this.clusterClickListener, this);
      this.markersGroup.off('clustermouseout', this.clusterMouseoutListener, this);
      this.markersGroup.off('clustermouseover', this.clusterMouseoverListener, this);
      this.markersGroup.off('animationend', this.animationEndListener, this);
    }
  }

  private attachClusterEventListeners(): Promise<any> {
    if (this.clusterClick.observers.length > 0) {
      this.markersGroup.on('clusterclick', this.clusterClickListener, this);
    }

    if (this.clusterMouseout.observers.length > 0) {
      this.markersGroup.on('clustermouseout', this.clusterMouseoutListener, this);
    }

    if (this.clusterMouseover.observers.length > 0) {
      this.markersGroup.on('clustermouseover', this.clusterMouseoverListener, this);
    }

    this.markersGroup.on('animationend', this.animationEndListener, this);

    return Promise.resolve();
  }

  private clusterMouseoutListener(event: L.LeafletEvent): void {
    this.clusterMouseout.emit(event);
  }

  private clusterMouseoverListener(event: L.LeafletEvent): void {
    this.clusterMouseover.emit(event);
  }

  private clusterClickListener(event: L.LeafletEvent): void {
    this.clusterClick.emit(event);
  }

  private animationEndListener(event: L.LeafletEvent): void {
    if (this.activeMarkerRef) {
      this.trackMarker(this.activeMarkerRef);
    }
  }
  /**
   * Create cluster for markers with default or specific options
   *
   */

  protected createFeatureGroup(markers): void {
    const clusterOptions: any = {
      ...DEFAULT_CLUSTER_OPTIONS,
      ...this.clusterOptions
    };

    if (!this.clusterView) {
      super.createFeatureGroup(markers);
      return;
    }
    this.markersGroup = L.markerClusterGroup(clusterOptions);

    this.markersGroup.addLayers(markers);

    this.attachMarkerEventListeners().then(() => {
      this.attachClusterEventListeners().then(() => {
        this.mapService.getMap().addLayer(this.markersGroup);
      });
    });
  }

  protected getMarkerLatLng(marker: any): any {
    return this.markersGroup.getVisibleParent(marker) ? this.markersGroup.getVisibleParent(marker).getLatLng() : marker.getLatLng();
  }
}
