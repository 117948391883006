import { HttpClient } from '@angular/common/http';
import { Position } from '@Terra/shared/widgets/config';
import { Draw, LatLngBounds, Layer } from 'leaflet';
import { DivIcon } from '../..';
import { FULL_SCREEN_EVENTS } from './map.constants';

//All enums goes here

export enum PopupPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left'
}

export enum MapViewType {
  ROAD = 'roadmap',
  SATELLITE = 'satellite',
  TERRAIN = 'terrain',
  HYBRID = 'hybrid',
  TRAFFIC = 'TrafficLayer',
  SPEED_DEFAULT = 'SpeedDefaultLayer',
  TRANSIT = 'TransitLayer',
  CUTFILL = 'CutFillLayer',
  ELEVATION = 'ElevationLayer',
  CMV = 'CMVLayer',
  MDP = 'MDPLayer',
  PASS_COUNT = 'PassCountLayer',
  SPEED = 'SpeedLayer',
  TEMP = 'TemperatureLayer',
  MORE = 'MoreLayer',
  LCA = 'LCALayer',
  ELEVATION_PROFILE = 'ElevationProfileLayer',
  MEASURE_DISTANCE = 'PolyLineMeasure',
  ZONE = 'ZoneLayer'
}

export enum MarkerTrackPosition {
  PREVIOUS = 'previous',
  CURRENT = 'current',
  NEXT = 'next'
}

export enum PopupType {
  TEMPLATE = 'template',
  CONTENT = 'content'
}

export enum VectorLayerType {
  POLYLINE = 'polyline',
  POLYGON = 'polygon',
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle'
}

export enum DrawManagerActionStatus {
  CREATED = 'created',
  EDITED = 'edited',
  DELETED = 'deleted'
}

export enum VectorDataType {
  DEFAULT = 'default',
  GEOJSON = 'geojson'
}

export enum MapViewSwitchControlType {
  HORIZONTAL_BAR = 'horizontal_bar',
  VERTICAL_IMAGE = 'vertical_image',
  TOGGLE = 'toggle'
}

export enum MobilizationRouteColor {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red',
  BLACK = 'black'
}

export enum MeasureUnit {
  METRES = 'metres',
  LANDMILES = 'landmiles',
  NAUTICALMILES = 'nauticalmiles'
}

export enum DecimalSeparatorType {
  PERIOD = 'Period',
  COMMA = 'Comma'
}
//******************************************************************************* */

//*** */
export interface MapOptions {
  minZoom?: number;
  maxZoom?: number;
  scrollWheelZoom?: boolean;
  gestureHandling?: boolean;
  wheelPxPerZoomLevel?: number;
  zoomControl?: boolean;
  zoomControlPosition?: Position;
  scaleControl?: boolean;
  scaleControlPosition?: Position;
  mapView?: MapViewType;
  mapViewSwitchControl?: {
    type: MapViewSwitchControlType;
    mapViews: MapViewType[];
    defaultMapView: MapViewType;
    position: Position;
  };
  invalidateSizeOnLoad?: boolean; //pass true if map is loaded inside dialog
  enablePopupCloseOnZoom?: boolean; // pass true if popup need close on zoom in/out
  preventCentreUpdateOnResize?: boolean;
  noBaseLayers?: boolean;
}

export interface MapAdditionalOptions extends MapOptions {
  center?: { lat: number; lng: number };
  zoom?: number;
  attributionControl?: boolean;
  baseMap?: string;
  renderer?: any;
}

export interface MapZoomRange {
  min: number;
  max: number;
}

export interface MarkerPopupData {
  [name: string]: any;
}

export interface Marker {
  point: LatLngObject;
  options?: MarkerOptions;
  popupData?: MarkerPopupData;
}

export interface MarkerOptions {
  icon?: MarkerIcon;
  highlightIcon?: MarkerIcon | DivIcon;
  keyboard?: boolean;
  title?: string;
  alt?: string;
  zIndexOffset?: number;
  opacity?: number;
  riseOnHover?: boolean;
  riseOffset?: number;
  interactive?: boolean;
  popup?: boolean;
  color?: string;
  rotationAngle?: number;
}

export interface MarkerIcon {
  url: string;
  size?: Coordinate;
  iconAnchor?: Coordinate;
  html?: string;
}

export interface Coordinate {
  x: number;
  y: number;
}

export interface PolyArrowOptions {
  polyLineOption?: PolyLineOptions;
  arrowHeadOption?: ArrowHeadsOptions;
}

export interface PolyLineOptions {
  stroke?: boolean; //Whether to draw stroke along the path
  color?: string; //colour of the polyline
  weight?: number;
  opacity?: number;
  lineCap?: string;
  lineJoin?: string;
  smoothFactor?: number;
}

export interface ArrowHeadsOptions {
  yawn?: number; //yawn number in degrees arrow head opening size
  size?: number | string; //arrow size
  frequency?: number | string; //arrow's in single poly line allvertices,endonly,20,50px
  proportionalToTotal?: boolean; //proportional value
  fill?: boolean; //whether need to fill with colour or empty
  color?: string; //colour for arrow
  fillColor?: string; //arrow fill color in particular triangle space
}

export interface TrackOptions {
  line_color?: string; //colour of the line
  line_weight?: number; //lineWeight
  line_smoothFactor?: number;
  arrow_yawn?: number; //yawn number in degrees arrow head opening size
  arrow_size?: number | string; //arrow size
  arrow_frequency?: number | string; //arrow's in single poly line
  arrow_proportionalToTotal?: boolean; //proportional value
  arrow_fill?: boolean; //whether need to fill with colour or empty
  arrow_color?: string; //colour for arrow
  arrow_fillColor?: string; //fill color for arrow
}

export interface MarkerTrackOptions {
  nextTrackOptions: TrackOptions;
  prevTrackOptions: TrackOptions;
}

export interface LatLngObject {
  lat: number;
  lng: number;
}

export interface MarkerTrackData {
  previous?: LatLngObject;
  current?: LatLngObject;
  next?: LatLngObject;
}

export interface PopupOptions {
  maxWidth?: number;
  minWidth?: number;
  maxHeight?: number;
  keepInView?: boolean;
  closeButton?: boolean;
  autoClose?: boolean;
  closeOnEscapeKey?: boolean;
  closeOnClick?: boolean;
  className?: string;
  position?: PopupPosition;
  offset?: Coordinate;
  autoPan?: boolean;
  autoPanPadding?: Coordinate;
}

export interface FullScreenControlOptions {
  position?: Position; // position the max and min icon
  title?: string;
  titleCancel?: string;
  content?: unknown;
  forceSeparateButton?: boolean;
  forcePseudoFullscreen?: boolean;
  fullscreenElement?: HTMLElement | boolean;
  fullscreenElementTargetClass?: string;
}

export interface LocationSearchOptions {
  position?: Position; //Position of the search console.
  prepend?: boolean; //control will prepended to other existing controls
  collapsed_mode?: boolean; // whether need in collapsed mode or not
  placeholder?: string; //place holder text in the search
  autocomplete_options?: any; //autocomplete google options
  autoPan?: boolean; //whether pan is needed or not
}

export interface ClusterOptions {
  showCoverageOnHover?: boolean;
  zoomToBoundsOnClick?: boolean;
  spiderfyOnMaxZoom?: boolean;
  iconCreateFunction?: Function;
  animate?: boolean;
  disableClusteringAtZoom?: number;
  maxClusterRadius?: number;
  clusterIconSize?: Coordinate;
}
/**Refer below link for options description
 * https://leafletjs.com/reference-1.6.0.html#path
 */
export interface VectorLayerOptions {
  stroke?: boolean;
  color?: string;
  weight?: number;
  opacity?: number;
  fill?: boolean;
  fillColor?: string;
  fillOpacity?: number;
  dashArray?: string;
  dashOffset?: string;
  className?: string;
  smoothFactor?: number;
  noClip?: boolean;
  interactive?: boolean;
  maintainColor?: boolean; // Only for selected path toolbar
  popup?: boolean;
  lineJoin?: string;
}

export interface VectorLayer {
  type: VectorLayerType;
  points?: Array<LatLngObject>; //For Types POLYGON, POLYLINE, RECTANGLE
  center?: LatLngObject; //For Type CIRCLE Only
  radius?: number; //For Type CIRCLE Only
  options?: VectorLayerOptions;
  popupData?: { [name: string]: any };
}

/**Refer below link for options description
 * http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#drawoptions
 * https://bhaskarvk.github.io/leaflet.extras/reference/draw-options.html
 */

export interface DrawManagerOptions {
  allowIntersection?: boolean;
  guidelineDistance?: number;
  shapeOptions?: VectorLayerOptions;
  drawError?: { color?: string; timeout?: number; message?: string };
  showLength?: boolean;
  metric?: boolean;
  feet?: boolean;
  nautic?: boolean;
  zIndexOffset?: number;
  repeatMode?: boolean;
  showArea?: boolean; // Only applicable for polygon
  showRadius?: boolean; // Only applicable for circle
}

export interface DrawManagerOutput {
  action: DrawManagerActionStatus;
  type: VectorLayerType;
  layer: Layer; // Actual leaflet layer
  points?: Array<LatLngObject>; //For Types POLYGON, POLYLINE, RECTANGLE
  center?: LatLngObject; //For Type CIRCLE Only
  radius?: number; //For Type CIRCLE Only
}

export interface DrawManagerToolbarOptions {
  position: Position;
  draw: {
    polygon: DrawManagerOptions | boolean;
    polyline: DrawManagerOptions | boolean;
    rectangle: DrawManagerOptions | boolean;
    circle: DrawManagerOptions | boolean;
  };
  edit: boolean;
  delete: boolean;
  allowIntersectionWhileEdit: boolean;
  editDrawError?: { color?: string; timeout?: number; message?: string };
}

export interface DrawManagerToolbarText {
  draw?: {
    actions?: {
      title?: string;
      text?: string;
    };
    finish?: {
      title?: string;
      text?: string;
    };
    undo?: {
      title?: string;
      text?: string;
    };
    buttons?: {
      polyline?: string;
      polygon?: string;
      rectangle?: string;
      circle?: string;
    };
  };

  edit?: {
    actions?: {
      save?: {
        title?: string;
        text?: string;
      };
      cancel?: {
        title?: string;
        text?: string;
      };
      clearAll?: {
        title?: string;
        text?: string;
      };
    };
    buttons?: {
      edit?: string;
      editDisabled?: string;
      remove?: string;
      removeDisabled?: string;
    };
  };
}

export interface DrawManagerHandlerText {
  draw?: {
    circle?: {
      tooltip?: {
        start?: string;
      };
      radius?: string;
    };
    polygon?: {
      tooltip?: {
        start?: string;
        cont?: string;
        end?: string;
      };
    };
    polyline?: {
      error?: string;
      tooltip?: {
        start?: string;
        cont?: string;
        end?: string;
      };
    };
    rectangle?: {
      tooltip?: {
        start?: string;
      };
    };
    simpleshape?: {
      tooltip?: {
        end?: string;
      };
    };
  };

  edit?: {
    edit?: {
      tooltip?: {
        text?: string;
        subtext?: string;
      };
    };
    remove?: {
      tooltip?: {
        text?: string;
      };
    };
  };
}

export interface MapContentOptions {
  position: Position; //Position for the map content control
  disableDblClickPropagation?: boolean; // to prevent zoomIn on doubleClick over the content
}

export interface GeoJSONFeatureCollection {
  type: string;
  features: GeoJSONFeature[];
}

export interface GeoJSONGeometry {
  type: string;
  coordinates: number[] | number[][] | number[][][];
}

export interface GeoJSONFeature {
  type: string;
  geometry: GeoJSONGeometry;
  bbox?: number[];
  properties?: any;
}

export type GeoJSON = GeoJSONFeatureCollection | GeoJSONGeometry | GeoJSONFeature;

export type VectorData = VectorLayer[] | GeoJSON[];

/**
 * Refer https://leafletjs.com/reference-1.6.0.html#geojson
 */
export interface GeoJSONOptions {
  style?: (geoJSONFeature: any) => VectorLayerOptions;
  filter?: (geoJSONFeature: any) => boolean;
}

/**
 * Refer https://leafletjs.com/reference-1.6.0.html#tilelayer
 */
export interface TileLayerOptions {
  minZoom?: number; //default 0
  maxZoom?: number; //default 18
  subdomains?: string | string[];
  errorTileUrl?: string;
  zoomOffset?: number;
  zoomReverse?: boolean;
  detectRetina?: boolean;
  crossOrigin?: boolean;
  tileSize?: number; //default 256
  opacity?: number;
  updateWhenIdle?: boolean;
  updateWhenZooming?: boolean; //default true
  updateInterval?: number; //default 200
  zIndex?: number;
  bounds?: LatLngObject[];
  className?: string;
  filter?: (coords: any) => boolean;
  pane?: string;
  paneZIndex?: number;
  httpInstance?: HttpClient;
}

export interface ModuleConfig {
  apiKey: string;
}

export interface RouteMetadata {
  layerId: number;
  latLng: LatLngObject;
}

export interface RecenterControlOptions {
  position?: Position; // position of recenter control in map
  isManual?: boolean;
  coordinate?: LatLngObject;
  zoom?: number;
  fitBounds?: LatLngBounds;
  padding?: [number, number];
}

export interface MeasureControlOptions {
  layer?: string;
  unit?: string;
  decimalSeparator?: DecimalSeparatorType;
  position?: string;
  unitControlLabel?: {
    metres: string;
    kilometres: string;
    feet: string;
    landmiles: string;
    nauticalmiles: string;
  };
  cardInfo?: string[];
  hideDistance?: boolean;
  tooltipTextFinish?: string;
  tooltipTextDelete?: string;
  tooltipTextMove?: string;
  measureControlTitleOn?: string;
  measureControlTitleOff?: string;
  cardTitle?: string;
  cardSubTitle?: string;
  cardTotalDistanceText?: string;
  cardAreaText?: string;
  showBearings?: boolean;
  clearMeasurementsOnStop?: boolean;
  showClearControl?: boolean;
  showUnitControl?: boolean;
  measureControlLabel?: string;
  eventFireControlName?: string;
  tempLine?: MeasureControlStyle;
  fixedLine?: MeasureControlStyle;
  startCircle?: MeasureControlStyle;
  intermedCircle?: MeasureControlStyle;
  currentCircle?: MeasureControlStyle;
  endCircle?: MeasureControlStyle;
  arrowOptions?: MeasureControlStyle;
  allowMultipleLine?: boolean;
}

export interface MeasureControlStyle {
  color?: string;
  weight?: number;
  fillColor?: string;
  fillOpacity?: number;
  radius?: number;
}

export interface MapLayerDetail {
  layerName: string;
  class: string;
  displayText: string;
  enableLayer?: boolean;
  highlightLayer?: boolean;
}

export interface ViewLayerToggleOptions {
  showTrafficLayer?: boolean;
  showSpeedDefaultLayer?: boolean;
  showDistanceDefaultLayer?: boolean;
  measureDistanceTitle?: string;
  tooltipText?: string;
  tooltipTextForSpeed?: string;
  mapLayers?: MapLayerDetail[];
  mapTools?: MapLayerDetail[];
  additionalMapLayers?: MapLayerDetail[];
  mapViews: MapViewType[];
  defaultMapView: MapViewType;
  defaultLayer?: string;
  position: Position;
  moreTooltipWidth?: boolean;
  enableTrafficLayer?: boolean;
  enableSpeedDefaultLayer?: boolean;
  tooltipTextForZone?: any;
  resetLayer?: boolean;
  unselectAllLayer?: boolean;
  resetZoneLayer?: boolean;
  isTablet?: boolean;
}

export interface HeatMapControlOptions {
  minOpacity: number;
  maxZoom?: number;
  max: number;
  radius: number;
  blur: number;
  gradient: any;
}

export interface ShowMeasurementOptions {
  showMeasurements: boolean;
  imperial?: boolean;
}

export type typeDraw = Draw.Circle | Draw.Polygon | Draw.Rectangle | Draw.Polyline;

export interface GeoJsonLineString {
  type: string;
  coordinates: number[][];
}

export interface ElevationProfileEventData {
  geoJsonLineString?: GeoJsonLineString;
  totalDistance?: number;
  fromDate?: string;
  toDate?: string;
  siteId?: string;
}

export type FULL_SCREEN_EVENT = FULL_SCREEN_EVENTS.ENTER_FULL_SCREEN | FULL_SCREEN_EVENTS.EXIT_FULL_SCREEN;
export interface SiteData {
  siteId?: number;
  latLng?: LatLngObject;
  index?: number;
  subState?: string;
  segmentDesc?: string;
  zoneId?: string;
  vectorIndex?: number;
}

export interface DemoModeModuleConfig {
  group_by_views: {
    [key: string]: SiteData;
  };
  priorityView: Array<PriorityViewObject>;
  layerData: LayerDataConfigObject;
}

export interface LayerDataConfigObject {
  [key: string]: LayersDataConfig;
}

export interface PriorityViewObject {
  layerName: string;
  priority: number;
}

export interface FitBoundsObject {
  data: LayersDataConfig;
  view: string;
}

export type LayersDataConfig = Marker[] | VectorData | LatLngObject[];

export interface DemoModeConfig {
  [key: string]: DemoModeModuleConfig;
}
