import { LatLngBounds, Layer, Map, Util } from 'leaflet';
import { LatLngObject } from '../map.model';

export class MapUtility {
  public static getBoundsOfBounds(bounds: LatLngBounds[]): LatLngBounds {
    const latLngs: LatLngObject[] = [];
    for (const bound of bounds) {
      latLngs.push(bound.getSouthWest());
      latLngs.push(bound.getNorthEast());
      latLngs.push(bound.getNorthWest());
      latLngs.push(bound.getSouthEast());
    }

    return this.getLatLngBounds(latLngs);
  }

  public static getLatLngBounds(latLngs: LatLngObject[]): LatLngBounds {
    return new LatLngBounds(latLngs);
  }

  public static getLayerId(layer: Layer): number {
    return Util.stamp(layer);
  }

  public static getPolygonCenter(layer: Layer): LatLngObject {
    return layer.getBounds().getCenter();
  }

  public static getPolygonBoundsZoom(map: Map, layer: Layer): number {
    return map.getBoundsZoom(layer.getBounds());
  }

  public static isPointAvailableInViewPort(map: Map, latLng: LatLngObject): boolean {
    return map.getBounds().contains(latLng);
  }

  /**
   * function to maximise the view of the map
   * openMapfullscreen is assigned based on the browser in which it is viewed
   */
  public static mapFullScreenControl(fullscreen: boolean, mapWidgetElement: string): void {
    const mapWidget = this.selectElementFromDocument(mapWidgetElement);
    mapWidget.removeEventListener('fullscreenchange', this.mapFullScreenListener);
    if (fullscreen) {
      mapWidget.addEventListener('fullscreenchange', this.mapFullScreenListener);
    }
    if (mapWidget) {
      const closeMapFullScreen =
        document['exitFullscreen'] || document['mozCancelFullScreen'] || document['webkitExitFullscreen'] || document['msExitFullscreen'];
      const openMapFullScreen =
        mapWidget['requestFullscreen'] ||
        mapWidget['mozRequestFullScreen'] ||
        mapWidget['webkitRequestFullscreen'] ||
        mapWidget['msRequestFullscreen'];
      fullscreen ? openMapFullScreen && openMapFullScreen.call(mapWidget) : this.mapFullScreen() && closeMapFullScreen.call(document);
    }
  }

  public static selectElementFromDocument(mapWidgetElement: string): HTMLElement {
    return document.querySelector(mapWidgetElement);
  }

  /**
   * Listener function to listen map fullscreenchange event
   */
  public static mapFullScreenListener(): void {
    if (!document.fullscreenElement && document.querySelector('.map-ctrl-maximize-fullscreen')) {
      const docQuery: HTMLElement = document.querySelector('.map-ctrl-maximize-button');
      docQuery?.click();
    }
  }

  /**
   * function to return if the document is in fullscreen mode
   */
  public static mapFullScreen() {
    return document['isFullScreen'] || document['msIsFullScreen'] || document['mozIsFullScreen'] || document['webkitIsFullScreen'];
  }
}
