import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Circle,
  Control,
  Draw,
  Icon,
  Marker as LMarker,
  LatLng,
  LatLngUtil,
  Layer,
  LayerGroup,
  LeafletEvent,
  Polygon,
  Polyline,
  Rectangle,
  Util,
  drawLocal
} from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import '../../libs/draw-manager-patch';
import { DEFAULT_DM_TOOLBAR_OPTIONS, DEFAULT_SELECTED_PATH_OPTION, DEFAULT_UNDO_ICON } from '../../map.constants';
import {
  DrawManagerActionStatus,
  DrawManagerHandlerText,
  DrawManagerOutput,
  DrawManagerToolbarOptions,
  DrawManagerToolbarText,
  MarkerIcon,
  VectorLayerOptions,
  VectorLayerType
} from '../../map.model';
import { MapService } from '../../map.service';
import { VectorLayers } from '../vector-layers/vector-layers.component';

interface UndoRef {
  [id: number]: LatLng[];
}

@Component({
  selector: 'app-vector-layers-with-toolbar',
  template: ''
})
export class VectorLayersToolbar extends VectorLayers implements OnInit, OnDestroy {
  @Input()
  toolbarText: DrawManagerToolbarText;

  @Input()
  handlerText: DrawManagerHandlerText;

  @Input()
  toolbarOptions: DrawManagerToolbarOptions = DEFAULT_DM_TOOLBAR_OPTIONS;

  @Input()
  selectedPathOptions: VectorLayerOptions = DEFAULT_SELECTED_PATH_OPTION;

  @Input()
  tooltipInfoRequired = true;

  @Input()
  undoPolygonEdit = true;

  @Input()
  undoIcon: MarkerIcon = DEFAULT_UNDO_ICON;

  @Output()
  vectorCreate = new EventEmitter<DrawManagerOutput>();

  @Output()
  vectorsEdit = new EventEmitter<DrawManagerOutput[]>();

  @Output()
  vertexEdit = new EventEmitter<Layer>();

  @Output()
  undoEdit = new EventEmitter<Layer>();

  @Output()
  vectorsDelete = new EventEmitter<DrawManagerOutput[]>();

  private toolbar: Control.Draw;

  protected firstBuck: UndoRef = {};
  protected secondBuck: UndoRef = {};
  protected undoRefCnt = 1;

  protected undoMarker: LMarker;
  protected lastEditedPolygon: number;

  constructor(private readonly mapServiceRef: MapService) {
    super(mapServiceRef);
  }

  ngOnInit() {
    this.initializeIfDataNotExists();

    this.attachDrawListeners();
    this.groupInitiate$.subscribe(() => {
      this.createToolbar();
    });
  }

  ngOnDestroy() {
    this.detachDrawListeners();
  }

  /**
   * Polygon vertex move listener. Will be called only on edit mode
   * Will create undo marker on the last moved point
   * @param editedLayers
   */
  private vertexEdited(editedLayers: any): void {
    if (!editedLayers.poly || !(editedLayers.poly instanceof Polygon)) {
      return;
    }

    editedLayers.poly.updateMeasurements();

    const id: number = Util.stamp(editedLayers.poly);
    const curLatLngs = LatLngUtil.cloneLatLngs(editedLayers.poly.getLatLngs());
    const prevLatLngs = this.undoRefCnt % 2 !== 0 ? this.secondBuck[id] : this.firstBuck[id];
    this.lastEditedPolygon = id;
    let movedVertex = null;
    let notAvailable: boolean;

    for (const latlng of curLatLngs[0]) {
      notAvailable = true;
      for (const prevLatLng of prevLatLngs[0]) {
        if (latlng.lat === prevLatLng.lat && latlng.lng === prevLatLng.lng) {
          notAvailable = false;
          break;
        }
      }

      if (notAvailable) {
        movedVertex = latlng;
        break;
      }
    }
    this.updateUndoMarker(movedVertex);
    if (this.undoRefCnt % 2 !== 0) {
      this.firstBuck[id] = LatLngUtil.cloneLatLngs(editedLayers.poly.getLatLngs());
    } else {
      this.secondBuck[id] = LatLngUtil.cloneLatLngs(editedLayers.poly.getLatLngs());
    }

    this.undoRefCnt += 1;
    this.vertexEdit.emit(editedLayers.poly);
  }

  private updateUndoMarker(movedVertex: any): void {
    if (movedVertex) {
      if (!this.undoMarker || !(this.undoMarker instanceof LMarker)) {
        const icon = new Icon({
          iconUrl: this.undoIcon.url,
          iconSize: [this.undoIcon.size.x, this.undoIcon.size.y],
          iconAnchor: [this.undoIcon.iconAnchor.x, this.undoIcon.iconAnchor.y]
        });
        this.undoMarker = new LMarker(movedVertex, { icon });
        this.undoMarker.on('click', this.undoPolygon, this);
        this.undoMarker.addTo(this.mapService.getMap());
      } else {
        this.undoMarker.setLatLng(movedVertex);
      }

      if (!this.mapService.getMap().hasLayer(this.undoMarker)) {
        this.undoMarker.addTo(this.mapService.getMap());
      }
    }
  }

  private vectorEditStart(): void {
    this.resetRefBuckets();

    this.vectorsGroup.eachLayer(layer => {
      if (layer instanceof Polygon) {
        this.firstBuck[Util.stamp(layer)] = LatLngUtil.cloneLatLngs(layer.getLatLngs());
        this.secondBuck[Util.stamp(layer)] = LatLngUtil.cloneLatLngs(layer.getLatLngs());
      }
    });
  }

  /**
   * Undo last moved polygon vertex
   * @param event
   */
  private undoPolygon(event: LeafletEvent): void {
    const vector: Layer = this.vectorsGroup.getLayer(this.lastEditedPolygon);
    if (this.undoRefCnt % 2 !== 0) {
      vector.setLatLngs(this.firstBuck[this.lastEditedPolygon]);
      this.secondBuck[this.lastEditedPolygon] = LatLngUtil.cloneLatLngs(vector.getLatLngs());
    } else {
      vector.setLatLngs(this.secondBuck[this.lastEditedPolygon]);
      this.firstBuck[this.lastEditedPolygon] = LatLngUtil.cloneLatLngs(vector.getLatLngs());
    }
    vector.fire('revert-edited', { layer: vector });
    vector.editing.disable();
    vector.editing.enable();
    this.mapService.getMap().removeLayer(event.target);
    this.undoEdit.emit(vector);
  }

  private resetRefBuckets(): void {
    if (!this.undoPolygonEdit) {
      return;
    }
    this.undoRefCnt = 1;
    this.firstBuck = {};
    this.secondBuck = {};
    this.removeUndoMarker();
  }

  /**
   * Method used to initiate toolbar with toolbartext and handler text
   */
  private createToolbar() {
    this.prepareToolbarText();
    const drawOptions = { ...this.toolbarOptions.draw, marker: false, circlemarker: false };

    if (typeof drawOptions.rectangle === 'boolean') {
      if (drawOptions.rectangle) {
        drawOptions.rectangle = { showArea: false };
      }
    } else {
      drawOptions.rectangle.showArea = false;
    }

    if (this.toolbar) {
      return;
    }

    this.toolbar = new Control.Draw({
      position: this.toolbarOptions.position,
      draw: drawOptions,
      edit: this.toolbarOptions.edit
        ? {
            featureGroup: this.vectorsGroup,
            poly: {
              allowIntersection: this.toolbarOptions.allowIntersectionWhileEdit,
              drawError: this.toolbarOptions.editDrawError
            },
            edit: {
              selectedPathOptions: this.selectedPathOptions
            },
            remove: this.toolbarOptions.delete
          }
        : false
    });
    this.mapService.getMap().addControl(this.toolbar);
  }

  protected initializeIfDataNotExists(): void {
    if (!this.data || this.data.length <= 0) {
      this.initialize(this.dataType, [], this.options, this.geoJSONOptions);
    }
  }

  protected removeUndoMarker(): void {
    if (this.undoPolygonEdit && this.undoMarker && this.mapService.getMap().hasLayer(this.undoMarker)) {
      this.mapService.getMap().removeLayer(this.undoMarker);
    }
  }

  protected prepareToolbarText() {
    if (!this.tooltipInfoRequired) {
      Util.setOptions(this.mapService.getMap(), { drawControlTooltips: false });
    }

    if (this.toolbarText && this.toolbarText.draw) {
      drawLocal.draw.toolbar = this.toolbarText.draw;
    }
    if (this.toolbarText && this.toolbarText.edit) {
      drawLocal.edit.toolbar = this.toolbarText.edit;
    }

    if (this.handlerText && this.handlerText.draw) {
      drawLocal.draw.handlers = this.handlerText.draw;
    }
    if (this.handlerText && this.handlerText.edit) {
      drawLocal.edit.handlers = this.handlerText.edit;
    }
  }

  protected attachDrawListeners(): void {
    this.mapService
      .getMap()
      .on(Draw.Event.CREATED, this.vectorCreated, this)
      .on(Draw.Event.EDITED, this.vectorsEdited, this)
      .on(Draw.Event.DELETED, this.vectorsDeleted, this);

    if (this.undoPolygonEdit) {
      this.mapService
        .getMap()
        .on(Draw.Event.EDITSTART, this.vectorEditStart, this)
        .on(Draw.Event.EDITVERTEX, this.vertexEdited, this)
        .on(Draw.Event.EDITSTOP, this.vectorEditStop, this);
    }
  }

  /**
   * Detach listeners. Will be called when the component gets destroyed
   */
  protected detachDrawListeners(): void {
    this.detachVectorEventListeners();

    this.mapService
      .getMap()
      .off(Draw.Event.CREATED, this.vectorCreated, this)
      .off(Draw.Event.EDITED, this.vectorsEdited, this)
      .off(Draw.Event.DELETED, this.vectorsDeleted, this);

    if (this.undoPolygonEdit) {
      this.mapService
        .getMap()
        .off(Draw.Event.EDITSTART, this.vectorEditStart, this)
        .off(Draw.Event.EDITVERTEX, this.vertexEdited, this)
        .off(Draw.Event.EDITSTOP, this.vectorEditStop, this);

      if (this.undoMarker && this.undoMarker instanceof LMarker) {
        this.undoMarker.off('click', this.undoPolygon, this);
      }
    }

    this.groupInitiate$.unsubscribe();
  }

  private vectorEditStop(): void {
    this.removeUndoMarker();
  }

  /**
   * Listener that will be triggered when a vector is drawn with the created vector
   * Method that emits created vector to the host
   * @param event
   */
  private vectorCreated(event: any): void {
    if (!this.vectorsGroup || !this.mapService.getMap().hasLayer(this.vectorsGroup)) {
      return;
    }

    this.vectorsGroup.addLayer(event.layer);

    this.handleEvent(this.vectorsLoad, [event.layer]);

    switch (event.layerType) {
      case VectorLayerType.POLYGON:
        this.vectorCreate.emit({
          action: DrawManagerActionStatus.CREATED,
          layer: event.layer,
          type: VectorLayerType.POLYGON,
          points: event.layer.getLatLngs()
        });

        break;

      case VectorLayerType.POLYLINE:
        this.vectorCreate.emit({
          action: DrawManagerActionStatus.CREATED,
          layer: event.layer,
          type: VectorLayerType.POLYLINE,
          points: event.layer.getLatLngs()
        });

        break;

      case VectorLayerType.RECTANGLE:
        this.vectorCreate.emit({
          action: DrawManagerActionStatus.CREATED,
          layer: event.layer,
          type: VectorLayerType.RECTANGLE,
          points: event.layer.getLatLngs()
        });

        break;

      case VectorLayerType.CIRCLE:
        this.vectorCreate.emit({
          action: DrawManagerActionStatus.CREATED,
          layer: event.layer,
          type: VectorLayerType.CIRCLE,
          center: event.layer.getLatLng(),
          radius: event.layer.getRadius()
        });
        break;

      default:
        break;
    }
  }

  /**
   * Listener that will be triggered when vectors are edited with the created vectors
   * Method that emits edited vectors to the host
   * @param event
   */
  private vectorsEdited(event): void {
    this.removeUndoMarker();

    this.processOutputVectors(event.layers, DrawManagerActionStatus.EDITED).then((data: DrawManagerOutput[]) => {
      this.vectorsEdit.emit(data);
    });
  }

  /**
   * Listener that will be triggered when vectors are deleted with the deleted vectors info
   * Method that emits deleted vectors to the host
   * @param event
   */
  private vectorsDeleted(event): void {
    this.processOutputVectors(event.layers, DrawManagerActionStatus.DELETED).then((data: DrawManagerOutput[]) => {
      this.vectorsDelete.emit(data);
    });
  }

  /**
   * Method used to construct output of Vectors created, edited & deleted events
   * @param layers
   * @param actionStatus
   */
  private processOutputVectors(layers: LayerGroup, actionStatus: DrawManagerActionStatus): Promise<DrawManagerOutput[]> {
    return new Promise(resolve => {
      const savedVectors: DrawManagerOutput[] = [];
      layers.eachLayer(layer => {
        if (layer instanceof Polygon) {
          savedVectors.push({
            action: actionStatus,
            layer,
            type: VectorLayerType.POLYGON,
            points: layer.getLatLngs()
          });
        } else if (layer instanceof Polyline) {
          savedVectors.push({
            action: actionStatus,
            layer,
            type: VectorLayerType.POLYLINE,
            points: layer.getLatLngs()
          });
        } else if (layer instanceof Rectangle) {
          savedVectors.push({
            action: actionStatus,
            layer,
            type: VectorLayerType.RECTANGLE,
            points: layer.getLatLngs()
          });
        } else if (layer instanceof Circle) {
          savedVectors.push({
            action: actionStatus,
            layer,
            type: VectorLayerType.CIRCLE,
            center: layer.getLatLng(),
            radius: layer.getRadius()
          });
        }
      });
      resolve(savedVectors);
    });
  }
}
