import { Control, DomEvent, DomUtil, Evented, Util } from 'leaflet';
import { MAP_CTRL_MAXIMIZE_FULLSCREEN } from '../map.constants';

/**
 * Leaflet full-screen control
 *
 * Refer https://github.com/brunob/leaflet.fullscreen
 * As This control was rewritten based on this lib
 */

export const nativeAPI = (() => {
  const methodMap = [
    // Standard
    [
      'requestFullscreen',
      'exitFullscreen',
      'fullscreenElement',
      'fullscreenEnabled',
      'fullscreenchange',
      'fullscreenerror'
    ],
    // New WebKit
    [
      'webkitRequestFullscreen',
      'webkitExitFullscreen',
      'webkitFullscreenElement',
      'webkitFullscreenEnabled',
      'webkitfullscreenchange',
      'webkitfullscreenerror'
    ]
  ];

  const baseList = methodMap[0];
  const ret = {};

  for (const methodList of methodMap) {
    if (methodList[1] in document) {
      for (let i = 0; i < methodList.length; i++) {
        ret[baseList[i]] = methodList[i];
      }
      return ret;
    }
  }

  return false;
})();

const eventNameMap = {
  change: nativeAPI['fullscreenchange'],
  error: nativeAPI['fullscreenerror'],
};

export const fullscreenAPI = {
  request(element, options) {
    return new Promise<void>((resolve, reject) => {
      const onFullScreenEntered = function () {
        this.off('change', onFullScreenEntered);
        resolve();
      }.bind(this);

      this.on('change', onFullScreenEntered);
      element = element || document.documentElement;
      const returnPromise = element[nativeAPI['requestFullscreen']]?.(options);
      if (returnPromise instanceof Promise) {
        returnPromise.then(onFullScreenEntered).catch(reject);
      }
    });
  },
  exit() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFullscreen) {
        resolve();
        return;
      }

      const onFullScreenExit = function () {
        this.off('change', onFullScreenExit);
        resolve();
      }.bind(this);

      this.on('change', onFullScreenExit);
      const returnPromise = document[nativeAPI['exitFullscreen']]();
      if (returnPromise instanceof Promise) {
        returnPromise.then(onFullScreenExit).catch(reject);
      }
    });
  },
  on(event, callback) {
    const eventName = eventNameMap[event];
    if (eventName) {
      document.addEventListener(eventName, callback, false);
    }
  },
  off(event, callback) {
    const eventName = eventNameMap[event];
    if (eventName) {
      document.removeEventListener(eventName, callback, false);
    }
  },
  nativeAPI: nativeAPI
};

Object.defineProperties(fullscreenAPI, {
  isFullscreen: {
    get() {
      return Boolean(document[nativeAPI['fullscreenElement']]);
    }
  },
  isEnabled: {
    enumerable: true,
    get() {
      // Coerce to boolean in case of old WebKit
      return Boolean(document[nativeAPI['fullscreenEnabled']]);
    }
  }
});

export const MaximizeControl = Control.extend(
  {
    includes: Evented.prototype,

    options: { position: 'bottomleft' },
    _screenfull: fullscreenAPI,
    _maximize: false,

    onAdd(map) {
      let container: HTMLElement;
      let containerClassName = '';
      const linkClassName = 'map-ctrl-maximize-button leaflet-bar-part';
      let content = '';
      const title = this.options.title ?? '';
      if (this.options.content) {
        content = this.options.content;
      } else {
        containerClassName = 'map-ctrl-maximize leaflet-bar leaflet-control';
      }
      if (map.zoomControl && !this.options.forceSeparateButton) {
        container = map.zoomControl._container;
      } else {
        container = DomUtil.create('div', containerClassName);
      }
      this.link = DomUtil.create('a', linkClassName, container);
      this.link.href = '#';
      this.link.title = title;
      this.link.innerHTML = content;
      if (this.options.maximize) {
        DomUtil.addClass(container, MAP_CTRL_MAXIMIZE_FULLSCREEN);
      } else {
        DomUtil.removeClass(container, MAP_CTRL_MAXIMIZE_FULLSCREEN);
      }
      this.link.setAttribute('role', 'button');
      this.link.setAttribute('aria-label', title);
      DomEvent.disableClickPropagation(container);

      DomEvent
        .on(this.link, 'click', DomEvent.stop)
        .on(this.link, 'click', this.toggleFullScreen, this);
      if (this._screenfull.isEnabled) {
        DomEvent
          .on(container, this._screenfull.nativeAPI.fullscreenchange, DomEvent.stop)
          .on(container, this._screenfull.nativeAPI.fullscreenchange, this._handleFullscreenChange, this);

        DomEvent
          .on(document, this._screenfull.nativeAPI.fullscreenchange, DomEvent.stop)
          .on(document, this._screenfull.nativeAPI.fullscreenchange, this._handleFullscreenChange, this);
      }
      this._map.fullscreenControl = this;
      this.on('enterFullscreen exitFullscreen', this._toggleState, this);
      return container;
    },

    initialize(maximize, options) {
      this._maximize = maximize;
      Util.setOptions(this, options);
    },

    _toggleState() {
      const container = this.getContainer();
      const title = this.options.title ?? '';
      const titleCancel = this.options.titleCancel ?? ''
      this.link.title = this._map._isFullscreen ? title : titleCancel;
      this._map._isFullscreen
        ? DomUtil.removeClass(container, MAP_CTRL_MAXIMIZE_FULLSCREEN)
        : DomUtil.addClass(container, MAP_CTRL_MAXIMIZE_FULLSCREEN);
    },

    toggleFullScreen() {
      const map = this._map;
      map._exitFired = false;
      if (map._isFullscreen) {
        this.exitFullscreenFn(map);
      } else {
        this.enterFullscreenFn(map);
      }
    },

    exitFullscreenFn(map) {
      const fullscreenElement = this.options.fullscreenElementTargetClass ? document.querySelector(this.options.fullscreenElementTargetClass) : this.options.fullscreenElement;
      if (this._screenfull.isEnabled && !this.options.forcePseudoFullscreen) {
        this._screenfull.exit().then(() => map.invalidateSize());
      } else {
        DomUtil.removeClass(fullscreenElement || map._container, 'leaflet-pseudo-fullscreen');
        map.invalidateSize();
      }
      this.fire('exitFullscreen');
      map._exitFired = true;
      map._isFullscreen = false;
    },

    enterFullscreenFn(map) {
      const fullscreenElement = this.options.fullscreenElementTargetClass ? document.querySelector(this.options.fullscreenElementTargetClass) : this.options.fullscreenElement;
      if (this._screenfull.isEnabled && !this.options.forcePseudoFullscreen) {
        this._screenfull.request(fullscreenElement || map._container).then(() => map.invalidateSize());
      } else {
        DomUtil.addClass(fullscreenElement || map._container, 'leaflet-pseudo-fullscreen');
        map.invalidateSize();
      }
      this.fire('enterFullscreen');
      map._isFullscreen = true;
    },

    maximize(max: boolean) {
      this._maximize = max;
    },

    onRemove() {
      DomEvent
        .off(this.link, 'click', DomEvent.stop)
        .off(this.link, 'click', this.toggleFullScreen, this);

      if (this._screenfull.isEnabled) {
        DomEvent
          .off(this._container, this._screenfull.nativeAPI.fullscreenchange, DomEvent.stop)
          .off(this._container, this._screenfull.nativeAPI.fullscreenchange, this._handleFullscreenChange, this);

        DomEvent
          .off(document, this._screenfull.nativeAPI.fullscreenchange, DomEvent.stop)
          .off(document, this._screenfull.nativeAPI.fullscreenchange, this._handleFullscreenChange, this);
      }
    },

    _handleFullscreenChange(ev) {
      const fullscreenElement = this.options.fullscreenElementTargetClass ? document.querySelector(this.options.fullscreenElementTargetClass) : this.options.fullscreenElement;
      const map = this._map;
      const eventContainer = fullscreenElement || map.getContainer();
      if (ev.target === eventContainer && !this._screenfull.isFullscreen && !map._exitFired) {
        this.exitFullscreenFn(map);
      }
    }
  }
);
