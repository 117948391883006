import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { Control, LatLngBounds } from 'leaflet';
import { RecenterControl as LeafletRecenterControl } from '../../controls/recenter-control';
import { DEFAULT_RECENTER_CONTROL_OPTIONS, ZOOM_PAN_ANIMATION_CONFIG } from '../../map.constants';
import { LatLngObject, RecenterControlOptions } from '../../map.model';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-map-recenter',
  template: ''
})
export class RecenterControl implements OnInit, OnDestroy {
  @Input() options: RecenterControlOptions;
  private control: Control;

  private center: LatLngObject;
  private zoom: number;

  constructor(private readonly mapService: MapService) {}

  ngOnInit(): void {
    this.createControl();
  }

  ngOnDestroy(): void {
    if (this.control) {
      this.control.remove();
    }
  }

  private createControl(): void {
    const options = {
      ...DEFAULT_RECENTER_CONTROL_OPTIONS,
      ...this.options
    };

    this.control = new LeafletRecenterControl(options);

    this.control.addTo(this.mapService.getMap());

    this.control.on('recenter', () => {
      if (this.options.isManual) {
        if (this.options.fitBounds) {
          this.setBounds(this.options.fitBounds, this.options.padding);
        } else {
          this.options.coordinate && this.setCenter(this.options.coordinate);
          this.options.zoom && this.setZoom(this.options.zoom);
        }
      }
      if (!isDemoModeON()) {
        this.recenter();
      }
    });
  }

  public setZoom(_zoom: number) {
    this.zoom = _zoom;
  }

  public setCenter(_center: LatLngObject) {
    this.center = _center;
  }

  public setBounds(_bounds: LatLngBounds, padding: [number, number] = null) {
    if (typeof _bounds.getCenter !== 'function') {
      _bounds = new LatLngBounds(_bounds);
    }
    this.zoom = this.mapService.getMap().getBoundsZoom(_bounds, false, padding);
    this.center = _bounds.getCenter();
  }

  public recenter() {
    this.mapService.getMap().setView(this.center, this.zoom, ZOOM_PAN_ANIMATION_CONFIG);
  }
}
