import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Control } from 'leaflet';
import { ContentControl } from '../../controls/content-control';
import { DEFAULT_MAP_CONTENT_OPTIONS } from '../../map.constants';
import { MapContentOptions } from '../../map.model';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-map-content',
  template: ` <ng-content></ng-content> `
})
export class MapContent implements OnInit {
  @Input() options: MapContentOptions;
  private control: Control;
  constructor(private readonly mapService: MapService, private readonly elementRef: ElementRef) {}
  ngOnInit() {
    this.addContent();
  }
  private addContent() {
    this.options = { ...DEFAULT_MAP_CONTENT_OPTIONS, ...this.options };
    const contentOptions = { position: this.options['position'], disableDblClickPropagation: this.options.disableDblClickPropagation };
    this.control = new ContentControl(this.elementRef.nativeElement, contentOptions);
    this.control.addTo(this.mapService.getMap());
  }
}
