import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CcTooltipModule } from '@cat-digital-workspace/shared-ui-core/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { FullScreenControl } from './map/directives/full-screen-control/full-screen-control.directive';
import { HeatmapComponent } from './map/directives/heatmap/heatmap.component';
import { LocationSearch } from './map/directives/location-search/location-search.directive';
import { MapContent } from './map/directives/map-content/map-content.component';
import { MarkerTrack } from './map/directives/marker-track/marker-track.directive';
import { ClusterMarkers } from './map/directives/markers-cluster/markers-cluster.component';
import { Markers } from './map/directives/markers/markers.component';
import { MeasureControl } from './map/directives/measure/measure-control.component';
import { MobilizationRoutes } from './map/directives/mobilization-routes/mobilization-routes.component';
import { OverlayDropdown } from './map/directives/overlay-dropdown-control/overlay-dropdown-control.component';
import { Popup } from './map/directives/popup/popup.component';
import { RecenterControl } from './map/directives/recenter-control/recenter-control.component';
import { SidePanelComponent } from './map/directives/sidepanel/sidepanel.component';
import { ThreeTilesOverlayDropdownComponent } from './map/directives/three-tiles-overlay-dropdown/three-tiles-overlay-dropdown.component';
import { TileLayer } from './map/directives/tile-layer/tile-layer.component';
import { VectorLayersDrawManager } from './map/directives/vector-layers-draw-manager/vector-layers-draw-manager.component';
import { VectorLayersToolbar } from './map/directives/vector-layers-toolbar/vector-layers-toolbar.component';
import { VectorLayers } from './map/directives/vector-layers/vector-layers.component';
import { ViewLayerToggleComponent } from './map/directives/view-layer-toggle-control/view-layer-toggle-control.component';
import { Map } from './map/map.component';
import { MapConfigService } from './map/map.constants';
import { ModuleConfig } from './map/map.model';
import { GApiLoaderService } from './map/utilities/gmap-loader.service';

@NgModule({
  declarations: [
    Map,
    MarkerTrack,
    Markers,
    ClusterMarkers,
    Popup,
    LocationSearch,
    VectorLayers,
    VectorLayersDrawManager,
    VectorLayersToolbar,
    MapContent,
    OverlayDropdown,
    TileLayer,
    MobilizationRoutes,
    RecenterControl,
    SidePanelComponent,
    MeasureControl,
    ViewLayerToggleComponent,
    HeatmapComponent,
    ThreeTilesOverlayDropdownComponent
  ],
  imports: [CommonModule, CcTooltipModule, TranslocoModule, FullScreenControl],
  exports: [
    Map,
    FullScreenControl,
    Markers,
    ClusterMarkers,
    MarkerTrack,
    Popup,
    LocationSearch,
    VectorLayers,
    VectorLayersDrawManager,
    VectorLayersToolbar,
    MapContent,
    OverlayDropdown,
    TileLayer,
    MobilizationRoutes,
    RecenterControl,
    SidePanelComponent,
    MeasureControl,
    ViewLayerToggleComponent,
    HeatmapComponent,
    ThreeTilesOverlayDropdownComponent
  ],
  providers: [GApiLoaderService]
})
export class MapLeafletModule {
  static forRoot(config: ModuleConfig): ModuleWithProviders<MapLeafletModule> {
    return {
      ngModule: MapLeafletModule,
      providers: [
        {
          provide: MapConfigService,
          useValue: config
        }
      ]
    };
  }
}
