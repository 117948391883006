/**
 * why implement canvas:
 * * * when the number of marker data points is huge,
 * * * number of DOM element for each marker keeps increasing
 * * * which makes the DOM heavy, causing performance issues
 *
 * problem when canvas is implemented:
 * * * with canvas being present, it obstructs all the dom events
 * * * and never propagates it to the top level layer.
 * * * say, we click on dom using measure control tool, it doesn't create polylines
 * * * when canvas is present
 *
 * How custom canvas solves this problem:
 * * * it uses the map overlay's parent as a overlay for the canvas itself
 * * * leaflet-container is the dom element that has all the overlays
 * * * we capture events from leaflet-container and call the respective methods inside canvas
 */

import { Canvas, DomUtil, Util } from 'leaflet';

/**
 * customCanvas as a renderer that will have the pointer events as none for the canvas
 * and will not attach any DOM events
 *
 * This includes mostly the existing library code only.
 */
export const customCanvas = Canvas.include({
  _overlayEvents: null,
  _canvasOverlay: null,
  _isListenersAdded: null,

  _initContainer: function () {
    var container = (this._container = document.createElement('canvas'));
    this._container.style.pointerEvents = 'none';
    this._container.style.zIndex = '200';
    container['_leaflet_disable_events'] = true;
    this._ctx = container.getContext('2d');
    this._createOverlayAndListeners();
  },

  /**
   * Method queries the leaflet-container
   * and uses it as a overlay
   */
  _getCanvasOverlay: function () {
    const leafletContainer = document.querySelector('.map.leaflet-container');
    this._canvasOverlay = leafletContainer;
    this._canvasOverlay.id = 'canvasOverlay';
    this._canvasOverlay.style.position = 'relative';
    this._canvasOverlay.style.zIndex = '201';
    return this._canvasOverlay;
  },

  removeAllListeners: function () {
    this._createOverlayAndListeners(false);
  },

  _createOverlayAndListeners: function (isAdd = true) {
    if (!this._canvasOverlay) {
      this._canvasOverlay = this._getCanvasOverlay();
    }

    if (!this._overlayEvents) {
      this._overlayEvents = {
        canvasClick: (e: MouseEvent) => {
          if ((e.target as HTMLElement).id === 'canvasOverlay' || e.target instanceof SVGPathElement) {
            this._onClick(e);
            this._map?.dragging?.enable();
          }
        },

        canvasMove: (e: MouseEvent) => {
          if ((e.target as HTMLElement).id === 'canvasOverlay') {
            this._onMouseMove(e);
            this._map?.dragging?.enable();
          }
        },

        canvasOut: (e: MouseEvent) => {
          if ((e.target as HTMLElement).id === 'canvasOverlay') this._handleMouseOut(e);
        }
      };
    }

    if (!isAdd) {
      ['click', 'mouseup', 'mousedown', 'dblclick', 'contextmenu'].forEach(event =>
        this._canvasOverlay.removeEventListener(event, this._overlayEvents.canvasClick)
      );
      this._canvasOverlay.removeEventListener('mousemove', this._overlayEvents.canvasMove);
      this._canvasOverlay.removeEventListener('mouseout', this._overlayEvents.canvasOut);
      this._isListenersAdded = false;
      return;
    }

    if (this._isListenersAdded) {
      return;
    }

    ['click', 'mouseup', 'mousedown', 'dblclick', 'contextmenu'].forEach(event =>
      this._canvasOverlay.addEventListener(event, this._overlayEvents.canvasClick)
    );
    // This will check if the mouseover is happenning on top of a marker and makes the cursor as pointer
    this._canvasOverlay.addEventListener('mousemove', this._overlayEvents.canvasMove);
    this._canvasOverlay.addEventListener('mouseout', this._overlayEvents.canvasOut);
    this._isListenersAdded = true;
  },

  _onClick: function (e) {
    var point = this._map.mouseEventToLayerPoint(e),
      layer,
      clickedLayer;

    for (var order = this._drawFirst; order; order = order.next) {
      layer = order.layer;
      if (layer.options.interactive && layer._containsPoint(point)) {
        if (!(e.type === 'click' || e.type === 'preclick') || !this._map._draggableMoved(layer)) {
          clickedLayer = layer;
        }
      }
    }
    this._fireEvent(clickedLayer ? [clickedLayer] : false, e);
  },

  _handleMouseHover: function (e, point) {
    if (this._mouseHoverThrottled) {
      return;
    }

    var layer, candidateHoveredLayer;

    for (var order = this._drawFirst; order; order = order.next) {
      layer = order.layer;
      if (layer.options.interactive && layer._containsPoint(point)) {
        candidateHoveredLayer = layer;
      }
    }

    if (candidateHoveredLayer !== this._hoveredLayer) {
      this._handleMouseOut(e);

      if (candidateHoveredLayer) {
        DomUtil.addClass(this._container, 'leaflet-interactive');
        DomUtil.addClass(this._canvasOverlay, 'leaflet-interactive'); // add cursor:pointer for the overlay
        this._fireEvent([candidateHoveredLayer], e, '_leaflet_disable_events');
        this._hoveredLayer = candidateHoveredLayer;
      }
    }

    this._fireEvent(this._hoveredLayer ? [this._hoveredLayer] : false, e);

    this._mouseHoverThrottled = true;
    setTimeout(
      Util.bind(function () {
        this._mouseHoverThrottled = false;
      }, this),
      32
    );
  },

  _handleMouseOut: function (e) {
    var layer = this._hoveredLayer;
    if (layer) {
      DomUtil.removeClass(this._container, 'leaflet-interactive');
      DomUtil.removeClass(this._canvasOverlay, 'leaflet-interactive'); //add cursor:pointer for the overlay
      this._fireEvent([layer], e, 'mouseout');
      this._hoveredLayer = null;
      this._mouseHoverThrottled = false;
    }
  }
});
