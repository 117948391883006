import { Component, ContentChild, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { Circle, FeatureGroup, GeoJSON as LGeoJSON, Layer, LayerEvent, LeafletEvent } from 'leaflet';
import { Subject } from 'rxjs';
import { DataCircle, DataPolygon, DataPolyline, DataRectangle } from '../../layers/data-vector.layer';
import { MAP_LAYERS } from '../../map-demo.config';
import { DEFAULT_VECTOR_LAYER_OPTIONS } from '../../map.constants';
import {
  GeoJSON,
  GeoJSONOptions,
  ShowMeasurementOptions,
  VectorData,
  VectorDataType,
  VectorLayer,
  VectorLayerOptions,
  VectorLayerType
} from '../../map.model';
import { MapService } from '../../map.service';
import { CoreUtility } from '../../utilities/core-utility.service';
import { Popup } from '../popup/popup.component';

@Component({
  selector: 'app-vector-layers',
  template: ''
})
export class VectorLayers implements OnDestroy, OnChanges {
  @Input()
  dataType: VectorDataType;

  @Input()
  data: VectorData; //Array of default or geojson vectors

  @Input()
  options: VectorLayerOptions;

  @Input()
  geoJSONOptions: GeoJSONOptions;

  @Input()
  measurementOptions: ShowMeasurementOptions = {
    showMeasurements: false
  };

  @Output() vectorsLoad = new EventEmitter<Layer[]>();
  @Output() vectorClick = new EventEmitter<Layer>();
  @Output() vectorMouseout = new EventEmitter<Layer>();
  @Output() vectorMouseover = new EventEmitter<Layer>();

  @ContentChild(Popup) vectorPopup: Popup;

  protected vectorsGroup: FeatureGroup;

  protected readonly groupInitiate$ = new Subject<void>();

  private loadedGeoJSONVectors: Layer[];

  constructor(protected mapService: MapService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      isDemoModeON() && this.mapService.fitBoundsMap({ data: this.data, view: MAP_LAYERS.vectorLayer });
      this.initialize(this.dataType, this.data, this.options, this.geoJSONOptions);
    } else if ('options' in changes) {
      this.setOptions(this.options);
    }
  }

  /**
   * Detach all listeners when the component is destroyed
   * Remove/clear feature group from map
   */

  ngOnDestroy() {
    this.detachVectorEventListeners();
  }

  /**
   * Method used to Generate Leaflet Vectors
   */

  private generateLayers(vectorsData: VectorLayer[], options?: VectorLayerOptions): Promise<Layer[]> {
    return new Promise(resolve => {
      const vectors: Layer[] = [];

      const vectorOptions: VectorLayerOptions = {
        ...DEFAULT_VECTOR_LAYER_OPTIONS,
        ...options
      };

      vectorsData.forEach(vector => {
        if (vector.type !== VectorLayerType.CIRCLE && (!vector.points || vector.points.length < 1)) {
          return;
        }

        switch (vector.type) {
          case VectorLayerType.POLYLINE:
            const polyline = new DataPolyline(vector.points, {
              ...vectorOptions,
              ...vector.options
            });
            this.updateLayer(polyline, vector);
            vectors.push(polyline);
            break;

          case VectorLayerType.POLYGON:
            const polygon = new DataPolygon(vector.points, {
              ...vectorOptions,
              ...vector.options,
              showMeasurements: this.measurementOptions.showMeasurements,
              measurementOptions: { imperial: !this.measurementOptions.imperial, showArea: false }
            });
            this.updateLayer(polygon, vector);
            vectors.push(polygon);
            break;

          case VectorLayerType.RECTANGLE:
            const rectangle = new DataRectangle(vector.points, {
              ...vectorOptions,
              ...vector.options
            });
            this.updateLayer(rectangle, vector);
            vectors.push(rectangle);
            break;

          case VectorLayerType.CIRCLE:
            if (vector.center && vector.radius) {
              const circle = new DataCircle(vector.center, {
                ...vectorOptions,
                ...vector.options,
                radius: vector.radius
              });
              this.updateLayer(circle, vector);
              vectors.push(circle);
            }
            break;
          default:
            break;
        }
      });
      resolve(vectors);
    });
  }

  private updateLayer(layer: any, vector: VectorLayer): void {
    if (vector.popupData) {
      layer.setPopupData(vector.popupData);
    }
  }

  private addListener(event: LayerEvent): void {
    this.loadedGeoJSONVectors.push(event.layer);
  }

  private addGeoJSONVectors(features: GeoJSON[]): void {
    if (this.vectorsLoad.observers.length > 0) {
      this.loadedGeoJSONVectors = [];
      this.vectorsGroup.on('layeradd', this.addListener, this);
    }
    for (const feature of features) {
      this.vectorsGroup.addData(feature);
    }
    if (this.vectorsLoad.observers.length > 0) {
      this.handleEvent(this.vectorsLoad, this.loadedGeoJSONVectors);
      this.vectorsGroup.off('layeradd', this.addListener, this);
    }
  }

  /**
   * Method used to attach vector listeners if obsevers are available
   */
  private attachEventListeners(): Promise<any> {
    this.vectorsGroup.on('click', this.clickListener, this);

    if (this.vectorsLoad.observers.length > 0) {
      this.vectorsGroup.on('add', this.loadsListener, this);
    }

    if (this.vectorMouseout.observers.length > 0) {
      this.vectorsGroup.on('mouseout', this.mouseoutListener, this);
    }

    if (this.vectorMouseover.observers.length > 0) {
      this.vectorsGroup.on('mouseover', this.mouseoverListener, this);
    }

    return Promise.resolve();
  }

  private clickListener(event: LeafletEvent): void {
    if (this.vectorPopup && event.propagatedFrom.options.popup === true) {
      /**
       * For geojson, set properties object of feature as popup context
       */
      this.vectorPopup.show(
        event.latlng,
        this.dataType === VectorDataType.GEOJSON ? event.propagatedFrom.feature.properties : event.propagatedFrom.getPopupData()
      );
    }
    this.handleEvent(this.vectorClick, event.propagatedFrom);
  }

  private mouseoutListener(event: LeafletEvent) {
    this.vectorMouseout.emit(event.propagatedFrom);
  }

  private mouseoverListener(event: LeafletEvent) {
    this.vectorMouseover.emit(event.propagatedFrom);
  }

  private loadsListener(event: LeafletEvent) {
    this.vectorsLoad.emit(event.target.getLayers());
  }

  /**
   * Method to check whether the given object is of type GeoJSON
   * @param object
   */
  private instanceOfGeoJSON(object: any): object is GeoJSON {
    return !!('features' in object || 'coordinates' in object || 'geometry' in object);
  }

  /**
   * Method to check whether the given object is of type VectorLayer
   * @param object
   */
  private instanceOfVectorLayer(object: any): object is VectorLayer {
    return !!('points' in object || 'center' in object);
  }

  /**
   * Method used to create leaflet feature group with generated leaflet vectors
   * @param layers
   */
  protected createDefaultFeatureGroup(layers: Layer[]): void {
    if (this.vectorsGroup && this.mapService.getMap().hasLayer(this.vectorsGroup)) {
      this.vectorsGroup.clearLayers();
      for (const layer of layers) {
        this.vectorsGroup.addLayer(layer);
      }
      this.handleEvent(this.vectorsLoad, layers);
    } else {
      this.vectorsGroup = new FeatureGroup(layers);
      this.attachEventListeners().then(() => {
        this.vectorsGroup.addTo(this.mapService.getMap());
        this.groupInitiate$.next();
      });
    }
  }

  protected detachVectorEventListeners(): void {
    if (this.vectorsGroup) {
      this.vectorsGroup.off('add', this.loadsListener, this);
      this.vectorsGroup.off('click', this.clickListener, this);
      this.vectorsGroup.off('mouseout', this.mouseoutListener, this);
      this.vectorsGroup.off('mouseover', this.mouseoverListener, this);
      CoreUtility.removeLayerGroup(this.mapService.getMap(), this.vectorsGroup);
    }
  }

  /**
   * To create geoJSON feature group
   * @param geoJSON
   * @param geoJSONOptions
   */
  protected createGeoJSONFeatureGroup(geoJSON: GeoJSON[], geoJSONOptions: GeoJSONOptions): void {
    if (this.vectorsGroup && this.mapService.getMap().hasLayer(this.vectorsGroup)) {
      this.mapService.getMap().removeLayer(this.vectorsGroup);
      this.detachVectorEventListeners();
    }

    if (!geoJSONOptions || !geoJSONOptions.style) {
      geoJSONOptions = {
        ...geoJSONOptions,
        style: () => ({
          ...DEFAULT_VECTOR_LAYER_OPTIONS,
          ...this.options
        })
      };
    }

    const groupOptions = {
      ...geoJSONOptions,
      pointToLayer: (feature, latlng) => {
        if (feature.properties.radius && !isNaN(Number(feature.properties.radius))) {
          const circle = new Circle(latlng, {
            radius: feature.properties.radius
          });
          circle.setPopupData(feature.properties);
          return circle;
        }
        return null;
      }
    };

    this.vectorsGroup = new LGeoJSON(geoJSON, groupOptions);
    this.attachEventListeners().then(() => {
      this.vectorsGroup.addTo(this.mapService.getMap());
      this.groupInitiate$.next();
    });
  }

  protected handleEvent<T>(eventEmitter: EventEmitter<T>, event: T) {
    if (eventEmitter.observers.length > 0) {
      eventEmitter.emit(event);
    }
  }

  /**
   * Exposed to host
   * Method used to initialize compoenent with vectors
   * @param vectorsData
   * @param options
   */
  public initialize(
    dataType: VectorDataType,
    vectorsData: VectorData,
    options?: VectorLayerOptions,
    geoJSONOptions?: GeoJSONOptions
  ): void {
    if (vectorsData && vectorsData.length > 0) {
      if (
        (dataType === VectorDataType.GEOJSON && !this.instanceOfGeoJSON(vectorsData[0])) ||
        (dataType !== VectorDataType.GEOJSON && !this.instanceOfVectorLayer(vectorsData[0]))
      ) {
        return;
      }
    }

    this.dataType = dataType;

    if (dataType === VectorDataType.GEOJSON) {
      this.createGeoJSONFeatureGroup(vectorsData as GeoJSON[], geoJSONOptions);
    } else {
      this.generateLayers(vectorsData as VectorLayer[], options).then((layers: Layer[]) => {
        this.createDefaultFeatureGroup(layers);
      });
    }
  }

  private validateDataType(vectors: VectorData): boolean {
    return (
      (this.dataType === VectorDataType.GEOJSON && !this.instanceOfGeoJSON(vectors[0])) ||
      (this.dataType !== VectorDataType.GEOJSON && !this.instanceOfVectorLayer(vectors[0]))
    );
  }

  /**
   * Exposed to host
   * Method used to add vectors after feature group is initiated
   * @param vectors
   */

  public addVectors(vectors: VectorData) {
    if ((this.vectorsGroup && !this.mapService.getMap().hasLayer(this.vectorsGroup)) || vectors.length <= 0) {
      return;
    }

    if (this.validateDataType(vectors)) {
      return;
    }

    if (this.dataType === VectorDataType.GEOJSON) {
      this.addGeoJSONVectors(vectors as GeoJSON[]);
    } else {
      this.generateLayers(vectors as VectorLayer[], this.options).then((generatedVectors: Layer[]) => {
        if (generatedVectors && generatedVectors.length > 0) {
          for (const vector of generatedVectors) {
            vector && this.vectorsGroup?.addLayer(vector);
          }
          this.handleEvent(this.vectorsLoad, generatedVectors);
        }
      });
    }
  }

  /**
   * Exposed to host
   * Method used to remove vectors from feature group
   * @param vectors
   */

  public removeVectors(vectors: Layer[]) {
    if (this.vectorsGroup && this.mapService.getMap().hasLayer(this.vectorsGroup)) {
      for (const vector of vectors) {
        if (vector && this.vectorsGroup.hasLayer(vector)) {
          this.vectorsGroup.removeLayer(vector);
        }
      }
    }
  }

  /**
   * Exposed to host
   * Method used to overide options of all vectors
   * @param options
   */

  public setOptions(options: VectorLayerOptions): void {
    this.options = options;

    if (this.vectorsGroup && this.mapService.getMap().hasLayer(this.vectorsGroup)) {
      this.setStyle(this.vectorsGroup.getLayers(), options);
    }
  }

  /**
   * Exposed to host
   * Method used to change the style of one leaflet vector
   * @param vectors
   * @param options
   */
  public setStyle(vectors: Layer[], options: VectorLayerOptions): void {
    vectors.forEach(vector => {
      vector.setStyle(options);
    });
  }

  public getBounds(): any {
    if (this.vectorsGroup) {
      return this.vectorsGroup.getBounds();
    }
    return undefined;
  }

  /**
   * This method will return leaftet featuregroup object. You can use methods such as bringtofront, bringtoback, etc. of this object
   */
  public getVectorGroup(): FeatureGroup {
    return this.vectorsGroup;
  }
}
