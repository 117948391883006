import { Control, DomEvent, DomUtil, Evented, Util } from 'leaflet';
import { TOGGLER_TOOLTIP } from '../map.constants';

/**
 * Leaflet Map View Toggle Switch Control
 * Takes 2 map types and default map type as input
 * Toggle between the two types on click
 *
 */

const MapViewToggleSwitchControl = Control.extend({
  includes: Evented.prototype,
  _mapTypes: null,
  _defaultMap: null,
  _mapKeys: null,
  onAdd(map) {
    if (this._mapKeys.length < 2) {
      return undefined;
    }

    if (this._mapKeys.length > 2) {
      this._mapKeys = this._mapKeys.slice(0, 2);
    }

    if (this._mapKeys.indexOf(this._defaultMap) === -1) {
      this._defaultMap = this._mapKeys[0];
    }

    const container = DomUtil.create('div', 'switch-control-toggle');
    const altKey = this._toggleKey(this._defaultMap);
    this._mapTypes[this._defaultMap].addTo(map);
    this._toggleButton = DomUtil.create('button', altKey.toLowerCase(), container);
    this._toggleButton.mapType = this._defaultMap;
    this._toggleButton.title = TOGGLER_TOOLTIP[altKey];
    DomEvent.on(this._toggleButton, 'click', this._click, this);
    DomEvent.on(container, 'touchstart dblclick mousewheel', this._stopPropagation, this);
    return container;
  },
  initialize(mapTypes, defaultMap, options) {
    Util.setOptions(this, options);
    this._mapTypes = mapTypes;
    this._defaultMap = defaultMap;
    this._mapKeys = Object.keys(mapTypes);
  },
  getCurrentLayer() {
    return { layer: this._mapTypes[this._toggleButton.mapType], key: this._toggleButton.mapType };
  },
  _click() {
    const mapTypeToLoad = this._toggleKey(this._toggleButton.mapType);
    this._map.removeLayer(this._mapTypes[this._toggleButton.mapType]);
    DomUtil.removeClass(this._toggleButton, mapTypeToLoad.toLowerCase());
    DomUtil.addClass(this._toggleButton, this._toggleButton.mapType.toLowerCase());
    this._toggleButton.title = TOGGLER_TOOLTIP[this._toggleButton.mapType];
    this._toggleButton.mapType = mapTypeToLoad;
    this._mapTypes[mapTypeToLoad].addTo(this._map);
    this.fire('maptypechange', { mapType: mapTypeToLoad });
  },
  _stopPropagation(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.originalEvent) {
      // In case of Leaflet event.
      e.originalEvent._stopped = true;
    } else {
      e.cancelBubble = true;
    }
  },
  _toggleKey(currentKey) {
    return this._mapKeys.indexOf(currentKey) === 1 ? this._mapKeys[0] : this._mapKeys[1];
  },
  onRemove() {
    const container = this.getContainer();
    DomEvent.off(container, 'touchstart dblclick mousewheel', this._stopPropagation, this);
    DomEvent.off(this._toggleButton, 'click', this._click, this);
  }
});

export { MapViewToggleSwitchControl };

