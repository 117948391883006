import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomEvent, FeatureGroup, LeafletEvent, Util } from 'leaflet';
import { RoutesGeoJSON } from '../../layers/mobilization-routes.layer';
import { ROUTE_COLOR_CONFIG, ROUTE_STROKE_WIDTH } from '../../map.constants';
import { GeoJSON, MobilizationRouteColor, RouteMetadata } from '../../map.model';
import { MapService } from '../../map.service';
import { MapUtility } from '../../utilities/map-utility.service';

@Component({
  selector: 'app-mobilization-routes',
  template: ''
})
export class MobilizationRoutes implements OnInit {
  @Output() routeClick = new EventEmitter<RouteMetadata>();
  @Input() highlightDefault = false;

  private routesGroup: FeatureGroup;
  private activeRoute: FeatureGroup;

  constructor(private readonly mapService: MapService) {}

  ngOnInit() {
    this.routesGroup = new FeatureGroup([]);
    this.routesGroup.addTo(this.mapService.getMap());
    this.mapService.getMap().on('click', this.mapClickListener, this);
  }

  private clickListener(event: LeafletEvent): void {
    if (!this.highlightDefault) {
      DomEvent.stopPropagation(event);
      if (this.activeRoute) {
        this.activeRoute.removeHighlight();
      }
      this.routeClick.emit({ layerId: event.target._leaflet_id, latLng: event.latlng });
      this.activeRoute = event.target;
      this.activeRoute.highlight();
    }
  }

  private mapClickListener(event: LeafletEvent): void {
    if (this.activeRoute && !this.highlightDefault) {
      this.activeRoute.removeHighlight();
    }
  }

  public addRoute(data: GeoJSON, color: MobilizationRouteColor): Promise<number> {
    return new Promise((resolve, reject) => {
      const route = new RoutesGeoJSON(this.mapService.getMap(), data, {
        routeColor: color,
        style: () => ({ color: ROUTE_COLOR_CONFIG[color], weight: ROUTE_STROKE_WIDTH })
      }).on('click', this.clickListener, this);
      this.routesGroup.addLayer(route);
      if (this.highlightDefault) {
        this.activeRoute = route;
        this.activeRoute.highlight();
      }
      resolve(Util.stamp(route));
    });
  }

  public removeRoute(layerId: number): void {
    if (this.routesGroup.hasLayer(layerId)) {
      this.routesGroup.removeLayer(layerId);
    }
  }

  public updateRoute(layerId: number, color: MobilizationRouteColor): void {
    if (this.routesGroup.hasLayer(layerId)) {
      const routeLayer = this.routesGroup.getLayer(layerId);
      routeLayer.updateSymbolsColor(color);

      const vectors = routeLayer.getLayers();
      vectors.forEach(vector => {
        if (vector.feature) {
          vector.setStyle({ color: ROUTE_COLOR_CONFIG[color], weight: ROUTE_STROKE_WIDTH });
        }
      });
    }
  }

  public getBounds(): any {
    let boundsOfBounds;
    if (this.routesGroup) {
      const bounds = [];
      this.routesGroup.eachLayer(function (layer) {
        bounds.push(layer.getBounds());
      });
      if (bounds.length > 0) {
        boundsOfBounds = MapUtility.getBoundsOfBounds(bounds);
      }
    }
    return boundsOfBounds;
  }

  public clearRoutes(): any {
    if (this.routesGroup) {
      this.routesGroup.clearLayers();
    }
  }

  public getRouteGroup(): FeatureGroup {
    return this.routesGroup;
  }
}
