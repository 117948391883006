import * as L from 'leaflet';

import { ELEVATION_PROFILE_LAYER, MAP_MEASURE_CONTROL_DEFAULT_CONFIG, POLYLINE_MEASURE_POPUP_TOOLTIP } from '../../map.constants';

/**
 * Leaflet measure control
 *
 */
const _measureControlId = 'polyline-measure-control';
const _unicodeClass = 'polyline-measure-unicode-icon';
let currentCircleCoords;
let newLineSegment;
let clicks = 0;
let markerDragged = false;
let firstPoint;
let firstLatLng;
export const MeasureControl = L.Control.extend({
  disabled: false,
  options: MAP_MEASURE_CONTROL_DEFAULT_CONFIG.options,
  totalDistance: ':totalDistance',
  elevationProfileIntersectError: false,
  elevationProfileFinish: false,
  enableElevationProfileDragging: false,

  _arcpoints: 100, // 100 points = 99 line segments. lower value to make arc less accurate or increase value to make it more accurate.
  _circleNr: -1,
  _lineNr: -1,

  _createControl(label, title, classesToAdd, container, fn, context) {
    const anchor = document.createElement('a');
    anchor.innerHTML = label;
    anchor.setAttribute('title', title);
    classesToAdd.forEach(function (c) {
      anchor.classList.add(c);
    });
    L.DomEvent.on(anchor, 'click', fn, context);
    container.appendChild(anchor);
    return anchor;
  },

  _updatePolyline(polylineState, lastCircleCoords, mouseCoords, eventFireControlName) {
    if (this._currentLine.circleCoords.length > 1) {
      polylineState._rubberlinePath.setStyle(polylineState.options.tempLine);
      const arc = polylineState._polylineArc(lastCircleCoords, mouseCoords);
      if (this._currentLine.circleCoords.length > 2) {
        arc.shift(); // remove first coordinate of the arc, cause it is identical with last coordinate of previous arc
      }
      this._currentLine.polylinePath.setLatLngs(this._currentLine.polylinePath.getLatLngs().concat(arc));
      // following lines needed especially for Mobile Browsers where we just use mouseclicks. No mousemoves, no tempLine.
      const arrowMarker = polylineState._drawArrow(arc);
      arrowMarker.cntLine = polylineState._currentLine.id;
      arrowMarker.cntArrow = polylineState._cntCircle - 1;
      polylineState._currentLine.arrowMarkers.push(arrowMarker);
      const distanceSegment = lastCircleCoords.distanceTo(mouseCoords);
      this._currentLine.distance += distanceSegment;
      const distanceMarker = polylineState._drawDistanceMarker(arc, distanceSegment);
      polylineState._currentLine.distanceMarkers.push(distanceMarker);
      polylineState._map.fire(
        eventFireControlName + this._currentLine.totalDistance,
        polylineState._getDistance(this._currentLine.distance)
      );
    }
  },

  onAdd(map) {
    const self = this;
    // needed to avoid creating points by mouseclick during dragging the map
    map.on('movestart ', function () {
      self._mapdragging = true;
    });
    this._container = document.createElement('div');
    this._container.classList.add('leaflet-bar');
    L.DomEvent.disableClickPropagation(this._container); // otherwise drawing process would instantly start at controls' container or double click would zoom-in map
    const title = this.options.measureControlTitleOn;
    const label = this.options.measureControlLabel;
    const classes = this.options.measureControlClasses;
    if (label.indexOf('&') !== -1) {
      classes.push(_unicodeClass);
    }

    // initialize state
    this._arrPolylines = [];
    this._measureControl = this._createControl(label, title, classes, this._container, this._toggleMeasure, this);
    this._defaultControlBgColor = this._measureControl.style.backgroundColor;
    this._measureControl.setAttribute('id', _measureControlId);
    return this._container;
  },

  /**
   * Method to fire on remove from map
   */
  onRemove() {
    if (this._measuring) {
      this._toggleMeasure();
    }
  },

  // turn off all Leaflet-own events of markers (popups, tooltips). Needed to allow creating points on top of markers
  _saveNonpolylineEvents() {
    this._nonpolylineTargets = this._map._targets;
    if (typeof this._polylineTargets !== 'undefined') {
      this._map._targets = this._polylineTargets;
    } else {
      this._map._targets = {};
    }
  },

  // on disabling the measure add-on, save Polyline-measure events and enable the former Leaflet-own events again
  _savePolylineEvents() {
    this._polylineTargets = this._map._targets;
    this._map._targets = this._nonpolylineTargets;
  },

  _zoomEnd(event) {
    const _eventsTargets = event.target._targets;

    this._map._targets = {};
    for (const key in _eventsTargets) {
      if (!(_eventsTargets[key] instanceof L.Marker)) {
        this._map._targets[key] = _eventsTargets[key];
      } else {
        this._nonpolylineTargets[key] = _eventsTargets[key];
      }
    }
  },

  /**
   * Toggle the measure functionality on or off
   * @private
   */
  _toggleMeasure() {
    if (this.disabled) {
      return;
    }
    this.elevationProfileFinish = false;
    this._measuring = !this._measuring;
    this._map.closePopup();
    if (this._measuring) {
      this._map.on('zoomend', this._zoomEnd, this);
      // if measuring is going to be switched on
      this._mapdragging = false;
      this._saveNonpolylineEvents();
      this._measureControl.classList.add('polyline-measure-controlOnBgColor');
      this._measureControl.style.backgroundColor = this.options.backgroundColor;
      this._measureControl.title = this.options.measureControlTitleOff;
      this._oldCursor = this._map._container.style.cursor; // save former cursor type
      this._map._container.style.cursor = 'crosshair';
      this._doubleClickZoom = this._map.doubleClickZoom.enabled(); // save former status of doubleClickZoom
      this._map.doubleClickZoom.disable();
      // create LayerGroup "layerPaint" (only) the first time Measure Control is switched on
      if (!this._layerPaint) {
        this._layerPaint = L.featureGroup().addTo(this._map);
      }
      this._map.on('mousemove', this._mouseMove, this); //  enable listing to 'mousemove', 'click', 'keydown' events
      this._map.on('click', this._mouseClick, this);

      this._resetPathVariables();
    } else {
      this._map.off('zoomend', this._zoomEnd, this);
      // if measuring is going to be switched off
      this._savePolylineEvents();
      this._measureControl.classList.remove('polyline-measure-controlOnBgColor');
      this._measureControl.style.backgroundColor = this._defaultControlBgColor;
      this._measureControl.title = this.options.measureControlTitleOn;
      this._map._container.style.cursor = this._oldCursor;
      this._map.off('mousemove', this._mouseMove, this);
      this._map.off('click', this._mouseClick, this);
      this._map.off('mousemove', this._dragCircleMousemove, this);
      this._map.off('mouseup', this._dragCircleMouseup, this);
      if (this._doubleClickZoom) {
        this._map.doubleClickZoom.enable();
      }
      if (this.options.clearMeasurementsOnStop && this._layerPaint) {
        // to not fire finish event for elevation profile
        this._clearAllMeasurements(this.options.layer !== ELEVATION_PROFILE_LAYER);
      }
      // to remove temp. Line if line at the moment is being drawn and not finished while clicking the control
      if (this._cntCircle !== 0 && this.options.layer !== ELEVATION_PROFILE_LAYER) {
        this._finishPolylinePath();
      }
    }
    // allow easy to connect the measure control to the app, f.e. to disable the selection on the map when the measurement is turned on
    this._map.fire(`${this.options.eventFireControlName}:toggle`, { sttus: this._measuring });
  },

  /**
   * Clear all measurements from the map
   */
  _clearAllMeasurements(finish = true) {
    if (this._cntCircle !== undefined && this._cntCircle !== 0 && finish) {
      this._finishPolylinePath();
    }
    if (this._layerPaint) {
      this._layerPaint.clearLayers();
    }
    this._arrPolylines = [];
    this._map.fire(`${this.options.eventFireControlName}:clear`);
  },

  _getDistUnit(dist: any, unit: any, value: number) {
    if (dist >= value) {
      dist = (dist / (value / 100)).toFixed(2);
    } else if (dist >= value / 10) {
      dist = (dist / (value / 100)).toFixed(2);
    } else if (dist >= value / 100) {
      dist = (dist / (value / 100)).toFixed(2);
    } else if (this.options.distanceShowSameUnit) {
      dist = (dist / (value / 100)).toFixed(2);
    } else {
      dist = (dist / 0.3048).toFixed(2);
      unit = this.options.unitControlLabel.feet;
    }

    return { dist, unit };
  },

  /**
   * Get the distance in the format specified in the options
   * @param {Number} distance Distance to convert
   * @returns {{value: *, unit: *}}
   * @private
   */
  _getDistance(distance): any {
    let dist = distance;
    let unit;
    if (this.options.unit === 'nauticalmiles') {
      unit = this.options.unitControlLabel.nauticalmiles;
      ({ dist, unit } = this._getDistUnit(dist, unit, 185200));
    } else if (this.options.unit === 'landmiles') {
      unit = this.options.unitControlLabel.landmiles;
      ({ dist, unit } = this._getDistUnit(dist, unit, 160934.4));
    } else {
      unit = this.options.unitControlLabel.kilometres;
      if (dist >= 100000) {
        dist = (dist / 1000).toFixed(2);
      } else if (dist >= 10000) {
        dist = (dist / 1000).toFixed(2);
      } else if (dist >= 1000) {
        dist = (dist / 1000).toFixed(2);
      } else if (this.options.distanceShowSameUnit) {
        dist = (dist / 1000).toFixed(2);
      } else {
        dist = dist.toFixed(2);
        unit = this.options.unitControlLabel.metres;
      }
    }
    const value = this.options.decimalSeparator === 'Comma' ? dist.replace('.', ',') : dist;
    return { value, unit };
  },

  /**
   * Calculate Great-circle Arc (= shortest distance on a sphere like the Earth) between two coordinates
   * formulas: http://www.edwilliams.org/avform.htm
   * @private
   */
  _polylineArc(_from, _to) {
    function _GCinterpolate(f) {
      const A = Math.sin((1 - f) * d) / Math.sin(d);
      const B = Math.sin(f * d) / Math.sin(d);
      const x = A * Math.cos(fromLat) * Math.cos(fromLng) + B * Math.cos(toLat) * Math.cos(toLng);
      const y = A * Math.cos(fromLat) * Math.sin(fromLng) + B * Math.cos(toLat) * Math.sin(toLng);
      const z = A * Math.sin(fromLat) + B * Math.sin(toLat);
      // atan2 better than atan-function cause results are from -pi to +pi
      // => results of latInterpol, lngInterpol always within range -180° ... +180°  => conversion into values < -180° or > + 180° has to be done afterwards
      const latInterpol = (180 / Math.PI) * Math.atan2(z, Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)));
      let lngInterpol = (180 / Math.PI) * Math.atan2(y, x);
      // don't split polyline if it crosses dateline ( -180° or +180°).  Without the polyline would look like: +179° ==> +180° ==> -180° ==> -179°...
      // algo: if difference lngInterpol-from.lng is > 180° there's been an unwanted split from +180 to -180 cause an arc can never span >180°
      const diff = lngInterpol - (fromLng * 180) / Math.PI;
      function trunc(n) {
        return Math[n > 0 ? 'floor' : 'ceil'](n);
      } // alternatively we could use the new Math.trunc method, but Internet Explorer doesn't know it
      if (diff < 0) {
        lngInterpol = lngInterpol - trunc((diff - 180) / 360) * 360;
      } else {
        lngInterpol = lngInterpol - trunc((diff + 180) / 360) * 360;
      }
      return [latInterpol, lngInterpol];
    }

    function _GCarc(npoints) {
      const arrArcCoords = [];
      const delta = 1.0 / (npoints - 1);
      // first point of Arc should NOT be returned
      for (let i = 0; i < npoints; i++) {
        const step = delta * i;
        const coordPair = _GCinterpolate(step);
        arrArcCoords.push(coordPair);
      }
      return arrArcCoords;
    }

    // work with with copies of object's elements _from.lat and _from.lng,
    //otherwise they would get modiefied due to call-by-reference on Objects in Javascript
    let fromLat = _from.lat;
    let fromLng = _from.lng;
    let toLat = _to.lat;
    let toLng = _to.lng;
    fromLat = (fromLat * Math.PI) / 180;
    fromLng = (fromLng * Math.PI) / 180;
    toLat = (toLat * Math.PI) / 180;
    toLng = (toLng * Math.PI) / 180;
    const d =
      2.0 *
      Math.asin(
        Math.sqrt(
          Math.pow(Math.sin((fromLat - toLat) / 2.0), 2) +
            Math.cos(fromLat) * Math.cos(toLat) * Math.pow(Math.sin((fromLng - toLng) / 2.0), 2)
        )
      );
    let arrLatLngs;
    if (d === 0) {
      arrLatLngs = [[fromLat, fromLng]];
    } else {
      arrLatLngs = _GCarc(this._arcpoints);
    }
    return arrLatLngs;
  },

  _drawArrow(arcLine) {
    const midpoint = Math.round(arcLine.length / 2);
    const P1 = arcLine[midpoint - 1];
    const P2 = arcLine[midpoint];
    const diffLng12 = P2[1] - P1[1];
    const diffLat12 = P2[0] - P1[0];
    // center of Great-circle distance, NOT of the arc on a Mercator map! reason: a) to complicated b)
    //map not always Mercator c) good optical feature to see where real center of distance is not the "virtual"
    // warped arc center due to Mercator projection
    const center = [P1[0] + diffLat12 / 2, P1[1] + diffLng12 / 2];
    // angle just an aprroximation, which could be somewhat off if Line runs near high latitudes.
    // Use of *geographical coords* for line segment P1 to P2 is best method.
    //Use of *Pixel coords* for just one arc segement P1 to P2 could create for short lines unexact rotation angles,
    // and the use Use of Pixel coords between endpoints [0] to [99] (in case of 100-point-arc) results in even more
    //rotation difference for high latitudes as with geogrpaphical coords-method
    const cssAngle = -Math.atan2(diffLat12, diffLng12) * 57.29578; // convert radiant to degree as needed for use as CSS value; cssAngle is opposite to mathematical angle.
    const iconArrow = L.divIcon({
      // to avoid getting a default class with paddings and borders assigned by Leaflet
      className: this.elevationProfileIntersectError ? this.options.arrowErrorClass : '',
      iconSize: [16, 16],
      iconAnchor: [8, 8],
      // html : "<img src='iconArrow.png' style='background:green; height:100%; vertical-align:top;
      // transform:rotate("+ cssAngle +"deg)'>"  <<=== alternative method by the use of an image instead of a Unicode symbol.
      html: `
        <div
          style =
          'color:${this.options.arrowOptions.color};
          font-size: 16px;
          line-height: 16px;
          vertical-align:top;
          transform: rotate(${cssAngle}deg)'
        >
          &#x27a4;
        </div>
      `
      // best results if iconSize = font-size = line-height and iconAnchor font-size/2 .
      //both values needed to position symbol in center of L.divIcon for all font-sizes.
    });
    return L.marker(center, { icon: iconArrow, zIndexOffset: -50, interactive: false }).addTo(this._layerPaint); // zIndexOffset to draw arrows below tooltips
  },

  _drawDistanceMarker(arcLine, segmentDistance) {
    const midpoint = Math.round(arcLine.length / 2);
    const P1 = arcLine[midpoint - 1];
    const P2 = arcLine[midpoint];
    const diffLng12 = P2[1] - P1[1];
    const diffLat12 = P2[0] - P1[0];
    // center of Great-circle distance, NOT of the arc on a Mercator map! reason: a) to complicated
    //b) map not always Mercator c) good optical feature to see where real center of distance is not the "virtual"
    // warped arc center due to Mercator projection
    const center = [P1[0] + diffLat12 / 2, P1[1] + diffLng12 / 2];
    // angle just an aprroximation, which could be somewhat off if Line runs near high latitudes.
    // Use of *geographical coords* for line segment P1 to P2 is best method.
    // Use of *Pixel coords* for just one arc segement P1 to P2 could create for short lines unexact rotation angles,
    // and the use Use of Pixel coords between endpoints [0] to [99] (in case of 100-point-arc) results in even more
    // rotation difference for high latitudes as with geogrpaphical coords-method

    let cssAngle = -Math.atan2(diffLat12, diffLng12) * 57.29578; // convert radiant to degree as needed for use as CSS value; cssAngle is opposite to mathematical angle.
    if (cssAngle > 110) {
      cssAngle = cssAngle - 180;
    }
    if (cssAngle < -110) {
      cssAngle = cssAngle - 180;
    }
    const differenceRound = this._getDistance(segmentDistance);

    const iconArrow = L.divIcon({
      className: '', // to avoid getting a default class with paddings and borders assigned by Leaflet
      iconSize: [0, 0],
      iconAnchor: [0, 0],
      // html : "<img src='iconArrow.png' style='background:green; height:100%; vertical-align:top;
      //transform:rotate("+ cssAngle +"deg)'>"  <<=== alternative method by the use of an image instead of a Unicode symbol.
      html: this.options.hideDistance
        ? ''
        : `
        <div
          style =
          'display:flex;
          justify-content:center;
          width:100%;
          height:100%;
          color:#FFF;
          text-shadow: 1px 1px 2px #000000;
          font-weight:normal;
          font-size: 12px;
          line-height: 12px;
          vertical-align:top;
          transform: rotate(${cssAngle}deg)'
        >
          <span style='margin-top:5px;'>${differenceRound.value}&nbsp;${differenceRound.unit}</span>
        </div>
        `
      // best results if iconSize = font-size = line-height and iconAnchor font-size/2 .both values needed to position symbol in center of L.divIcon for all font-sizes.
    });

    return L.marker(center, { icon: iconArrow, zIndexOffset: -50, interactive: false }).addTo(this._layerPaint); // zIndexOffset to draw arrows below tooltips
  },

  /**
   * Event to fire on mouse move
   * @param {Object} e Event
   * @private
   */
  _mouseMove(e) {
    const mouseCoords = e.latlng;
    this._map.on('click', this._mouseClick, this); // necassary for _dragCircle. If switched on already within _dragCircle an unwanted click is fired at the end of the drag.
    if (!mouseCoords || !this._currentLine) {
      return;
    }
    const lastCircleCoords = this._currentLine.circleCoords.last();
    this._rubberlinePath.setLatLngs(this._polylineArc(lastCircleCoords, mouseCoords));
  },

  _startLine(clickCoords) {
    const icon = L.divIcon({
      className: 'polyline-measure-tooltip',
      iconAnchor: [-4, -4]
    });
    const last = function () {
      return this.slice(-1)[0];
    };
    this._rubberlinePath = L.polyline([], {
      // Style of temporary, dashed line while moving the mouse
      color: this.options.layer === ELEVATION_PROFILE_LAYER ? this.options.errorThemeColor : this.options.tempLine.color,
      weight: this.options.tempLine.weight,
      interactive: false,
      dashArray: '8,8'
    })
      .addTo(this._layerPaint)
      .bringToBack();

    const polylineState = this; // use "polylineState" instead of "this" to allow measuring on 2 different maps the same time

    this._currentLine = {
      id: 0,
      circleCoords: [],
      circleMarkers: [],
      arrowMarkers: [],
      distanceMarkers: [],
      tooltips: [],
      distance: 0,
      totalDistance: ':totalDistance',

      polylinePath: L.polyline([], {
        // Style of fixed, polyline after mouse is clicked
        color: this.options.fixedLine.color,
        weight: this.options.fixedLine.weight,
        interactive: false
      })
        .addTo(this._layerPaint)
        .bringToBack(),

      handleMarkers(latlng) {
        // update style on previous marker
        const lastCircleMarker = this.circleMarkers.last();
        if (lastCircleMarker) {
          polylineState._setStyleToCircleMarker(lastCircleMarker, polylineState);
        }
        const newCircleMarker = new L.CircleMarker(latlng, polylineState.options.currentCircle).addTo(polylineState._layerPaint);
        newCircleMarker.cntLine = polylineState._currentLine.id;
        newCircleMarker.cntCircle = polylineState._cntCircle;
        polylineState._cntCircle++;

        if (this.circleMarkers.length === 0) {
          newCircleMarker.on(
            'click',
            e => {
              if (polylineState._measuring && polylineState._cntCircle === 0) {
                return;
              }
              if (this.circleMarkers.length !== 2) {
                e.latlng.lat = newCircleMarker.getLatLng().lat;
                e.latlng.lng = newCircleMarker.getLatLng().lng;
                polylineState._firstCircleMarkerClick(e);
              }
            },
            polylineState
          );
        } else {
          newCircleMarker.bindTooltip(`${polylineState.options.tooltipTextFinish}<br>${polylineState.options.tooltipTextDelete}`, {
            direction: 'top',
            opacity: polylineState.options.tooltipOpacity,
            className: POLYLINE_MEASURE_POPUP_TOOLTIP
          });
          newCircleMarker.on('click', polylineState._handleLastMarkerClick, polylineState);
          newCircleMarker.on('mousedown', polylineState._vertexDrag, polylineState);
        }
        this.circleMarkers.push(newCircleMarker);
      },

      getNewToolTip(latlng) {
        return L.marker(latlng, {
          icon,
          interactive: false
        });
      },

      checkForFirstCoordClick(firstCircleCoords, mouseCoords) {
        return firstCircleCoords && firstCircleCoords.equals(mouseCoords);
      },

      addPoint(mouseCoords, eventFireControlName) {
        const lastCircleCoords = this.circleCoords.last();
        const firstCircleCoords = this.circleCoords[0];
        if (polylineState.options.layer === ELEVATION_PROFILE_LAYER && this.checkForFirstCoordClick(firstCircleCoords, mouseCoords)) {
          return;
        }

        if (lastCircleCoords && lastCircleCoords.equals(mouseCoords)) {
          // don't add a new circle if the click was onto the last circle
          return;
        }

        this.circleCoords.push(mouseCoords);

        // update polyline
        polylineState._updatePolyline(polylineState, lastCircleCoords, mouseCoords, eventFireControlName);

        this.handleMarkers(mouseCoords);
        polylineState._map.fire(`${eventFireControlName}:addNew`, mouseCoords);
      },

      finalize() {
        // remove temporary rubberline
        polylineState._layerPaint.removeLayer(polylineState._rubberlinePath);
        if (this.circleCoords.length > 1) {
          const lastCircleMarker = this.circleMarkers.last();

          polylineState._lastCircleStyleAndPopup(polylineState, lastCircleMarker);

          lastCircleMarker.off('click', polylineState._finishPolylinePath, polylineState);

          polylineState._arrPolylines.push(this);
        } else {
          // if there is only one point, just clean it up
          polylineState._layerPaint.removeLayer(this.circleMarkers.last());
        }
        if (polylineState.options.layer !== ELEVATION_PROFILE_LAYER) {
          polylineState._resetPathVariables();
        }
      }
    };

    this._currentLine.circleCoords.last = last;

    this._currentLine.circleMarkers.last = last;
    this._currentLine.id = this._arrPolylines.length;
  },

  /**
   * Event to fire on mouse click
   * @param {Object} e Event
   * @private
   */
  _mouseClick(e) {
    if (
      typeof e.originalEvent.srcElement.className == 'string' &&
      e.originalEvent.srcElement.className.indexOf('map leaflet-container') === -1 &&
      e.originalEvent.srcElement.className.indexOf('leaflet-heatmap-layer') === -1
    ) {
      return;
    }

    // Disable addition of points when profile is finished
    if (this.options.layer === ELEVATION_PROFILE_LAYER && this.elevationProfileFinish) {
      return;
    }
    // skip if there are no coords provided by the event, or this event's screen coordinates match those of finishing CircleMarker for the line we just completed
    if (!e.latlng || (this._finishCircleScreencoords && this._finishCircleScreencoords.equals(e.containerPoint))) {
      return;
    }

    if (!this._currentLine && !this._mapdragging) {
      if (!this.options.allowMultipleLine) {
        this._clearAllMeasurements();
      }
      this._startLine(e.latlng);
      firstPoint = this._map.latLngToLayerPoint(e.latlng);
      firstLatLng = { lat: e.latlng.lat, lng: e.latlng.lng };
      this._map.fire(`${this.options.eventFireControlName}:start`, this._currentLine);
    }
    // just create a point if the map isn't dragged during the mouseclick.
    if (!this._mapdragging) {
      this._currentLine.addPoint(e.latlng, this.options.eventFireControlName);
      this._map.fire(`${this.options.eventFireControlName}:add`, e);
    } else {
      // this manual setting to "false" needed, instead of a "moveend"-Event.
      // Cause the mouseclick of a "moveend"-event immediately would create a point too the same time.
      this._mapdragging = false;
    }
  },

  _handleMarkerClick(e) {
    if (this._measuring && this._cntCircle === 0) {
      this._dragCircle(e);
      return;
    }

    const markersLength = this._arrPolylines[e.target.cntLine]
      ? this._arrPolylines[e.target.cntLine].circleMarkers.length
      : this._currentLine.circleMarkers.length;
    if (markersLength >= 2) {
      this._dragCircle(e);
    } else {
      this._clearAllMeasurements();
    }
  },

  _getMarkersLength(e: any): any {
    return this._arrPolylines[e.target.cntLine]
      ? this._arrPolylines[e.target.cntLine].circleMarkers.length
      : this._currentLine.circleMarkers.length;
  },

  _handleLastMarkerClick(e) {
    if (this._measuring && this._cntCircle === 0) {
      this._dragCircle(e);
      return;
    }

    clicks = clicks + 1;

    if (clicks === 1) {
      setTimeout(() => {
        if (clicks === 1) {
          const markersLength = this._getMarkersLength(e);
          if (markersLength >= 2) {
            this._dragCircle(e);
          } else {
            this._clearAllMeasurements();
          }
        } else {
          this._finishPolylinePath(e);
        }
        clicks = 0;
      }, 300);
    }
  },

  /**
   * Finish the drawing of the path by clicking onto the last circle or pressing ESC-Key
   * @private
   */
  _finishPolylinePath(e) {
    this._map.fire(`${this.options.eventFireControlName}:finish`, this._currentLine);
    this._currentLine.finalize();
    if (e) {
      this._finishCircleScreencoords = e.containerPoint;
    }
  },

  _disableDrawingForElevationProfile(): void {
    this._map._container.style.cursor = this._oldCursor;
    this._map.off('mousemove', this._mouseMove, this);
    this._map.off('click', this._mouseClick, this);
    this.elevationProfileFinish = true;
    this._map.doubleClickZoom.enable();
  },

  _enableDrawingForElevationProfile(): void {
    this._resetPathVariables();
    this._map.doubleClickZoom.disable();
    this._map._container.style.cursor = 'crosshair';
    this._map.on('mousemove', this._mouseMove, this);
    this._map.on('click', this._mouseClick, this);
  },

  _setErrorStylesForElevationProfile(): void {
    this.elevationProfileIntersectError = true;
    this._currentLine?.circleMarkers.forEach(circleMark => {
      circleMark.setStyle({ ...this.options.endCircle, color: this.options.errorThemeColor, fillColor: this.options.errorThemeColor });
    });
    this.toggleArrowClassForElevationProfileError('add');
    this._currentLine?.polylinePath?.setStyle({ ...this.options.tempLine, color: this.options.errorThemeColor });
  },

  _resetErrorStylesForElevationProfile(): void {
    this.elevationProfileIntersectError = false;
    this._currentLine?.circleMarkers.forEach(circleMark => {
      circleMark.setStyle(this.options.endCircle);
    });
    this.toggleArrowClassForElevationProfileError('remove');
    this._currentLine?.polylinePath?.setStyle(this.options.tempLine);
  },

  toggleArrowClassForElevationProfileError(method = 'remove'): void {
    this._currentLine?.arrowMarkers.forEach(arrowMark => {
      arrowMark._icon?.classList[method](this.options.arrowErrorClass);
    });
  },

  _firstCircleMarkerClick(e) {
    this._currentLine.addPoint(e.latlng, this.options.eventFireControlName);
  },

  /*
   * After completing a path, reset all the values to prepare in creating the next polyline measurement
   * @private
   */
  _resetPathVariables(resetCurrentLine = true) {
    this.elevationProfileIntersectError = false;
    this.enableElevationProfileDragging = false;
    this.elevationProfileFinish = false;
    this._cntCircle = 0;
    this._currentLine = resetCurrentLine ? null : this._currentLine;
  },

  _dragCircleMouseup(e) {
    if (markerDragged) {
      // bind new popup-tooltip to the last CircleMArker if dragging finished
      this._e1.target.unbindTooltip();
      this._e1.target.bindTooltip(`${this.options.tooltipTextMove}<br>${this.options.tooltipTextDelete}`, {
        direction: 'top',
        opacity: 0.7,
        className: POLYLINE_MEASURE_POPUP_TOOLTIP
      });

      this._resetPathVariables(this.options.layer !== ELEVATION_PROFILE_LAYER);
      this._map.off('mousemove', this._dragCircleMousemove, this);
      this._map.dragging.enable();
      this._map.on('mousemove', this._mouseMove, this);
      this._map.off('mouseup', this._dragCircleMouseup, this);
      this._map.fire(`${this.options.eventFireControlName}:move`, this._e1);
      this._map.fire(`${this.options.eventFireControlName}:moveCoords`, { coords: this._arrPolylines[this._lineNr].circleCoords });
    } else {
      this._map.dragging.enable();
      this._map.on('mousemove', this._mouseMove, this);
    }
  },

  _getfirstLine(circleNr: number, lineNr: number, arcpoints: number, lineCoords: any): boolean {
    let updateFirstLine = true;
    if (circleNr >= 1) {
      // redraw previous arc just if circle is not starting circle of polyline
      const newLineSegment1 = this._polylineArc(this._arrPolylines[lineNr].circleCoords[circleNr - 1], currentCircleCoords);
      Array.prototype.splice.apply(lineCoords, [(circleNr - 1) * (arcpoints - 1), arcpoints].concat(newLineSegment1));
      let arrowMarker;
      if (newLineSegment1.length <= 1) {
        arrowMarker = L.marker();
        updateFirstLine = false;
      } else {
        arrowMarker = this._drawArrow(newLineSegment1);
        updateFirstLine = true;
      }

      arrowMarker.cntLine = lineNr;
      arrowMarker.cntArrow = circleNr - 1;
      this._arrPolylines[lineNr].arrowMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].arrowMarkers[circleNr - 1] = arrowMarker;

      const distance1 = this._arrPolylines[lineNr].circleCoords[circleNr - 1].distanceTo(this._arrPolylines[lineNr].circleCoords[circleNr]);
      const distanceMarker = newLineSegment1.length <= 1 ? L.marker() : this._drawDistanceMarker(newLineSegment1, distance1);
      this._arrPolylines[lineNr].distanceMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].distanceMarkers[circleNr - 1] = distanceMarker;
    }

    return updateFirstLine;
  },

  _getSecondLine(circleNr: number, lineNr: number, arcpoints: number, lineCoords: any): boolean {
    let updateSecondLine = true;

    if (circleNr < this._arrPolylines[lineNr].circleCoords.length - 1) {
      // redraw following arc just if circle is not end circle of polyline
      const newLineSegment2 = this._polylineArc(currentCircleCoords, this._arrPolylines[lineNr].circleCoords[circleNr + 1]);
      Array.prototype.splice.apply(lineCoords, [circleNr * (arcpoints - 1), arcpoints].concat(newLineSegment2));
      let arrowMarker;
      if (newLineSegment2.length <= 1) {
        arrowMarker = L.marker();
        updateSecondLine = false;
      } else {
        arrowMarker = this._drawArrow(newLineSegment2);
        updateSecondLine = true;
      }

      arrowMarker.cntLine = lineNr;
      arrowMarker.cntArrow = circleNr;
      this._arrPolylines[lineNr].arrowMarkers[circleNr].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].arrowMarkers[circleNr] = arrowMarker;

      const distance2 = this._arrPolylines[lineNr].circleCoords[circleNr].distanceTo(this._arrPolylines[lineNr].circleCoords[circleNr + 1]);
      const distanceMarker = newLineSegment2.length <= 1 ? L.marker() : this._drawDistanceMarker(newLineSegment2, distance2);
      this._arrPolylines[lineNr].distanceMarkers[circleNr].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].distanceMarkers[circleNr] = distanceMarker;
    }

    return updateSecondLine;
  },

  _dragCircleMousemove(e2): void {
    if (this._e1.originalEvent.timeStamp + 20 < e2.originalEvent.timeStamp) {
      markerDragged = true;
      const curPoint = this._map.latLngToLayerPoint(e2.latlng);
      if (firstPoint.distanceTo(curPoint) < 4) {
        e2.latlng.lat = firstLatLng.lat;
        e2.latlng.lng = firstLatLng.lng;
      }
      const mouseNewLat = e2.latlng.lat;
      const mouseNewLng = e2.latlng.lng;
      const latDifference = mouseNewLat - this._mouseStartingLat;
      const lngDifference = mouseNewLng - this._mouseStartingLng;
      currentCircleCoords = L.latLng(this._circleStartingLat + latDifference, this._circleStartingLng + lngDifference);
      const arcpoints = this._arcpoints;
      const lineNr = this._e1.target.cntLine;
      this._lineNr = lineNr;

      const circleNr = this._e1.target.cntCircle;

      this._circleNr = circleNr;
      this._e1.target.setLatLng(currentCircleCoords);
      this._e1.target.unbindTooltip(); // unbind popup-tooltip cause otherwise it would be annoying during dragging, or popup instantly again if it's just closed
      this._arrPolylines[lineNr]['circleCoords'][circleNr] = currentCircleCoords;
      const lineCoords = this._arrPolylines[lineNr].polylinePath.getLatLngs().slice(); // get Coords of each Point of the current Polyline

      const updateFirstLine = this._getfirstLine(circleNr, lineNr, arcpoints, lineCoords);
      const updateSecondLine = this._getSecondLine(circleNr, lineNr, arcpoints, lineCoords);
      if (updateSecondLine && updateFirstLine) {
        this._arrPolylines[lineNr].polylinePath.setLatLngs(lineCoords);
      }

      let totalDistance = 0;
      // update tooltip texts of each tooltip
      this._arrPolylines[lineNr].tooltips.map(
        function (item, index) {
          if (index >= 1) {
            const distance = this._arrPolylines[lineNr].circleCoords[index - 1].distanceTo(this._arrPolylines[lineNr].circleCoords[index]);
            totalDistance += distance;
            this._arrPolylines[lineNr].distance = totalDistance;
            this._map.fire(this.options.eventFireControlName + this.totalDistance, this._getDistance(totalDistance));
          }
        }.bind(this)
      );

      this._arrPolylines[lineNr].circleCoords.map(
        function (item, index) {
          if (index >= 1) {
            const distance = this._arrPolylines[lineNr].circleCoords[index - 1].distanceTo(this._arrPolylines[lineNr].circleCoords[index]);
            totalDistance += distance;
            this._arrPolylines[lineNr].distance = totalDistance;
            this._map.fire(this.options.eventFireControlName + this.totalDistance, this._getDistance(totalDistance));
          }
        }.bind(this)
      );
    }
  },

  // not just used for dragging Cirles but also for deleting circles
  _dragCircle(e1) {
    L.DomEvent.stopPropagation(e1);
    this._map.off('mousemove', this._dragCircleMousemove, this);
    this._removeMapForElevationFinish();
    if (markerDragged) {
      markerDragged = false;
      return;
    }
    let markerReference = null;
    const arcpoints = this._arcpoints;
    this._lineNr = e1.target.cntLine;
    const lineNr = this._lineNr;
    this._circleNr = e1.target.cntCircle;
    const circleNr = this._circleNr;
    let lineCoords;
    let arrowMarker;
    if (this._arrPolylines[lineNr]) {
      if (this._arrPolylines[lineNr].circleMarkers.length === 2) {
        // if there are just 2 remaining points, delete all these points and the remaining line, since there should not stay a lonely point the map
        this._clearAllMeasurements(this.options.layer !== ELEVATION_PROFILE_LAYER);
        this._map.fire(`${this.options.eventFireControlName}:remove`, e1);
        this._map.fire(this.options.eventFireControlName + this.totalDistance, 0);
        return;
      }

      this._arrPolylines[lineNr].circleCoords.splice(circleNr, 1);
      this._arrPolylines[lineNr].circleMarkers[circleNr].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].circleMarkers.splice(circleNr, 1);
      this._arrPolylines[lineNr].circleMarkers.map(function (item, index) {
        item.cntCircle = index;
      });
      lineCoords = this._arrPolylines[lineNr].polylinePath.getLatLngs();

      // if the last Circle in polyline is being removed (in the code above, so length will be equal 0)
      if (!this._arrPolylines[lineNr].circleMarkers.length) {
        this._arrPolylines.splice(lineNr, 1);
        // when you delete the line in the middle of array, other lines indexes change, so you need to update line number of markers and circles
        this._arrPolylines.forEach(function (line, index) {
          line.circleMarkers.map(function (item) {
            item.cntLine = index;
          });
          line.arrowMarkers.map(function (item) {
            item.cntLine = index;
          });
        });

        return;
      }
      markerReference = this._removeCircleInPloyline(lineNr, circleNr, lineCoords, arcpoints, arrowMarker, markerReference);

      this._arrPolylines[lineNr].polylinePath.setLatLngs(lineCoords);
      this._arrPolylines[lineNr].arrowMarkers.map(function (item, index) {
        item.cntLine = lineNr;
        item.cntArrow = index;
      });
      let totalDistance = 0;
      this._arrPolylines[lineNr].circleCoords.map(
        function (item, index) {
          if (index >= 1) {
            const distance = this._arrPolylines[lineNr].circleCoords[index - 1].distanceTo(this._arrPolylines[lineNr].circleCoords[index]);
            totalDistance += distance;
            this._arrPolylines[lineNr].distance = totalDistance;
          }
        }.bind(this)
      );
      this._map.fire(this.options.eventFireControlName + this.totalDistance, this._getDistance(totalDistance));

      // if this is the first line and it's not finished yet
    } else {
      // when you're drawing and deleting point you need to take it into account by decreasing _cntCircle
      this._cntCircle--;
      // if the last Circle in polyline is being removed
      if (this._currentLine.circleMarkers.length === 1) {
        this._currentLine.finalize();
        return;
      }

      this._currentLine.circleCoords.splice(circleNr, 1);
      this._currentLine.circleMarkers[circleNr].removeFrom(this._layerPaint);
      this._currentLine.circleMarkers.splice(circleNr, 1);
      this._currentLine.circleMarkers.map(function (item, index) {
        item.cntCircle = index;
      });
      lineCoords = this._currentLine.polylinePath.getLatLngs();

      markerReference = this._removeCircleInCurrentLine(circleNr, lineCoords, arcpoints, arrowMarker, markerReference);

      this._currentLine.polylinePath.setLatLngs(lineCoords);
      this._currentLine.arrowMarkers.map(function (item, index) {
        item.cntLine = lineNr;
        item.cntArrow = index;
      });
      let totalDistanceUnfinishedLine = 0;
      this._currentLine.circleCoords.map(
        function (item, index) {
          if (index >= 1) {
            const distance = this._currentLine.circleCoords[index - 1].distanceTo(this._currentLine.circleCoords[index]);
            totalDistanceUnfinishedLine += distance;
          }
        }.bind(this)
      );
      this._map.fire(this.options.eventFireControlName + this.totalDistance, this._getDistance(totalDistanceUnfinishedLine));

      // update _currentLine distance after point deletion
      this._currentLine.distance = totalDistanceUnfinishedLine;
    }
    this._map.fire(`${this.options.eventFireControlName}:remove`, e1);
    this._fireClickForMarkerRef(markerReference);
  },

  _vertexDrag(e1) {
    this._e1 = e1;
    if ((this._measuring && this._cntCircle === 0) || this.enableElevationProfileDragging) {
      // just execute drag-function if Measuring tool is active but no line is being drawn at the moment.
      this._map.dragging.disable(); // turn of moving of the map during drag of a circle
      this._map.off('mousemove', this._mouseMove, this);
      this._map.off('click', this._mouseClick, this);
      this._mouseStartingLat = e1.latlng.lat;
      this._mouseStartingLng = e1.latlng.lng;
      this._circleStartingLat = e1.target._latlng.lat;
      this._circleStartingLng = e1.target._latlng.lng;
      this._map.on('mousemove', this._dragCircleMousemove, this);
      this._map.on('mouseup', this._dragCircleMouseup, this);
    }
  },

  updateOptions(options, triggerToggle = false): void {
    this.options = {
      ...options
    };
    this._measureControl.title = this.isMeasuring() ? this.options.measureControlTitleOff : this.options.measureControlTitleOn;
    this._arrPolylines.forEach(polyLine => {
      let totalDistance = 0;
      polyLine.distanceMarkers.forEach((distanceMarker, index) => {
        const firstCircle = polyLine.circleMarkers[index].getLatLng();
        const secondCircle = polyLine.circleMarkers[index + 1].getLatLng();
        const lineSegment = this._polylineArc(firstCircle, secondCircle);
        const distance = firstCircle.distanceTo(secondCircle);
        totalDistance += distance;
        polyLine.distanceMarkers[index].removeFrom(this._layerPaint);
        polyLine.distanceMarkers[index] = lineSegment.length <= 1 ? L.marker() : this._drawDistanceMarker(lineSegment, distance);
      });
      polyLine.distance = totalDistance;
      this._map.fire(`${this.options.eventFireControlName}:preferenceUpdate`, this._getDistance(totalDistance));
    });

    if (triggerToggle) {
      this._toggleMeasure();
    }
  },

  exitMeasure(): void {
    if (this._measuring) {
      this._toggleMeasure();
    }
  },

  isMeasuring(): boolean {
    return this._measuring;
  },
  enable(): void {
    this.disabled = false;
    this._measureControl.classList.remove('map-ctrl-measure-disabled');
  },

  disable(): void {
    this.disabled = true;
    this._measureControl.classList.add('map-ctrl-measure-disabled');
  },

  handleBidirection(circleCoordsLatLng): boolean {
    let isBidirectional = false;
    // On deleting circlecoords when ever we end in a situation where point A <---> B
    for (const i of circleCoordsLatLng) {
      if (circleCoordsLatLng[0].lat === circleCoordsLatLng[2].lat && circleCoordsLatLng[0].lng === circleCoordsLatLng[2].lng) {
        isBidirectional = true;
      }
    }
    return isBidirectional;
  },

  isMeasuringNotFinish() {
    return this._layerPaint && this._rubberlinePath ? this._layerPaint.hasLayer(this._rubberlinePath) : false;
  },

  getMeasurementFeatureGroup() {
    return this._layerPaint ? this._layerPaint : null;
  },

  _disableElevationProfileDragging(): void {
    this.enableElevationProfileDragging = false;
  },

  _enableElevationProfileDragging(): void {
    this.enableElevationProfileDragging = true;
  },

  _fireClickForMarkerRef(markerReference) {
    if (markerReference) {
      markerReference.fire('click');
    }
  },

  _removeCircleInPloyline(lineNr, circleNr, lineCoords, arcpoints, arrowMarker, markerReference) {
    // if first Circle is being removed
    if (circleNr === 0) {
      this._arrPolylines[lineNr].circleMarkers[0].setStyle(this.options.startCircle);
      lineCoords.splice(0, arcpoints - 1);
      this._arrPolylines[lineNr].circleMarkers[0].bindTooltip(`${this.options.tooltipTextMove}<br/> ${this.options.tooltipTextDelete}`, {
        direction: 'top',
        opacity: 0.7,
        className: POLYLINE_MEASURE_POPUP_TOOLTIP
      });
      this._arrPolylines[lineNr].arrowMarkers[circleNr].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].arrowMarkers.splice(0, 1);
      this._arrPolylines[lineNr].distanceMarkers[circleNr].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].distanceMarkers.splice(0, 1);

      this._arrPolylines[lineNr].circleMarkers[0].on(
        'click',
        e => {
          e.latlng.lat = this._arrPolylines[lineNr].circleMarkers[0].getLatLng().lat;
          e.latlng.lng = this._arrPolylines[lineNr].circleMarkers[0].getLatLng().lng;
          this._firstCircleMarkerClick(e);
        },
        this
      );

      // if last Circle is being removed
    } else if (circleNr === this._arrPolylines[lineNr].circleCoords.length) {
      this._arrPolylines[lineNr].circleMarkers[circleNr - 1].bindTooltip(
        `${this.options.tooltipTextMove}<br>${this.options.tooltipTextDelete}`,
        { direction: 'top', opacity: 0.7, className: POLYLINE_MEASURE_POPUP_TOOLTIP }
      );
      this._arrPolylines[lineNr].circleMarkers.slice(-1)[0].setStyle(this.options.endCircle); // get last element of the array
      lineCoords.splice(-(arcpoints - 1), arcpoints - 1);
      this._arrPolylines[lineNr].arrowMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].arrowMarkers.splice(-1, 1);
      this._arrPolylines[lineNr].distanceMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].distanceMarkers.splice(-1, 1);
      // if intermediate Circle is being removed
    } else {
      newLineSegment = this._polylineArc(
        this._arrPolylines[lineNr].circleCoords[circleNr - 1],
        this._arrPolylines[lineNr].circleCoords[circleNr]
      );
      Array.prototype.splice.apply(lineCoords, [(circleNr - 1) * (arcpoints - 1), 2 * arcpoints - 1].concat(newLineSegment));
      this._arrPolylines[lineNr].arrowMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].arrowMarkers[circleNr].removeFrom(this._layerPaint);
      arrowMarker = newLineSegment.length <= 1 ? L.marker() : this._drawArrow(newLineSegment);

      this._arrPolylines[lineNr].arrowMarkers.splice(circleNr - 1, 2, arrowMarker);

      this._arrPolylines[lineNr].distanceMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._arrPolylines[lineNr].distanceMarkers[circleNr].removeFrom(this._layerPaint);
      const distance = this._arrPolylines[lineNr].circleCoords[circleNr - 1].distanceTo(this._arrPolylines[lineNr].circleCoords[circleNr]);
      const distanceMarker = newLineSegment.length <= 1 ? L.marker() : this._drawDistanceMarker(newLineSegment, distance);
      this._arrPolylines[lineNr].distanceMarkers.splice(circleNr - 1, 2, distanceMarker);
      if (this._arrPolylines[lineNr].circleMarkers.length >= 3) {
        const isBidirectional = this.handleBidirection(this._arrPolylines[lineNr].circleCoords);
        if (isBidirectional) {
          markerReference = this._arrPolylines[lineNr].circleMarkers[2];
        }
      }
    }
    return markerReference;
  },

  _removeCircleInCurrentLine(circleNr, lineCoords, arcpoints, arrowMarker, markerReference) {
    if (circleNr === 0) {
      if (this._currentLine.circleMarkers.length === 1) {
        this._currentLine.circleMarkers[0].setStyle(this.options.currentCircle);
      } else {
        this._currentLine.circleMarkers[0].setStyle(this.options.startCircle);
      }
      lineCoords.splice(0, arcpoints - 1);
      this._currentLine.circleMarkers[0].bindTooltip(`${this.options.tooltipTextMove}<br>${this.options.tooltipTextDelete}`, {
        direction: 'top',
        opacity: 0.7,
        className: POLYLINE_MEASURE_POPUP_TOOLTIP
      });
      this._currentLine.arrowMarkers[circleNr].removeFrom(this._layerPaint);
      this._currentLine.arrowMarkers.splice(0, 1);
      this._currentLine.distanceMarkers[circleNr].removeFrom(this._layerPaint);
      this._currentLine.distanceMarkers.splice(0, 1);

      // if last Circle is being removed
    } else if (circleNr === this._currentLine.circleCoords.length) {
      this._currentLine.circleMarkers[circleNr - 1].bindTooltip(`${this.options.tooltipTextFinish}<br>${this.options.tooltipTextDelete}`, {
        direction: 'top',
        opacity: this.options.tooltipOpacity,
        className: POLYLINE_MEASURE_POPUP_TOOLTIP
      });

      this._currentLine.circleMarkers[circleNr - 1].off('click', this._handleMarkerClick, this);
      this._currentLine.circleMarkers[circleNr - 1].on('click', this._handleLastMarkerClick, this);

      this._currentLine.circleMarkers.slice(-1)[0].setStyle(this.options.currentCircle); // get last element of the array
      lineCoords.splice(-(arcpoints - 1), arcpoints - 1);
      this._currentLine.arrowMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._currentLine.arrowMarkers.splice(-1, 1);
      this._currentLine.distanceMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._currentLine.distanceMarkers.splice(-1, 1);
      // if intermediate Circle is being removed
    } else {
      newLineSegment = this._polylineArc(this._currentLine.circleCoords[circleNr - 1], this._currentLine.circleCoords[circleNr]);
      Array.prototype.splice.apply(lineCoords, [(circleNr - 1) * (arcpoints - 1), 2 * arcpoints - 1].concat(newLineSegment));
      this._currentLine.arrowMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._currentLine.arrowMarkers[circleNr].removeFrom(this._layerPaint);
      arrowMarker = this._drawArrow(newLineSegment);
      this._currentLine.arrowMarkers.splice(circleNr - 1, 2, arrowMarker);

      this._currentLine.distanceMarkers[circleNr - 1].removeFrom(this._layerPaint);
      this._currentLine.distanceMarkers[circleNr].removeFrom(this._layerPaint);

      const distance = this._currentLine.circleCoords[circleNr - 1].distanceTo(this._currentLine.circleCoords[circleNr]);
      const distanceMarker = this._drawDistanceMarker(newLineSegment, distance);
      this._currentLine.distanceMarkers.splice(circleNr - 1, 2, distanceMarker);
      if (this._currentLine.circleMarkers.length >= 3) {
        const isBidirectional = this.handleBidirection(this._currentLine.circleCoords);
        if (isBidirectional) {
          markerReference = this._currentLine.circleMarkers[2];
        }
      }
    }
    return markerReference;
  },
  _removeMapForElevationFinish() {
    if (this.options.layer === ELEVATION_PROFILE_LAYER && this.elevationProfileFinish) {
      this._map.off('mousemove', this._mouseMove, this);
      this._map.off('click', this._mouseClick, this);
    }
  },

  _setStyleToCircleMarker(lastCircleMarker, polylineState) {
    if (this._currentLine.circleMarkers.length === 1) {
      lastCircleMarker.setStyle(polylineState.options.startCircle);
    } else {
      lastCircleMarker.bindTooltip(polylineState.options.tooltipTextDelete, {
        direction: 'top',
        opacity: 0.7,
        className: POLYLINE_MEASURE_POPUP_TOOLTIP
      });
      lastCircleMarker.setStyle(polylineState.options.intermedCircle);
      lastCircleMarker.off('click', polylineState._handleLastMarkerClick, polylineState);
      lastCircleMarker.on('click', polylineState._handleMarkerClick, polylineState);
    }
  },
  _lastCircleStyleAndPopup(polylineState, lastCircleMarker) {
    if (polylineState.options.layer !== ELEVATION_PROFILE_LAYER) {
      lastCircleMarker.setStyle(polylineState.options.endCircle);
    }
    // use Leaflet's own tooltip method to shwo a popuo tooltip if user hovers the last circle of a polyline
    lastCircleMarker.unbindTooltip(); // to close the opened Tooltip after it's been opened after click onto point to finish the line
    polylineState._currentLine.circleMarkers.map(function (circle, index) {
      if (index !== 0) {
        circle.bindTooltip(`${polylineState.options.tooltipTextMove}<br> ${polylineState.options.tooltipTextDelete}`, {
          direction: 'top',
          opacity: 0.7,
          className: POLYLINE_MEASURE_POPUP_TOOLTIP
        });
      }
    });
  }
});
