import 'leaflet-draw/dist/leaflet.draw';

declare var L: any;

L.Edit.Circle = L.Edit.CircleMarker.extend({
  _createResizeMarker() {
    const center = this._shape.getLatLng(),
      resizemarkerPoint = this._getResizeMarkerPoint(center);

    this._resizeMarkers = [];
    this._resizeMarkers.push(this._createMarker(resizemarkerPoint, this.options.resizeIcon));
  },

  _getResizeMarkerPoint(latlng) {
    // From L.shape.getBounds()
    const delta = this._shape._radius * Math.cos(Math.PI / 4),
      point = this._map.project(latlng);
    return this._map.unproject([point.x + delta, point.y - delta]);
  },

  _resize(latlng) {
    let radius;
    const moveLatLng = this._moveMarker.getLatLng();

    // Calculate the radius based on the version
    if (L.GeometryUtil.isVersion07x()) {
      radius = moveLatLng.distanceTo(latlng);
    } else {
      radius = this._map.distance(moveLatLng, latlng);
    }
    this._shape.setRadius(radius);

    if (this._map.editTooltip) {
      this._map._editTooltip.updateContent({
        text: `${L.drawLocal.edit.handlers.edit.tooltip.subtext}<br />${L.drawLocal.edit.handlers.edit.tooltip.text}`,
        subtext: `${L.drawLocal.draw.handlers.circle.radius}: ${L.GeometryUtil.readableDistance(
          radius,
          true,
          this.options.feet,
          this.options.nautic
        )}`
      });
    }

    this._shape.setRadius(radius);

    this._map.fire(L.Draw.Event.EDITRESIZE, { layer: this._shape });
  }
});
