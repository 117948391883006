import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Control } from 'leaflet';

import { Position } from '@Terra/shared/widgets/config';
import { Subscription } from 'rxjs';
import { ThreeTilesOverlayControl } from '../../controls/three-tiles-overlay-control';
import { MapService } from '../../map.service';

@Component({
  selector: 'app-three-tiles-overlay-dropdown',
  template: `<ng-content></ng-content>`
})
export class ThreeTilesOverlayDropdownComponent implements OnInit, OnDestroy {
  private control: Control;
  private readonly subscription: Subscription = new Subscription();
  @Output() threeTileLayerOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly mapService: MapService, private readonly elementRef: ElementRef) {}

  ngOnInit() {
    this.addContent();
  }

  private addContent() {
    this.control = new ThreeTilesOverlayControl(this.elementRef.nativeElement, { position: Position.TOPRIGHT });
    this.control.addTo(this.mapService.getMap()).on('threeTilesClick', () => {
      this.mapService.overlayClickSubject.next();
    });

    this.control.addTo(this.mapService.getMap()).on('threeTilesExpand', () => {
      this.mapService.overlayExpandSubject.next('threeTilesExpand');
      this.threeTileLayerOpen.emit(true);
    });

    if (this.mapService.overlayExpandSubject) {
      this.subscription.add(
        this.mapService.overlayExpandSubject.subscribe(data => {
          if (data === 'overlayDropdownExpand' || 'mapLegendsOpen') {
            this.control.collapse();
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
