import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { isDemoModeON } from '@Terra/pitmanagement/shared/utils';
import { Map as LMap, LatLng, Layer, LeafletEvent, LeafletKeyboardEvent, control, gridLayer, map } from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant';
import ResizeObserver from 'resize-observer-polyfill';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MapViewSwitchControl } from './controls/map-view-switch-control';
import { MapViewToggleSwitchControl } from './controls/map-view-toggle-switch-control';
import { RecenterControl } from './directives/recenter-control/recenter-control.component';
import './libs/control-positions-extn';
import { DEMO_MODE_MAP_CONFIGURATIONS, HIDE_STREET_INFO } from './map-demo.config';
import { DEFAULT_OPTIONS, MAP_FIT_BOUNDS_ANIMATION_CONFIG, MAP_INVALIDATE_SIZE_DELAY, MAP_VIEW_LABELS } from './map.constants';
import * as mapHelper from './map.helper';
import { FitBoundsObject, LatLngObject, MapOptions, MapViewSwitchControlType, MapViewType, MapZoomRange, SiteData } from './map.model';
import { MapService } from './map.service';
import { GApiLoaderService } from './utilities/gmap-loader.service';

@Component({
  selector: 'app-widget-map-leaflet',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [MapService]
})
export class Map implements OnInit, OnDestroy, AfterContentInit {
  private readonly destroy$ = new Subject<void>();
  private readonly zoom$ = new BehaviorSubject<number>(DEFAULT_OPTIONS.zoom);
  private readonly zoomRange$ = new BehaviorSubject<MapZoomRange>(null);
  private readonly center$ = new BehaviorSubject<LatLngObject>(DEFAULT_OPTIONS.center);
  private readonly fitBounds$ = new BehaviorSubject<LatLngObject[]>(undefined);
  private readonly maxBounds$ = new BehaviorSubject<LatLngObject[]>(undefined);
  private readonly language$ = new BehaviorSubject<string>(null);
  private readonly viewControls$ = new BehaviorSubject<boolean>(false);
  private readonly mapLayerOption$ = new BehaviorSubject<SiteData>(undefined);

  @ViewChild('map', { static: true }) private readonly mapContainer: ElementRef;
  @ContentChild(RecenterControl) recenterControl: RecenterControl;
  canUpdateCentre = false;
  invalidateSizeEvent = false;
  invalidateSizeEventTriggeredOnce = false;

  demoModeConfig = { ...DEMO_MODE_MAP_CONFIGURATIONS };

  @Input()
  set zoom(_zoom: number) {
    this.zoom$.next(_zoom);
  }

  @Input() options: MapOptions;

  @Input()
  set center(_center: LatLngObject) {
    this.center$.next(_center);
  }

  @Input()
  set fitBounds(_fitBounds: LatLngObject[]) {
    this.fitBounds$.next(_fitBounds);
  }

  @Input()
  set maxBounds(_maxBounds: LatLngObject[]) {
    this.maxBounds$.next(_maxBounds);
  }

  @Input()
  set language(_lang: string) {
    this.language$.next(_lang);
  }

  @Input()
  set zoomRange(zoomRange: MapZoomRange) {
    this.zoomRange$.next(zoomRange);
  }

  @Input() fitBoundsOptions = MAP_FIT_BOUNDS_ANIMATION_CONFIG;
  @Input() apiErrorTemplate: TemplateRef<any>;
  @Input() isApiError = false;
  @Input() mapModule;

  private centerRef: LatLngObject;
  private mapZoom: number;
  private googleTileLayer: any;
  private viewSwitchControl: any;

  @Output() mapZoomChange = new EventEmitter<number>();
  @Output() mapLoad = new EventEmitter<LeafletEvent>();
  @Output() mapCenterChange = new EventEmitter<LatLngObject>();
  @Output() mapResize = new EventEmitter<LeafletEvent>();
  @Output() mapUnload = new EventEmitter<LeafletEvent>();
  @Output() mapKeyPress = new EventEmitter<LeafletKeyboardEvent>();
  @Output() mapClick = new EventEmitter<LeafletEvent>();
  @Output() mapKeyDown = new EventEmitter<LeafletKeyboardEvent>();
  @Output() mapTypeChange = new EventEmitter<MapViewType>();
  @Output() mapDragEnd = new EventEmitter<LeafletEvent>();

  constructor(private readonly mapService: MapService, private readonly ngZone: NgZone) {}

  /**
   * Initita map with default options and subscribe to event listerners

   */

  ngOnInit(): void {
    this.ngZone.run(() => {
      //Enable gesture handling hook
      LMap.addInitHook('addHandler', 'gestureHandling', GestureHandling);
      // Using runOutsideAngular to avoid unnecessary docheck events because of map compoenent
      this.mapService.initializeMap(map(this.mapContainer.nativeElement, mapHelper.getInitializeMapConfig(this.options)));
      this.onLoadFunctionCalls();
    });
  }

  onLoadFunctionCalls(): void {
    if (isDemoModeON()) {
      this.mapService.getMap().on('dragstart', () => this.mapService.getMap()._map.dragging.disable());
      this.subscribeDemoModeBoundsListeners();
      this.subscribeMapLayerOptions();
    } else {
      this.addCoreMapEventListeners();
      this.subscribeViewListeners();
      this.subscribeBoundsListeners();
    }
    this.addScaleAndZoomControls();
    this.subscribeGApiLoad();
    if (this.options?.invalidateSizeOnLoad) {
      this.invalidateMapSizeOnLoad();
    }
  }

  ngAfterContentInit() {
    if (this.recenterControl && !this.recenterControl.options.isManual) {
      this.subscribeRecenterControl();
      //TODO: Need to revisit
      this.viewControls$.next(this.viewSwitchControl);
    }
  }

  ngOnDestroy(): void {
    this.mapService.getMap()?.off('click', (e: LeafletEvent) => this.handleEvent(this.mapClick, e));
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Fit Bounds for DemoMode
   */

  private subscribeDemoModeBoundsListeners(): void {
    this.mapService.fitBoundsSubject.pipe(takeUntil(this.destroy$)).subscribe((_boundsMap: FitBoundsObject) => {
      if (mapHelper.isPriorityView(this.mapModule, _boundsMap.view, this.demoModeConfig[this.mapModule].layerData)) {
        this.demoModeConfig[this.mapModule].layerData = { [_boundsMap.view]: _boundsMap.data };
        const latLngObj = mapHelper.getLatLngObject(this.mapModule, _boundsMap);
        if (latLngObj?.length) {
          this.mapService.getMap().fitBounds(latLngObj, this.fitBoundsOptions);
        }
      }
    });
  }

  private subscribeMapLayerOptions(): void {
    this.mapLayerOption$
      .pipe(
        takeUntil(this.destroy$),
        filter((mapLayerOption: SiteData) => mapLayerOption !== null && mapLayerOption !== undefined)
      )
      .subscribe((mapLayerOption: SiteData) => {
        const latLngObject = mapHelper.getConvertedLatLngObject(
          Object.values(this.demoModeConfig[this.mapModule].layerData)?.[0],
          mapLayerOption
        );
        if (latLngObject?.length) {
          this.mapService.getMap().fitBounds(latLngObject, this.fitBoundsOptions);
        }
      });

    this.viewControls$
      .pipe(
        takeUntil(this.destroy$),
        filter((viewSwitchControl: boolean) => !!viewSwitchControl)
      )
      .subscribe(() => {
        this.mapService.getMap().addControl(this.viewSwitchControl);
      });
  }

  /**
   * To add base google tile layer to map
   */

  private addBaseLayers(): void {
    if (!this.options?.noBaseLayers) {
      if (this.options?.mapViewSwitchControl) {
        this.addMapViewSwitchControl();
      } else {
        this.addMapView();
      }
    }
  }

  getMutantLayer(view: MapViewType): any {
    const viewType = view === MapViewType.TRAFFIC || view === MapViewType.TRANSIT ? MapViewType.ROAD : view;
    const options = isDemoModeON() ? { type: viewType, styles: HIDE_STREET_INFO } : { type: viewType };
    return gridLayer.googleMutant(options);
  }

  private addMapViewSwitchControl(): void {
    let defaultView = '';

    if (this.viewSwitchControl) {
      const currentView = this.viewSwitchControl.getCurrentLayer();
      if (currentView && this.mapService.getMap().hasLayer(currentView.layer)) {
        defaultView = currentView.key;
        this.mapService.getMap().removeLayer(currentView.layer);
      }
    }

    const views = {};

    for (const view of this.options.mapViewSwitchControl.mapViews) {
      const mutantLayer = this.getMutantLayer(view);

      if (view === MapViewType.TRAFFIC || view === MapViewType.TRANSIT) {
        mutantLayer.addGoogleLayer(view);
      }

      views[MAP_VIEW_LABELS[view]] = mutantLayer;
    }

    if (defaultView === '') {
      defaultView =
        this.options.mapViewSwitchControl.mapViews.indexOf(this.options.mapViewSwitchControl.defaultMapView) > -1
          ? MAP_VIEW_LABELS[this.options.mapViewSwitchControl.defaultMapView]
          : MAP_VIEW_LABELS[this.options.mapViewSwitchControl.mapViews[0]];
    }
    if (this.viewSwitchControl) {
      this.mapService.getMap().removeControl(this.viewSwitchControl);
    }

    const switchControlPositionOption = {
      position: this.options.mapViewSwitchControl.position
    };

    this.viewSwitchControl =
      this.options.mapViewSwitchControl.type === MapViewSwitchControlType.TOGGLE
        ? new MapViewToggleSwitchControl(views, defaultView, switchControlPositionOption)
        : new MapViewSwitchControl(this.options.mapViewSwitchControl.type, views, defaultView, switchControlPositionOption);
    //Attach listener only if observer attached to mapTypeChange event emitter
    if (this.mapTypeChange.observers.length > 0) {
      this.viewSwitchControl.on('maptypechange', ({ mapType }: LeafletEvent) => {
        this.mapTypeChange.emit(mapType);
      });
    }
    this.viewControls$.next(true);
  }

  private addMapView(): void {
    if (this.googleTileLayer && this.mapService.getMap().hasLayer(this.googleTileLayer)) {
      this.mapService.getMap().removeLayer(this.googleTileLayer);
    }
    this.googleTileLayer = gridLayer.googleMutant({
      type: this.options?.mapView ? this.options.mapView : DEFAULT_OPTIONS.mapView
    });
    if (this.googleTileLayer && !this.mapService.getMap().hasLayer(this.googleTileLayer)) {
      this.mapService.getMap().addLayer(this.googleTileLayer);
    }
  }

  /**
   * Attach observers for center and zoom
   *
   */

  private subscribeViewListeners(): void {
    this.center$.pipe(takeUntil(this.destroy$)).subscribe((_center: LatLngObject) => {
      this.centerRef = _center;
      const latLong = [_center.lat, _center.lng];
      this.mapService.getMap().setView(latLong);
    });

    this.zoom$.pipe(takeUntil(this.destroy$)).subscribe((_zoom: number) => {
      this.mapService.getMap().setZoom(_zoom);
      this.mapZoom = _zoom;
    });

    this.zoomRange$
      .pipe(
        takeUntil(this.destroy$),
        filter(zoomRange => !!zoomRange)
      )
      .subscribe((zoomRange: MapZoomRange) => {
        this.mapService.getMap().setMinZoom(zoomRange.min);
        this.mapService.getMap().setMaxZoom(zoomRange.max);
      });
    //TODO: Need to revisit to move switch controllers to separate compoenent
    this.viewControls$
      .pipe(
        takeUntil(this.destroy$),
        filter(viewSwitchControl => !!viewSwitchControl)
      )
      .subscribe((views: boolean) => {
        this.mapService.getMap().addControl(this.viewSwitchControl);
      });
  }

  /**
   * Attach observers for map bounds
   *
   */

  private subscribeBoundsListeners(): void {
    this.fitBounds$
      .pipe(
        takeUntil(this.destroy$),
        filter(fitBounds => fitBounds !== null && fitBounds !== undefined)
      )
      .subscribe((_fitBounds: LatLngObject[]) => {
        this.mapService.getMap().fitBounds(_fitBounds, this.fitBoundsOptions);
      });

    this.maxBounds$
      .pipe(
        takeUntil(this.destroy$),
        filter(_maxBounds => _maxBounds !== null && _maxBounds !== undefined)
      )
      .subscribe((_maxBounds: LatLngObject[]) => {
        this.mapService.getMap().setMaxBounds(_maxBounds);
      });
  }

  private subscribeGApiLoad(): void {
    GApiLoaderService.gApiLoadNotifier$.pipe(takeUntil(this.destroy$)).subscribe(load => {
      if (!load) {
        return;
      }
      this.addBaseLayers();
    });

    this.language$
      .pipe(
        takeUntil(this.destroy$),
        filter(lang => lang !== null)
      )
      .subscribe((_lang: string) => {
        GApiLoaderService.load(_lang);
      });
  }

  /**
   * To add zoom control to map
   * @param position of type Position
   */

  private addScaleAndZoomControls(): void {
    if (
      this.options?.scaleControl !== null && typeof this.options?.scaleControl !== 'undefined'
        ? this.options.scaleControl
        : DEFAULT_OPTIONS.scaleControl
    ) {
      control
        .scale({
          imperial: false,
          metric: true,
          position: (this.options && this.options.scaleControlPosition) || DEFAULT_OPTIONS.scaleControlPosition
        })
        .addTo(this.mapService.getMap());
    }

    if (
      this.options?.zoomControl !== null && typeof this.options?.zoomControl !== 'undefined'
        ? this.options.zoomControl
        : DEFAULT_OPTIONS.zoomControl
    ) {
      control
        .zoom({
          position: (this.options && this.options.zoomControlPosition) || DEFAULT_OPTIONS.zoomControlPosition
        })
        .addTo(this.mapService.getMap());
    }
  }

  /**
   * Subscription used to update center & zoom of recenter control if exists
   */
  private subscribeRecenterControl(): void {
    this.center$.pipe(takeUntil(this.destroy$)).subscribe((_center: LatLngObject) => {
      this.recenterControl.setCenter(_center);
    });
    this.zoom$.pipe(takeUntil(this.destroy$)).subscribe((_zoom: number) => {
      this.recenterControl.setZoom(_zoom);
    });
    this.fitBounds$.pipe(takeUntil(this.destroy$)).subscribe((_bounds: LatLngObject[]) => {
      if (_bounds) {
        this.recenterControl.setBounds(_bounds);
      }
    });
  }

  private addCoreMapEventListeners(): void {
    //Below lines should be modified. Should attach listeners only if there are observers for eventemitters.
    //Should be refactored soon

    this.mapService.getMap().on('load', (e: LeafletEvent) => this.handleEvent(this.mapLoad, e));
    this.mapService.getMap().on('unload', (e: LeafletEvent) => this.handleEvent(this.mapUnload, e));
    this.mapService.getMap().on('resize', (e: LeafletEvent) => {
      if (this.options.preventCentreUpdateOnResize && this.invalidateSizeEvent) {
        /* Temp Fix - Leaflet invalidateSize is updating map center. Added this fix as workaround*/
        this.mapService.getMap().setView(this.centerRef, undefined, { pan: false, zoom: false });
        this.invalidateSizeEvent = false;
        this.canUpdateCentre = false;
        return;
      }
      this.canUpdateCentre = false;
      this.handleEvent(this.mapResize, e);
    });
    this.mapService.getMap().on('keypress', (e: LeafletKeyboardEvent) => this.handleEvent(this.mapKeyPress, e));
    this.mapService.getMap().on('keydown', (e: LeafletKeyboardEvent) => this.handleEvent(this.mapKeyDown, e));
    this.mapService.getMap().on('dragend', (e: LeafletEvent) => this.handleEvent(this.mapDragEnd, e));
    this.mapService.getMap().on('click', (e: LeafletEvent) => this.handleEvent(this.mapClick, e));

    this.mapService.getMap().on('zoomend', () => {
      this.handleEvent(this.mapZoomChange, this.mapService.getMap().getZoom());
    });

    if (this.mapCenterChange.observers.length > 0) {
      this.mapService.getMap().on('moveend', () => {
        if (!this.options.preventCentreUpdateOnResize || (this.options.preventCentreUpdateOnResize && this.canUpdateCentre)) {
          this.mapCenterChange.emit(this.mapService.getMap().getCenter());
        }
        this.canUpdateCentre = true;
      });

      this.mapService.getMap().on('movestart', () => {
        this.canUpdateCentre = true;
      });
    }
  }

  private handleEvent<T>(eventEmitter: EventEmitter<T>, event: T): void {
    if (eventEmitter.observers.length > 0) {
      eventEmitter.emit(event);
    }
  }

  /**
   * Checks if the map container size changed and updates the map if you've changed the map size dynamically
   */
  private invalidateMapSizeOnLoad(): void {
    //Need to check whether SCS can work with resize calling only onLoad
    if (this.options.preventCentreUpdateOnResize) {
      this.ngZone.run(() => {
        this.resize();
      });
    } else {
      const observer = new ResizeObserver(() => {
        this.ngZone.run(() => {
          this.resize();
        });
      });
      observer.observe(this.mapContainer.nativeElement);
    }
  }

  private resize(): void {
    setTimeout(() => {
      if (this.options.preventCentreUpdateOnResize && !this.invalidateSizeEventTriggeredOnce) {
        this.invalidateSizeEvent = true;
        this.canUpdateCentre = false;
        this.invalidateSizeEventTriggeredOnce = true;
      }
      this.mapService.getMap().invalidateSize({ pan: false });
    }, MAP_INVALIDATE_SIZE_DELAY);
  }

  setView(_center: LatLngObject, _zoom: number): void {
    if (this.options.preventCentreUpdateOnResize) {
      this.canUpdateCentre = false;
    }
    this.mapService.getMap().setView(_center, _zoom, { pan: false, zoom: false });
    this.handleEvent(this.mapCenterChange, _center);
    this.centerRef = _center;
    this.mapZoom = _zoom;

    if (this.recenterControl && !this.recenterControl.options.isManual) {
      this.recenterControl.setCenter(_center);
      this.recenterControl.setZoom(_zoom);
    }
  }

  setZoom(_zoom: number): void {
    this.zoom$.next(_zoom);
  }

  getZoom(): number {
    return this.mapService.getMap().getZoom();
  }

  setZoomRange(_zoomRange: MapZoomRange): void {
    this.zoomRange$.next(_zoomRange);
  }

  setCenter(_center: LatLngObject): void {
    this.center$.next(_center);
  }

  getCenter(): LatLngObject {
    return this.mapService.getMap().getCenter();
  }

  recenter(): void {
    this.center$.next(this.centerRef);
  }

  resetZoom(): void {
    this.zoom$.next(this.mapZoom);
  }

  setBounds(_fitBounds: LatLngObject[]): void {
    this.fitBounds$.next(_fitBounds);
  }

  setMapLayerOption(_layerOption: SiteData): void {
    this.mapLayerOption$.next(_layerOption);
  }

  setMaxBounds(_maxBounds: LatLngObject[]): void {
    this.maxBounds$.next(_maxBounds);
  }

  getBounds(): any {
    return this.mapService.getMap().getBounds();
  }

  checkPositionInViewPort(position: LatLngObject): boolean {
    const point = new LatLng(position.lat, position.lng);
    return this.getBounds().contains(point);
  }

  panTo(position: LatLngObject): void {
    this.mapService.getMap().panTo(position);
  }

  getMapInstance(): LMap {
    return this.mapService.getMap();
  }

  getGoogleGridLayer(): Layer {
    return this.googleTileLayer;
  }
}
