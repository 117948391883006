import 'leaflet-draw/dist/leaflet.draw';

declare var L: any;

L.Draw.Polyline.include({
  _getMeasurementString() {
    const currentLatLng = this._currentLatLng,
      previousLatLng = this._markers[this._markers.length - 1].getLatLng();
    let distance;
    // Calculate the distance from the last fixed point to the mouse position based on the version

    if (L.GeometryUtil.isVersion07x()) {
      distance =
        previousLatLng && currentLatLng && currentLatLng.distanceTo
          ? currentLatLng.distanceTo(previousLatLng) * (this.options.factor || 1)
          : 0;
    } else {
      distance = previousLatLng && currentLatLng ? this._map.distance(currentLatLng, previousLatLng) * (this.options.factor || 1) : 0;
    }
    return this.readableDistance(distance, this.options.metric, this.options.feet, this.options.nautic, this.options.precision);
  },

  readableDistance(distance, isMetric, isFeet, isNauticalMile, precision) {
    const defaultPrecision = {
      km: 2,
      ha: 2,
      m: 1,
      mi: 2,
      ac: 2,
      yd: 0,
      ft: 1,
      nm: 2
    };
    let distanceStr, units;
    const calculaedPrecision = L.Util.extend({}, defaultPrecision, precision);

    if (isMetric) {
      units = typeof isMetric == 'string' ? isMetric : 'metric';
    } else if (isFeet) {
      units = 'feet';
    } else if (isNauticalMile) {
      units = 'nauticalMile';
    } else {
      units = 'yards';
    }

    switch (units) {
      case 'metric':
        // show metres when distance is < 1km, then show km
        if (distance > 1000) {
          distanceStr = L.GeometryUtil.formattedNumber(distance / 1000, calculaedPrecision['km']) + ' km';
        } else {
          distanceStr = L.GeometryUtil.formattedNumber(distance, calculaedPrecision['m']) + ' m';
        }
        break;
      case 'feet':
        distance *= 1.09361 * 3;
        distanceStr = L.GeometryUtil.formattedNumber(distance, calculaedPrecision['ft']) + ' ft';
        break;
      case 'nauticalMile':
        distance *= 0.53996;
        distanceStr = L.GeometryUtil.formattedNumber(distance / 1000, calculaedPrecision['nm']) + ' nm';
        break;
      case 'yards':
      default:
        distance *= 1.09361;

        if (distance > 1760) {
          distanceStr = L.GeometryUtil.formattedNumber(distance / 1760, calculaedPrecision['mi']) + ' miles';
        } else {
          distanceStr = L.GeometryUtil.formattedNumber(distance, calculaedPrecision['yd']) + ' yd';
        }
        break;
    }
    return distanceStr;
  }
});
