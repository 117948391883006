import { Position } from '@Terra/shared/widgets/config';
import { turfUtilHelper } from '@Terra/shared/widgets/utils';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Control, FeatureGroup } from 'leaflet';
import * as L from 'leaflet/dist/leaflet';
import { ContentControl } from '../../controls/content-control';
import { MeasureControl as LeafletMeasureControl } from '../../controls/measure/measure-control';
import { ELEVATION_PROFILE_LAYER, MAP_MEASURE_CONTROL_DEFAULT_CONFIG, MEASURE_CONTROL_OPTIONS, MEASURE_LAYER } from '../../map.constants';
import { ElevationProfileEventData, GeoJsonLineString, MapContentOptions, MeasureControlOptions } from '../../map.model';
import { MapService } from '../../map.service';

declare let L: any;

@Component({
  selector: 'app-map-measure',
  templateUrl: './measure-control.component.html',
  styleUrls: ['./measure-control.component.scss']
})
export class MeasureControl implements OnInit, OnDestroy, OnChanges {
  @Input() options: MeasureControlOptions;
  @Input() controlLayer = MEASURE_LAYER;
  @Input() isElevationProfileChartActive = false;
  @Input() chartConfig;
  @Input() elevationProfileChartStyle: { [key: string]: string };
  @Input() isInsights = false;
  @Input() triggerToggle = false;

  @Output() onMeasureScaleToggle = new EventEmitter<boolean>();
  @Output() elevationProfileFinish = new EventEmitter<ElevationProfileEventData>();
  @Output() exitElevationProfileConfirmation = new EventEmitter<string>();
  @Output() elevationProfileToggle = new EventEmitter<boolean>();
  @Output() exitMeasureTool = new EventEmitter<boolean>();
  @Output() elevationProfileHideChart = new EventEmitter<boolean>();
  @Output() allPointsDeleted = new EventEmitter<boolean>();

  contentOptions: MapContentOptions = {
    position: Position.BOTTOMCENTER
  };

  totalDistance = null;
  distanceUnit = '';
  showMeasureCard = false;
  showElevationProfileCard = false;
  latLngs = [];
  lineSegments = [];
  area: string;
  controlOptions: any;
  showIntersectError = false;
  isElevationProfileFinish = false;
  private control: Control;

  constructor(
    private readonly mapService: MapService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.subscribeTurfScript();
    this.controlOptions = {
      ...MAP_MEASURE_CONTROL_DEFAULT_CONFIG.options,
      ...MEASURE_CONTROL_OPTIONS[this.controlLayer],
      ...this.options
    };
    this.createControl();

    const contentControl = new ContentControl(this.elementRef.nativeElement, { position: Position.BOTTOMCENTER });
    contentControl.addTo(this.mapService.getMap());
  }

  subscribeTurfScript(): void {
    turfUtilHelper.loadScript$().subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.control) {
      this.updateOptions(this.options);
    }
  }

  ngOnDestroy(): void {
    if (this.control) {
      this.control.remove();
    }
  }

  private createControl(): void {
    this.control = new LeafletMeasureControl(this.controlOptions);
    this.control.addTo(this.mapService.getMap());

    this.mapService.getMap().on('PolyLineMeasure:addNew', latlng => {
      this.latLngs.push(latlng);
      this.updateArea();
    });

    this.mapService.getMap().on('elevaionProfileMeasure:addNew', latlng => {
      this.latLngs.push(latlng);
    });

    this.mapService.getMap().on('PolyLineMeasure:totalDistance', e => {
      this.totalDistance = e.value;
      this.distanceUnit = e.unit;
      this.changeDetector.detectChanges();
    });

    this.mapService.getMap().on('elevaionProfileMeasure:totalDistance', distance => {
      this.totalDistance = distance.value;
      this.distanceUnit = distance.unit;
    });

    this.mapService.getMap().on('PolyLineMeasure:clear', e => {
      this.resetValues();
    });

    this.mapService.getMap().on('elevaionProfileMeasure:clear', e => {
      this.resetValues();
    });

    this.mapService.getMap().on('PolyLineMeasure:toggle', e => {
      this.showMeasureCard = e.sttus;
      this.showElevationProfileCard = false;
      this.onMeasureScaleToggle.emit(e.sttus);
      this.changeDetector.detectChanges();
    });

    this.mapService.getMap().on('PolyLineMeasure:moveCoords', e => {
      this.latLngs = e.coords.slice();
      this.updateArea();
    });

    this.mapService.getMap().on('elevaionProfileMeasure:moveCoords', line => {
      this.latLngs = line.coords.slice();
      this.checkForIntersection();
      this.control._disableDrawingForElevationProfile();
    });

    this.mapService.getMap().on('PolyLineMeasure:remove', e => {
      this.removePoint(e.latlng);
      this.updateArea();
    });

    this.mapService.getMap().on('elevaionProfileMeasure:remove', coords => {
      this.removePoint(coords.latlng);
      this.control._disableDrawingForElevationProfile();
      if (this.latLngs.length >= 2) {
        this.checkForIntersection();
      } else {
        this.allPointsDeleted.emit(true);
        this.control._enableDrawingForElevationProfile();
      }
    });

    this.mapService.getMap().on('PolyLineMeasure:preferenceUpdate', e => {
      this.totalDistance = e.value;
      this.distanceUnit = e.unit;
      this.updateArea();
    });

    this.mapService.getMap().on('elevaionProfileMeasure:toggle', e => {
      this.showMeasureCard = false;
      this.showElevationProfileCard = e.sttus;
      this.isElevationProfileFinish = false;
      this.elevationProfileToggle.emit(e.sttus);
      this.changeDetector.detectChanges();
    });

    this.mapService.getMap().on('elevaionProfileMeasure:finish', line => {
      if (line && this.latLngs.length > 0) {
        this.isElevationProfileFinish = true;
        this.control._enableElevationProfileDragging();
        this.control._disableDrawingForElevationProfile();
        this.checkForIntersection();
      }
    });
  }

  toggleMeasure(): void {
    this.resetElevationProfileData();
    this.control._toggleMeasure();
  }

  exitMeasure() {
    this.showIntersectError = false;
    this.control._resetPathVariables();
    this.control.exitMeasure();
  }

  isMeasuring(): boolean {
    return this.control ? this.control.isMeasuring() : false;
  }

  isMeasuringNotFinish(): boolean {
    return this.control ? this.control.isMeasuringNotFinish() : false;
  }

  enable(): void {
    this.control.enable();
  }

  disable(): void {
    this.control.disable();
  }

  removePoint(latLng: any): void {
    let itemIndexToRemove = -1;
    if (latLng) {
      for (let i = this.latLngs.length - 1; i > -1; i--) {
        if (latLng.lat === this.latLngs[i].lat && latLng.lng === this.latLngs[i].lng) {
          itemIndexToRemove = i;
          break;
        }
      }
    } else {
      itemIndexToRemove = 2;
    }
    if (itemIndexToRemove > -1) {
      this.latLngs.splice(itemIndexToRemove, 1);
    }
  }

  resetElevationProfileData(): void {
    this.latLngs = [];
    this.lineSegments = [];
    this.showIntersectError = false;
    this.control._resetPathVariables();
  }

  checkForIntersection(): void {
    this.showIntersectError = false;
    this.createLineSegments();
    outerLoop: for (let i = 0; i < this.lineSegments.length; i++) {
      for (let j = 0; j < this.lineSegments.length; j++) {
        if (j !== i && j !== i - 1 && j !== i + 1) {
          const line1 = turfUtilHelper.getLineString(this.lineSegments[i]);
          const line2 = turfUtilHelper.getLineString(this.lineSegments[j]);
          const intersects = turfUtilHelper.getLineIntersect(line1, line2);
          if (intersects?.features.length > 0) {
            this.showIntersectError = true;
            break outerLoop;
          }
        }
      }
    }
    this.setErrorOrResetErrorForElevationProfile();
  }

  setErrorOrResetErrorForElevationProfile(): void {
    if (this.isElevationProfileFinish) {
      if (this.showIntersectError || this.latLngs.length < 2) {
        this.control._setErrorStylesForElevationProfile();
        this.elevationProfileHideChart.emit(true);
      } else {
        this.control._resetErrorStylesForElevationProfile();
        const geoJsonLineString = this.createGeoJsonLineString();
        this.chartConfig = null;
        this.elevationProfileFinish.emit({ geoJsonLineString, totalDistance: this.totalDistance });
      }
    } else {
      this.control._resetErrorStylesForElevationProfile();
    }

    this.changeDetector.detectChanges();
  }

  createGeoJsonLineString(): GeoJsonLineString {
    const geoJsonString = {
      type: 'LineString',
      coordinates: []
    };

    this.latLngs.forEach(point => {
      geoJsonString.coordinates.push([this.trimDecimals(point.lng, 6), this.trimDecimals(point.lat, 6)]);
    });

    return geoJsonString;
  }

  trimDecimals(coord: number, decimalLength: number): number {
    const factor = Math.pow(10, decimalLength);
    return Math.floor(coord * factor) / factor;
  }

  createLineSegments(): void {
    this.lineSegments = [];
    for (let i = 0; i < this.latLngs.length - 1; i++) {
      const lineSegment = [
        [this.latLngs[i].lat, this.latLngs[i].lng],
        [this.latLngs[i + 1].lat, this.latLngs[i + 1].lng]
      ];
      this.lineSegments.push(lineSegment);
    }
  }

  updateArea(): void {
    let closedLatLngs = [];
    let shouldCalcArea = false;
    if (this.latLngs.length > 0) {
      closedLatLngs.push(this.latLngs[0]);
      let closedSegments = [];
      for (let i = 1; i < this.latLngs.length; i++) {
        closedSegments.push(this.latLngs[i]);
        if (this.latLngs[0].lat === this.latLngs[i].lat && this.latLngs[0].lng === this.latLngs[i].lng) {
          shouldCalcArea = true;
          closedLatLngs = closedLatLngs.concat(closedSegments);
          closedSegments = [];
        }
      }
    }

    if (shouldCalcArea) {
      this.calculateArea(closedLatLngs);
    } else {
      this.area = null;
      this.changeDetector.detectChanges();
    }
  }

  resetValues(): void {
    this.totalDistance = null;
    this.latLngs = [];
    this.area = null;
    this.changeDetector.detectChanges();
  }

  calculateArea(latLngs: Array<any>): void {
    let area = L.GeometryUtil.geodesicArea(latLngs);
    switch (this.distanceUnit) {
      case this.controlOptions.unitControlLabel.landmiles:
        area = area * 0.0000003861;
        break;
      case this.controlOptions.unitControlLabel.kilometres:
        area = area / 1000000;
        break;
      case this.controlOptions.unitControlLabel.nauticalmiles:
        area = area * 0.0000003861;
        area = area * 0.75512;
        break;
      case this.controlOptions.unitControlLabel.feet:
        area = area * 10.7639;
        break;
      default:
        break;
    }
    this.area = this.options.decimalSeparator === 'Comma' ? area.toFixed(2).replace('.', ',') : area.toFixed(2);
    this.changeDetector.detectChanges();
  }

  updateOptions(options: MeasureControlOptions) {
    if (this.control) {
      this.controlOptions = {
        ...MAP_MEASURE_CONTROL_DEFAULT_CONFIG.options,
        ...MEASURE_CONTROL_OPTIONS[this.controlLayer],
        ...options
      };

      this.control.updateOptions(this.controlOptions, this.triggerToggle);
    }
  }

  trackByForLoop(id: number): number {
    return id;
  }

  getMeasurementFeatureGroup(): FeatureGroup {
    return this.control ? this.control.getMeasurementFeatureGroup() : null;
  }

  exitElevationProfileDrawing(): void {
    this.exitElevationProfileConfirmation.emit(ELEVATION_PROFILE_LAYER);
  }

  exitMeasureEvent(): void {
    this.exitMeasureTool.emit(true);
  }
}
